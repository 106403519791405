<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
      <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Transaction</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Cleansings Process</a></li>
                  </ol>
              </div>
          </div>
      </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">

      </div>

      <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa la-search scale5 mr-2"></i>Form Filter</a>
      <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="openimport(sendModalImport)"><i class="fa fa-cloud-upload scale5 mr-2"></i>Import</a>
      <a href="javascript:void(0);" class="btn btn-success btn-rounded mb-3 mr-3" (click)="setMerge()"><i class="fa fa-exchange scale5 mr-2"></i>Merge</a>
      <!-- <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" [routerLink]="'/admin/form-cleansing'"><i class="fa fa-plus scale5 mr-2"></i>Create</a> -->
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>

    </div>
    <!-- row -->
    <div class="row">
      <!-- // OKE -->

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Cleansing Process</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="">
                      <input type="checkbox" name="all" [checked]="isAllChecked()" (change)="checkAll($event)"/>
                    </th>
                    <th scope="col">No</th>
                    <th scope="col">Inbound</th>
                    <th scope="col">Resi</th>
                    <th scope="col">Ship Date</th>
                    <th scope="col">AirwayBill</th>
                    <th scope="col">AWB Vendor</th>
                    <th scope="col">Shipper</th>
                    <th scope="col">Consignee</th>
                    <th scope="col">Vendor</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                    <th scope="col">Input By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <td>
                      <input type="checkbox" name="tutorialcb[]" value="{{tutorial.id}}" [(ngModel)]="tutorial.state"
                      (click)="check(tutorial)"/>
                    </td>
                    <th scope="row">
                      {{ x + 1 }}
                    </th>
                    <td>
                      {{ tutorial.code_resi_inbound }}
                    </td>
                    <td>
                      {{ tutorial.resi_lokal }}
                    </td>
                    <td>
                      {{ tutorial.ship_date }}
                    </td>
                    <td>
                      {{ tutorial.airwaybill }}
                    </td>
                    <td>
                      {{ tutorial.awb_vendor }}
                    </td>
                    <td>
                      {{ tutorial.nameshippers }}
                    </td>
                    <td>
                      {{ tutorial.nameconsignee }}
                    </td>
                    <td>
                      {{ tutorial.namevendor }}
                    </td>
                    <td>
                      {{ tutorial.createdAt | date : 'd MMM y HH:mm:ss'}}
                    </td>
                    <td>
                      {{ tutorial.updatedAt | date : 'd MMM y HH:mm:ss'}}
                    </td>
                    <td>
                      {{ tutorial.full_name }}
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-rounded btn-dark btntutup" placement="top" ngbTooltip="Print"
                        (click)="openPrint(tutorial, 'view')"
                      >
                        <span class="btn-icon-left text-dark"
                          ><i class="fa fa-print color-dark"></i> </span
                        ></button
                      >&nbsp;
                      <button
                        type="button"
                        class="btn btn-rounded btn-success btntutup" placement="top" ngbTooltip="Detail"
                        (click)="openData(tutorial, 'view')"
                      >
                        <span class="btn-icon-left text-success"
                          ><i class="fa fa-eye color-success"></i> </span
                        ></button
                      >&nbsp;

                      <button *ngIf="tutorial.is_bag == 'N' && !isFinance"
                        type="button"
                        class="btn btn-rounded btn-info btntutup" placement="top" ngbTooltip="Edit"
                        (click)="openData(tutorial, 'update')"
                      >
                        <span class="btn-icon-left text-info"
                          ><i class="fa fa-pencil color-info"></i> </span
                        ></button
                      >&nbsp;
                      <button *ngIf="tutorial.is_bag == 'N' && !isFinance" (click)="deleteUser(tutorial)" type="button" class="btn btn-rounded btn-danger btntutup" placement="top" ngbTooltip="Remove">
                        <span class="btn-icon-left text-danger"
                          ><i class="fa fa-trash-o color-danger"></i> </span
                        >
                      </button>&nbsp;

                      <button *ngIf="!isFinance" (click)="copyCleansing(tutorial)" type="button" class="btn btn-rounded btn-primary btntutup" placement="top" ngbTooltip="Copy">
                        <span class="btn-icon-left text-primary"
                          ><i class="fa fa-files-o color-primary"></i> </span
                        >
                      </button>&nbsp;

                      <button *ngIf="isSuperadmin || isAdmin || isCleansings || isInbound" (click)="setAwbvendor(sendCleansingModal, tutorial, 'update')" type="button" class="btn btn-rounded btn-primary btntutup" placement="top" ngbTooltip="Set AirwayBill Vendor">
                        <span class="btn-icon-left text-danger"
                          ><i class="fa fa-plane color-danger"></i> </span
                        >
                      </button>

                      <button *ngIf="tutorial.is_copy == 'Y' && !isFinance" (click)="setAwbs(sendCleansingawbModal, tutorial, 'update')" type="button" class="btn btn-rounded btn-primary btntutup" placement="top" ngbTooltip="Set AirwayBill">
                        <span class="btn-icon-left text-danger"
                          ><i class="fa fa-ticket color-danger"></i> </span
                        >
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination class="pagination-responsive"
                [collectionSize]="count"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              >
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select
                  class="mr-sm-2 custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="handlePageSizeChange($event)"
                >
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Filter</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">
        <div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Code Inbound</label>
            <input
              type="text"
              class="form-control"
              id="code_resi_inbound"
              [(ngModel)]="code_resi_inbound"
              #name="ngModel"
              name="code_resi_inbound"
            />
          </div>
				</div>
        <div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Resi Lokal</label>
            <input
              type="text"
              class="form-control"
              id="resi_lokal"
              [(ngModel)]="resi_lokal"
              #name="ngModel"
              name="resi_lokal"
            />
          </div>
				</div>
        <div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Consignee Name</label>
            <input
              type="text"
              class="form-control"
              id="nameconsignee"
              [(ngModel)]="nameconsignee"
              #name="ngModel"
              name="nameconsignee"
            />
          </div>
				</div>
        <div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">AirwayBill</label>
            <input
              type="text"
              class="form-control"
              id="airwaybill"
              [(ngModel)]="airwaybill"
              #name="ngModel"
              name="airwaybill"
            />
          </div>
				</div>
				<!-- <div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Ship Date</label>
            <input
              type="date"
              class="form-control"
              id="ship_date"
              [(ngModel)]="ship_date"
              #name="ngModel"
              name="ship_date"
            />
          </div>
				</div> -->
        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Shipper</label>
            <select class="form-control default-select" *ngIf="vcombos.length > 0" id="shipper_id" [(ngModel)]="shipper_id" #name="ngModel" name="shipper_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>
        <div class="col-lg-4 mb-3" *ngIf="!isAgen">
          <label class="text-black font-w600">Vendor</label>
            <select class="form-control default-select" *ngIf="vcomboss.length > 0" id="vendor_id" [(ngModel)]="vendor_id" #name="ngModel" name="vendor_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcomboss" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>
        <div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Start Ship Date</label>
            <input
              type="date"
              class="form-control"
              id="start_ship_date"
              [(ngModel)]="start_ship_date"
              #name="ngModel"
              name="start_ship_date"
            />
          </div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">End Ship Date</label>
            <input
              type="date"
              class="form-control"
              id="end_ship_date"
              [(ngModel)]="end_ship_date"
              #name="ngModel"
              name="end_ship_date"
            />
          </div>
				</div>

				<div class="col-lg-12">
					<div class="form-group mb-0">
            <button (click)="searchTitle()" class="submit btn btn-primary">Search</button>
					</div>
				</div>
			</div>
		<!-- </form> -->
	</div>
</ng-template>
<!-- Modal ADD -->
<ng-template #sendModalImport let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Import</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Ship Date</label>
            <input
              type="date"
              class="form-control"
              id="ship_date"
              [(ngModel)]="ship_date"
              #name="ngModel"
              name="ship_date"
            />
          </div>
				</div>
        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Shipper</label>
            <select class="form-control default-select" *ngIf="vcombos.length > 0" id="shipper_id" [(ngModel)]="shipper_id" #name="ngModel" name="shipper_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>
        <div class="col-lg-4 mb-3" *ngIf="!isAgen">
          <label class="text-black font-w600">Vendor</label>
            <select class="form-control default-select" *ngIf="vcomboss.length > 0" id="vendor_id" [(ngModel)]="vendor_id" #name="ngModel" name="vendor_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcomboss" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>

        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Select file to upload.</label>
          <input type="file" class="form-control" (change)="onFileChange($event);">
        </div>

				<div class="col-lg-12">
					<div class="form-group mb-0">
            <button (click)="Downloadtemps()" class="submit btn btn-success mr-3">Download Template</button>
            <button (click)="Upload()" class="submit btn btn-danger mr-3">Upload File</button>
					</div>
				</div>
			</div>
		<!-- </form> -->
	</div>
</ng-template>

<!-- EDIT AWB VENDOR -->
<ng-template #sendCleansingModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Set AirwayBill Vendor</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">AirwayBill Vendor <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="awb_vendor"
              required
              [(ngModel)]="fadd_role.awb_vendor"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="awb_vendor"
            />
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveAwbvendor()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<!-- EDIT AWB -->
<ng-template #sendCleansingawbModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Set AirwayBill</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">AirwayBill <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="airwaybill"
              required
              [(ngModel)]="fadd_role.airwaybill"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="airwaybill"
            />
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveAwbs()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
