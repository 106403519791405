<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <!-- <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Transaksi</a></li>
                  <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Pendaftaran</a></li>
              </ol>
          </div>
        </div>
    </div> -->

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input
          type="text"
          class="form-control"
          placeholder="Search by nik/NoHp/NoInvoice/Email/nobib,namabib/kaos/kategori"
          [(ngModel)]="title"
          />

        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="openFormBIB(sendPartnerModalBIB)" *ngIf="isAdmin"><i class="fa fa-plus scale5 mr-2"></i>Scan</a>
      <button (click)="downloadExcel()" class="submit btn btn-success btn-rounded mb-3 mr-3" *ngIf="isAdmin || isSuperadmin"><i class="fa fa-file-excel-o scale5 mr-2"></i> Export</button>
      <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="openimport(sendModalImport)" *ngIf="isSuperadmin"><i class="fa fa-cloud-upload scale5 mr-2"></i>Import</a>
      <!-- <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a *ngIf="isSuperadmin" href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a> -->

    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Data Pendaftaran</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">No.Invoice</th>
                    <th scope="col">NIK/PASSPORT</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Nama</th>
                    <th scope="col">No.BIB</th>
                    <th scope="col">Size</th>
                    <!-- <th scope="col">Nama BIB</th> -->
                    <!-- <th scope="col">Email</th> -->
                    <th scope="col">No Hp/Telp</th>
                    <th scope="col" *ngIf="isSuperadmin">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x + 1 }}
                    </th>
                    <td>
                      {{ tutorial.run_order_id }}
                    </td>
                    <td>
                      {{ tutorial.run_idnumber }}
                    </td>
                    <td>
                      {{ tutorial.run_categoryText }}
                    </td>
                    <td>
                      {{ tutorial.run_firstname }} {{ tutorial.run_lastname }}
                    </td>
                    <td>
                      {{ tutorial.run_nobip }}
                    </td>
                    <td>
                      {{ tutorial.run_sizekaos }}
                    </td>
                    <!-- <td>
                      {{ tutorial.run_namabip }}
                    </td> -->
                    <!-- <td>
                      {{ tutorial.run_email }}
                    </td> -->
                    <td>
                      {{ tutorial.run_phone }}
                    </td>
                    <td >
                        <button type="button" class="btn btn-rounded btn-info btn-xxs mr-2" (click)="showsPesertas(sendMessageModal, tutorial, 'view')" placement="left" ngbTooltip="Show">
                          <i class="fa fa-eye color-info"></i>
                        </button>
                        <button type="button" class="btn btn-danger btn-xxs mr-2" (click)="setBIBs(tutorial)" placement="left" ngbTooltip="Set BIB" *ngIf="isSuperadmin || isAdmin">
                          <i class="fa fa-cog color-info"></i>
                        </button>
                        <button type="button" class="btn btn-rounded btn-dark btn-xxs mr-2" placement="top" ngbTooltip="Print TIket" (click)="openPrint(tutorial, 'view')" *ngIf="tutorial.status_bayar == '00'">
                          <i class="fa fa-print color-dark"></i>
                        </button>
                        <button *ngIf="isAdmin" type="button" class="btn btn-rounded btn-warning btn-xxs mr-2" (click)="setKaos(sendKaosModal, tutorial, 'update')" placement="top" ngbTooltip="Set Ambil Kaos">
                        <i class="fa fa-exclamation-triangle color-danger"></i>
                        </button>
                        <button type="button" class="btn btn-success btn-xxs mr-2" (click)="setKehadiran(tutorial)" placement="left" ngbTooltip="Hadir" *ngIf="tutorial.run_kehadiranText == 'Tidak Hadir'">
                          <i class="fa fa-check color-info"></i>
                        </button>
                        <button type="button" class="btn btn-danger btn-xxs mr-2" (click)="setSendTiket(tutorial)" placement="left" ngbTooltip="Kirim E-Tiket" *ngIf="isSuperadmin || isAdmin && tutorial.status_bayar == '00'">
                          <i class="fa fa-paper-plane color-info"></i>
                        </button>
                        <button *ngIf="isSuperadmin" type="button" class="btn btn-rounded btn-primary btn-xxs mr-2" (click)="setNomorbip(sendPesertaBIPModal, tutorial, 'update')" placement="top" ngbTooltip="Update">
                        <i class="fa fa-edit color-danger"></i>
                        </button>
                        <button type="button" class="btn btn-rounded btn-danger btn-xxs mr-2" (click)="showsPembayarans(sendMessagebayarModal, tutorial, 'view')" placement="left" ngbTooltip="Bayar" *ngIf="tutorial.status_bayar != '00'">
                          <i class="fa fa-money color-danger"></i>
                        </button>
                        <!-- <button *ngIf="isSuperadmin" type="button" class="btn btn-rounded btn-success btn-xxs mr-2" (click)="setKehadiran(sendPesertaBIPModal, tutorial, 'update')" placement="top" ngbTooltip="Set Kehadiran">
                        <i class="fa fa-check-square-o color-danger"></i>
                        </button> -->
                        <!-- <button *ngIf="isSuperadmin" type="button" class="btn btn-rounded btn-primary btn-xxs mr-2" (click)="setBayar(sendPesertaBIPModal, tutorial, 'update')" placement="top" ngbTooltip="Set Nomor BIP">
                        <i class="fa fa-ticket color-danger"></i>
                        </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination class="pagination-responsive"
                [collectionSize]="count"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              >
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select
                  class="mr-sm-2 custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="handlePageSizeChange($event)"
                >
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detail Peserta</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="comment-form">
      <!-- Start Detail Pendaftaran -->
      <!-- End Detail Pendaftaran -->
      <div class="row">
        <div class="col-lg-12">
				
          <div class="card">
            <div class="card-header"> Tanggal Pendaftaran <strong>{{fadd_role.createdAt | date : 'd MMM y HH:mm:ss' }}</strong> <span class="float-right">
            <strong>Status Pembayaran:</strong> {{fadd_role.status_bayar == '00' ? 'Lunas' : 'Belum Bayar'}}</span> </div>
            <div class="card-body">
              <div class="row mb-5">
                <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <h6>Informasi Event: </h6>
                  <div>Kategori: {{fadd_role.run_categoryText}}  </div>
                  <div>Ukuran Kaos: {{fadd_role.run_sizekaos}}</div>
                  <div>Status Pengambil Perlengkapan: {{fadd_role.ambilkaos}}</div>
                  <div>Nomor BIB: {{fadd_role.run_nobip}} </div>
                  <div>Nama BIB: {{fadd_role.run_namabip}} </div>
                </div>
                <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <h6>Informasi Pribadi:</h6>
                  <div>Nomor KTP/Passport: {{fadd_role.run_idnumber}} </div>
                  <div>Nama Lengkap: {{fadd_role.fullname}} </div>
                  <div>Jenis Kelamin: {{fadd_role.run_gender == 'Male' ? 'Pria' : 'Wanita'}}</div>
                  <div>Tanggal Lahir: {{fadd_role.run_gender | date : 'd MMM y' }}</div>
                  <div>Email: {{fadd_role.run_email}}</div>
                  <div>No.Hp/Telp: {{fadd_role.run_phone}}</div>
                  <div>Kota: {{fadd_role.run_city}}</div>
                  <div>Warga Negara: {{fadd_role.run_nationality}}</div>
                  <div>Perusahaan: {{fadd_role.run_organization}}</div>
                  <div>Pekerjaan: {{fadd_role.run_occupation}}</div>
                </div>
                <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <h6>Kontak Darurat:</h6>
                  <div>Nama Kontak Darurat: {{fadd_role.run_emergencyname}} </div>
                  <div>No. Telp Darurat: {{fadd_role.run_emergencyphone}}</div>
                  <div>Hubungan Kontak Darurat: {{fadd_role.run_emergencyrel}}</div>
                  <div>Golongan Darah: {{fadd_role.run_blood}}</div>
                </div>
                <!-- <div class="mt-4 col-xl-6 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-lg-end justify-content-md-center justify-content-xs-start">
                  <div class="row align-items-center">
                    <div class="col-sm-9"> 
                      <div class="brand-logo mb-3">
                        <img class="logo-abbr mr-2" src="assets/images/logo.png" alt="">
                        <img class="logo-compact" src="assets/images/logo-text.png" alt="">
                      </div>
                      <span>Please send exact amount: <strong class="d-block">0.15050000 BTC</strong>
                      <strong>1DonateWffyhwAjskoEwXt83pHZxhLTr8H</strong></span><br>
                      <small class="text-muted">Current exchange rate 1BTC = $6590 USD</small>
                    </div>
                    <div class="col-sm-3 mt-3"> <img src="assets/images/qr.png" class="img-fluid width110"> </div>
                  </div>
                </div> -->
              </div>
              <div class="card-header"> Informasi Pembayaran <strong>{{fadd_role.paymentText}}</strong> </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">#</th>
                      <!-- <th>Item</th> -->
                      <th>Keterangan</th>
                      <th class="right">Harga</th>
                      <th class="center">Qty</th>
                      <th class="right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="center">1</td>
                      <td class="left strong">Kategori {{fadd_role.run_categoryText}}</td>
                      <!-- <td class="left">Extended License</td> -->
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                    </tr>
                    <tr>
                      <td class="center">2</td>
                      <td class="left">Biaya Layanan</td>
                      <!-- <td class="left">Instalation and Customization (cost per hour)</td> -->
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                    </tr>
                    <tr *ngIf="fadd_role.harga3 > 0">
                      <td class="center">3</td>
                      <td class="left">Diskon</td>
                      <!-- <td class="left">Instalation and Customization (cost per hour)</td> -->
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                    </tr>
                    <!-- <tr>
                      <td class="center">3</td>
                      <td class="left">Hosting</td>
                      <td class="left">1 year subcription</td>
                      <td class="right">$499,00</td>
                      <td class="center">1</td>
                      <td class="right">$499,00</td>
                    </tr>
                    <tr>
                      <td class="center">4</td>
                      <td class="left">Platinum Support</td>
                      <td class="left">1 year subcription 24/7</td>
                      <td class="right">$3.999,00</td>
                      <td class="center">1</td>
                      <td class="right">$3.999,00</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-5"> </div>
                <div class="col-lg-4 col-sm-5 ml-auto">
                  <table class="table table-clear">
                    <tbody>
                      <tr>
                        <td class="left"><strong>Total</strong></td>
                        <td class="right"><strong>{{fadd_role.total | number}}</strong><br>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!-- EDIT -->
<ng-template #sendPesertaBIPModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Update</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Nomor BIB </label>
            <input
              type="text"
              class="form-control"
              id="run_nobip"
              [(ngModel)]="fadd_role.run_nobip"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_nobip"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Nama BIB <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_namabip"
              required
              [(ngModel)]="fadd_role.run_namabip"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_namabip"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Kategori <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_category"
              required
              [(ngModel)]="fadd_role.run_category"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_category"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Size <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_sizekaos"
              required
              [(ngModel)]="fadd_role.run_sizekaos"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_sizekaos"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Email <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_email"
              required
              [(ngModel)]="fadd_role.run_email"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_email"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">No Handphone <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_phone"
              required
              [(ngModel)]="fadd_role.run_phone"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_phone"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Jenis Kelamin <span>Male / Female</span></label>
            <input
              type="text"
              class="form-control"
              id="run_gender"
              required
              [(ngModel)]="fadd_role.run_gender"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_gender"
              placeholder="Male OR Female"
            />
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveNobip()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<!-- EDIT -->
<ng-template #sendKaosModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Pengambilan Kaos</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Nama <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_nmpengambil"
              required
              [(ngModel)]="fadd_role.run_nmpengambil"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_nmpengambil"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">No Handphone</label>
            <input
              type="text"
              class="form-control"
              id="run_nohppengambil"
              required
              [(ngModel)]="fadd_role.run_nohppengambil"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="run_nohppengambil"
            />
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveKaos()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #sendMessagebayarModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detail Pembayaran</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="comment-form">
      <div class="row">
        <div class="col-lg-12">
				
          <div class="card">
            <div class="card-header"> No Invoice <strong>{{fadd_role.run_order_id }}</strong> <span class="float-right">
            <strong>Status Pembayaran:</strong> {{fadd_role.status_bayar != '00' ? 'Belum Bayar' : 'Bayar'}}</span> </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">#</th>
                      <th>Keterangan</th>
                      <th class="right">Harga</th>
                      <th class="center">Qty</th>
                      <th class="right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="center">1</td>
                      <td class="left strong">Kategori {{fadd_role.run_categoryText}}</td>
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                    </tr>
                    <tr>
                      <td class="center">2</td>
                      <td class="left">Biaya Layanan</td>
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                    </tr>
                    <tr *ngIf="fadd_role.harga3 > 0">
                      <td class="center">3</td>
                      <td class="left">Diskon</td>
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-5"> </div>
                <div class="col-lg-4 col-sm-5 ml-auto">
                  <table class="table table-clear">
                    <tbody>
                      <tr>
                        <td class="left"><strong>Total</strong></td>
                        <td class="right"><strong>{{fadd_role.total | number}}</strong><br>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 float-right">
					<div class="form-group mb-0">
            <button (click)="saveBayar()" class="submit btn btn-danger">Bayar</button>
					</div>
				</div>
      </div>
    </form>
  </div>
</ng-template>

<!-- Modal ADD -->
<ng-template #sendModalImport let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Import</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">

        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Select file to upload.</label>
          <input type="file" class="form-control" (change)="onFileChange($event);">
        </div>

				<div class="col-lg-12">
					<div class="form-group mb-0">
            <!-- <button (click)="Downloadtemps()" class="submit btn btn-success mr-3">Download Template</button> -->
            <button (click)="Upload()" class="submit btn btn-danger mr-3">Upload File</button>
					</div>
				</div>
			</div>
		<!-- </form> -->
	</div>
</ng-template>

<!-- SCAN -->
 <!-- Modal ADD -->
<ng-template #sendPartnerModalBIB let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Scan Nomor BIB</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Scan <span class="required">*</span></label>
            <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult1($event)"
              [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
              (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
          </div>
        </div>

        <div class="col-md-8">
          <label class="text-black font-w600">Nomor BIB <span class="required">*</span></label>
          <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
            <input
              type="text"
              class="form-control"
              id="run_nobip"
              required
              [(ngModel)]="run_nobip"
              #name="ngModel"
              name="run_nobip"
            />
            <div class="input-group-append">
              <button class="btn" type="button" class="submit btn btn-primary" (click)="cariNOBIBS(run_nobip)">
                <i class="flaticon-381-search-2"></i> Cari
              </button>
            </div>
          </div>
          <!-- <button (click)="cariNOBIBS()" class="submit btn btn-primary">Cari</button> -->
        </div>

				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No Invoice <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_order_id"
              required
              [(ngModel)]="run_order_id"
              #name="ngModel"
              name="run_order_id"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Nama BIB <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_namabip"
              required
              [(ngModel)]="run_namabip"
              #name="ngModel"
              name="run_namabip"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Kategori <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_category"
              required
              [(ngModel)]="run_category"
              #name="ngModel"
              name="run_category"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Ukuran Kaos <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="run_sizekaos"
              required
              [(ngModel)]="run_sizekaos"
              #name="ngModel"
              name="run_sizekaos"
            />
          </div>
				</div>

				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Nama Depan </label>
            <input
              type="text"
              class="form-control"
              id="run_firstname"
              required
              [(ngModel)]="run_firstname"
              #name="ngModel"
              name="run_firstname"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Nama Belakang </label>
            <input
              type="text"
              class="form-control"
              id="run_lastname"
              required
              [(ngModel)]="run_lastname"
              #name="ngModel"
              name="run_lastname"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Email </label>
            <input
              type="text"
              class="form-control"
              id="run_email"
              required
              [(ngModel)]="run_email"
              #name="ngModel"
              name="run_email"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No.Telp/HP </label>
            <input
              type="text"
              class="form-control"
              id="run_phone"
              required
              [(ngModel)]="run_phone"
              #name="ngModel"
              name="run_phone"
            />
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
              <ng-container *ngIf="(isLoading$) === false">
							<span class="indicator-label">
                <button (click)="saveBySCAN()" [disabled]="name.invalid" class="submit btn btn-primary">
                  {{isCreated ? 'Submit':'Update'}}
                </button>
              </span>
						</ng-container>
						<ng-container *ngIf="isLoading$">
							<span class="indicator-progress" [style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
            <!-- </button>  -->
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<!-- END SCAN -->

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
