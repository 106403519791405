import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../_services/master.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { ChartComponent, ApexNonAxisChartSeries,  ApexResponsive, ApexChart, ApexDataLabels, ApexStroke, ApexLegend } from "ng-apexcharts";


export interface Pendaftaranrun {
  id?: any;
  // shortname?: string,
  // name?: string,
  // phonecode?: string,
  run_idnumber?: string,
  run_category?: string,
  run_email?: string,
  run_firstname?:string,
  run_lastname?:string,
  run_namabip?:string,
  run_nobip?:string,
  run_phone?: string,
  action?: string
}

export type ChartOptions = {
  series: ApexNonAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive[] | any;
  labels: any;
  dataLabels: ApexDataLabels | any;
  stroke: ApexStroke | any;
  colors: string[] | any;
  legend: ApexLegend | any;
};

@Component({
  selector: 'app-index1',
  templateUrl: './index1.component.html',
  styleUrls: ['./index1.component.css']
})
export class Index1Component implements OnInit {

  // CHART
  @ViewChild("chart") chart!: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  // @ViewChild('chart') chart!: ChartComponent;
	// public chartOptions: Partial<ChartOptions>;
	chartOptions: any = {};
  // 
  pendaftarans: Pendaftaranrun[] = [];
  tutorialsTemp: Pendaftaranrun[] = [];
  // COUNT DOWNS
  date: any;
  now: any;
  targetDate: any = new Date(2024, 8, 11);
  targetTime: any = this.targetDate.getTime();
  difference: any;
  // days:any;
  // hours:any;
  // minutes:any;
  // seconds:any;
  months: Array<string> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  currentTime: any = `${
    this.months[this.targetDate.getMonth()]
  } ${this.targetDate.getDate()}, ${this.targetDate.getFullYear()}`;

  @ViewChild('days', { static: true })
  days!: ElementRef;
  @ViewChild('hours', { static: true }) hours!: ElementRef;
  @ViewChild('minutes', { static: true }) minutes!: ElementRef;
  @ViewChild('seconds', { static: true }) seconds!: ElementRef;
  // END COUNT DOWN

  @Input() data:any;

  datenow = new Date();

  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  tutorials: any;
  totalpayments: any;
  totalbill: any;
  totalinbh: any;
  totalinbb: any;
  totalinbt: any;
  totalshpph: any;
  totalshpph1: any;
  totalshppb: any;
  totalshppb1: any;
  totalshppt: any;
  totalshppt1: any;
  sum3KMales: any;
  sum3KFemales: any;
  sum5KMales: any;
  sum5KFemales: any;
  sum10KMales: any;
  sum10KFemales: any;
  sum21KMales: any;
  sum21KFemales: any;
  sum21KMMales: any;
  sum21KMFemales: any;
  sum21KM1Males: any;
  sum21KM1Females: any;
  sum5KRMales: any;
  sum5KRFemales: any;
  sum10KRMales: any;
  sum10KRFemales: any;

  sum5KRIMales: any;
  sum5KRIFemales: any;

  sum5KRIJMales: any;
  sum5KRIJFemales: any;

  sum5KRIJKMales: any;
  sum5KRIJKFemales: any;

  sum5KRIJLMales: any; // WHR
  sum5KRIJLFemales: any; // WHR

  // BRM
  sum5KBRMMales: any; // WHR
  sum5KBRMFemales: any; // WHR
  sum10KBRMMales: any; // WHR
  sum10KBRMFemales: any; // WHR

  Vsum3KLunas: any;
  Vsum5KLunas: any;
  Vsum10KLunas: any;
  Vsum21KLunas: any;
  Vsum21KMLunas: any;
  Vsum21KM1Lunas: any;
  
  Vsum5KRLunas: any;
  Vsum10KRLunas: any;

  // BRIMOB
  Vsum5KBRMLunas: any;
  Vsum10KBRMLunas: any;
  

  VsumTotalPeserta3KLunas: any;
  VsumTotalPeserta5KLunas: any;
  VsumTotalPeserta10KLunas: any;
  VsumTotalPeserta21KLunas: any;
  VsumTotalPeserta21KMLunas: any;
  VsumTotalPeserta21KM1Lunas: any;

  VsumTotalPeserta5KRLunas: any;
  VsumTotalPeserta10KRLunas: any;

  // BRIMOB
  VsumTotalPeserta5KBRMLunas: any;
  VsumTotalPeserta10KBRMLunas: any;

  Vsum5KRILunas: any;
  VsumTotalPeserta5KRILunas: any;

  Vsum5KRIJLunas: any;
  VsumTotalPeserta5KRIJLunas: any;

  Vsum5KRIJKLunas: any;
  VsumTotalPeserta5KRIJKLunas: any;

  // WAHAHA
  Vsum5KRIJLLunas: any;
  VsumTotalPeserta5KRIJLLunas: any;


  VsumSaldoS: any;
  VsumSaldoM: any;
  VsumSaldoL: any;
  VsumSaldoXL: any;
  VsumSaldopS: any;
  VsumSaldopM: any;
  VsumSaldopL: any;
  VsumSaldopXL: any;
  VsumSaldosisaS: any;
  VsumSaldosisaM: any;
  VsumSaldosisaL: any;
  VsumSaldosisaXL: any;

  // PSMTI
  VsumSaldoXS1: any;
  VsumSaldopXS1: any;
  VsumSaldosisaXS1: any;
  VsumSaldoS1: any;
  VsumSaldopS1: any;
  VsumSaldosisaS1: any;
  VsumSaldoM1: any;
  VsumSaldopM1: any;
  VsumSaldosisaM1: any;
  VsumSaldoL1: any;
  VsumSaldopL1: any;
  VsumSaldosisaL1: any;
  VsumSaldoXL1: any;
  VsumSaldopXL1: any;
  VsumSaldosisaXL1: any;
  VsumSaldoXXL1: any;
  VsumSaldopXXL1: any;
  VsumSaldosisaXXL1: any;

  // IMA
  VsumSaldoS2: any;
  VsumSaldopS2: any;
  VsumSaldosisaS2: any;
  VsumSaldoM2: any;
  VsumSaldopM2: any;
  VsumSaldosisaM2: any;
  VsumSaldoL2: any;
  VsumSaldopL2: any;
  VsumSaldosisaL2: any;
  VsumSaldoXL2: any;
  VsumSaldopXL2: any;
  VsumSaldosisaXL2: any;
  VsumSaldoXXL2: any;
  VsumSaldopXXL2: any;
  VsumSaldosisaXXL2: any;

  // ONA
  VsumSaldoS4: any;
  VsumSaldopS4: any;
  VsumSaldosisaS4: any;
  VsumSaldoM4: any;
  VsumSaldopM4: any;
  VsumSaldosisaM4: any;
  VsumSaldoL4: any;
  VsumSaldopL4: any;
  VsumSaldosisaL4: any;
  VsumSaldoXL4: any;
  VsumSaldopXL4: any;
  VsumSaldosisaXL4: any;

  
  // UNL
  VsumSaldoS5: any;
  VsumSaldopS5: any;
  VsumSaldosisaS5: any;
  VsumSaldoM5: any;
  VsumSaldopM5: any;
  VsumSaldosisaM5: any;
  VsumSaldoL5: any;
  VsumSaldopL5: any;
  VsumSaldosisaL5: any;
  VsumSaldoXL5: any;
  VsumSaldopXL5: any;
  VsumSaldosisaXL5: any;
  VsumSaldoXXL5: any;
  VsumSaldopXXL5: any;
  VsumSaldosisaXXL5: any;

   // WAHAHA
   VsumSaldoS6: any;
   VsumSaldopS6: any;
   VsumSaldosisaS6: any;
   VsumSaldoM6: any;
   VsumSaldopM6: any;
   VsumSaldosisaM6: any;
   VsumSaldoL6: any;
   VsumSaldopL6: any;
   VsumSaldosisaL6: any;
   VsumSaldoXL6: any;
   VsumSaldopXL6: any;
   VsumSaldosisaXL6: any;
   VsumSaldoXXL6: any;
   VsumSaldopXXL6: any;
   VsumSaldosisaXXL6: any;

// TOTAL KATEGORI SALDO
  VsumSaldoS3K: any;
  VsumSaldopS3K: any;
  VsumSaldosisaS3K: any;

  VsumSaldoS5K: any;
  VsumSaldopS5K: any;
  VsumSaldosisaS5K: any;

  VsumSaldoS10K: any;
  VsumSaldopS10K: any;
  VsumSaldosisaS10K: any;

  VsumSaldoS21K: any;
  VsumSaldopS21K: any;
  VsumSaldosisaS21K: any;

  VsumSaldoS21KM: any;
  VsumSaldopS21KM: any;
  VsumSaldosisaS21KM: any;

  VsumSaldoS21KM1: any;
  VsumSaldopS21KM1: any;
  VsumSaldosisaS21KM1: any;


  VsumSaldoS5KR: any;
  VsumSaldopS5KR: any;
  VsumSaldosisaS5KR: any;

  VsumSaldoM10KR: any;
  VsumSaldopM10KR: any;
  VsumSaldosisaM10KR: any;

  // BRIM
  VsumSaldoS5KBRM: any;
  VsumSaldopS5KBRM: any;
  VsumSaldosisaS5KBRM: any;

  VsumSaldoM10KBRM: any;
  VsumSaldopM10KBRM: any;
  VsumSaldosisaM10KBRM: any;

  VsumSaldoS5KRI: any;
  VsumSaldopS5KRI: any;
  VsumSaldosisaS5KRI: any;

  VsumSaldoS5KRIJ: any;
  VsumSaldopS5KRIJ: any;
  VsumSaldosisaS5KRIJ: any;

  totalshawbkgpercen: any;
  totalshawbkgpercen1: any;
  totalshawbkgpercen0: any;
  totalshawbkgpercen2: any;
  totalshawbkgpercen3s: any;
  totalshawbkgpercen3: any;
  totalshawbkgpercen4s: any;
  totalshawbkgpercen4: any;
  totalshawbkgpercen5s: any;
  totalshawbkgpercen5: any;
  totalshawbkgpercen6s: any;
  totalshawbkgpercen6: any;
  totalshawbkgpercen7s: any;
  totalshawbkgpercen7: any;

  // sum5KR1Males: any;
  // sum5KR1Females: any;
  // sum10KR1Males: any;
  // sum10KR1Females: any;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
  ) {
    
    // const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // params.tglinput = this.datenow;

    // this.tutorialService.getAlls('dashboards/genderkategori', params)
    // .subscribe(
    //   response => {
    //     const { data, totalItems } = response;
    //     this.tutorials = data;
    //     this.count = totalItems;
    //     // console.log(response.rows)
    //     let rdata = response.rows
    //     let sum3KMale = 0;
    //     let sum3KFemale = 0;
    //     let sum5KMale = 0;
    //     let sum5KFemale = 0;
    //     let sum10KMale = 0;
    //     let sum10KFemale = 0;
    //     let sum21KMale = 0;
    //     let sum21KFemale = 0;
    //     let sum21KMMale = 0;
    //     let sum21KMFemale = 0;
    //     let sum21KM1Male = 0;
    //     let sum21KM1Female = 0;
    //     let sum5KRMale = 0;
    //     let sum5KRFemale = 0;
    //     let sum10KRMale = 0;
    //     let sum10KRFemale = 0;
    //     for (let i = 0; i < rdata.length; i++) {
    //       if(rdata[i].run_category == '3K' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum3KMale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '3K' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum3KFemale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '5K' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum5KMale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '5K' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum5KFemale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '10K' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum10KMale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '10K' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum10KFemale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '21K' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum21KMale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '21K' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum21KFemale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '21KM' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum21KMMale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '21KM' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum21KMFemale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '21KM1' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum21KM1Male += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '21KM1' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum21KM1Female += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '5KR' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum5KRMale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '5KR' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum5KRFemale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '10KR' && rdata[i].run_gender == 'Male'){ // Laki
    //         sum10KRMale += rdata[i].total;
    //       }
    //       if(rdata[i].run_category == '10KR' && rdata[i].run_gender == 'Female'){ // Perempuan
    //         sum10KRFemale += rdata[i].total;
    //       }
    //     }
      
    //     this.sum3KMales = sum3KMale;
    //     this.sum3KFemales = sum3KFemale;
    //     this.sum5KMales = sum5KMale;
    //     this.sum5KFemales = sum5KFemale;
    //     this.sum10KMales = sum10KMale;
    //     this.sum10KFemales = sum10KFemale;
    //     this.sum21KMales = sum21KMale;
    //     this.sum21KFemales = sum21KFemale;
    //     this.sum21KMMales = sum21KMMale;
    //     this.sum21KMFemales = sum21KMFemale;
    //     this.sum21KM1Males = sum21KM1Male;
    //     this.sum21KM1Females = sum21KM1Female;
    //     this.sum5KRMales = sum5KRMale;
    //     this.sum5KRFemales = sum5KRFemale;
    //     this.sum10KRMales = sum10KRMale;
    //     this.sum10KRFemales = sum10KRFemale;

    //     this.chartOptions = {
    //       series: [44, 55, 13, 43, 22],
    //       chart: {
    //         type: "donut"
    //       },
    //       labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
    //       responsive: [
    //         {
    //           breakpoint: 480,
    //           options: {
    //             chart: {
    //               width: 200
    //             },
    //             legend: {
    //               position: "bottom"
    //             }
    //           }
    //         }
    //       ]
    //     };
        
    //   },
    //   error => {
    //     // console.log(error);
    //   });

  }

    usersData:any = {};
    rolesString:any = '';
    isAdmin:boolean = false;
    isSuperadmin:boolean = false;
    // isCustomer:boolean = false;
    // isAgen:boolean = false;
    // isFinance:boolean = false;
    // isCleansings:boolean = false;
    // isInbound:boolean = false;
    isPeserta:boolean = false;
    getUserData() {
      this.tokenStorage.getUsers().then(async res => {
        this.usersData = res;
        // console.log(res,'ress')
        this.usersData.role.forEach((e:any) => {
          this.rolesString = this.rolesString+e.name+', ';
        });
        // console.log(this.rolesString)
        if(this.rolesString.includes('Admin')) {
          this.isAdmin = true;
        }
        if(this.rolesString.includes('Superadmin')) {
          this.isSuperadmin = true;
        }
        if(this.rolesString.includes('Peserta')) {
          this.isPeserta = true;
        }
        // if(this.rolesString.includes('Agen')) {
        //   this.isAgen = true;
        // }
        // if(this.rolesString.includes('Finance')) {
        //   this.isFinance = true;
        // }
        // if(this.rolesString.includes('Cleansings')) {
        //   this.isCleansings = true;
        // }
        // if(this.rolesString.includes('Inbound')) {
        //   this.isInbound = true;
        // }
        
        // console.log(this.isAdmin)
      })
    }

  ngOnInit(): void {
    // this.retrieveInbounds();
    // this.retrieveInboundbulan();
    // this.retrieveInboundtahun();
    // this.retrieveShipmenthari();
    // this.retrieveShipmentbulan();
    // this.retrieveShipmenttahun();
    // this.retrieveTutorials();
    this.retrieveTotalKategoris();
    this.retrieveTotalTagihan();
    this.retrieveTotalSize();
    this.retrieveTotalKategori();
    // this.retrieveTagihans();
    this.getUserData();
    this.retrievePendaftarans();

    // console.log(this.sum5KRMales,'this.sum5KRMales')
  }

  // START COUNTDOWN
  ngAfterViewInit() {
    setInterval(() => {
      this.tickTock();
      this.difference = this.targetTime - this.now;
      this.difference = this.difference / (1000 * 60 * 60 * 24);

      !isNaN(this.days.nativeElement.innerText)
        ? (this.days.nativeElement.innerText = Math.floor(this.difference))
        : (this.days.nativeElement.innerHTML = `<img src="https://i.gifer.com/VAyR.gif" />`);
    }, 1000);
  }

  tickTock() {
    this.date = new Date();
    this.now = this.date.getTime();
    this.days.nativeElement.innerText = Math.floor(this.difference);
    this.hours.nativeElement.innerText = 23 - this.date.getHours();
    this.minutes.nativeElement.innerText = 60 - this.date.getMinutes();
    this.seconds.nativeElement.innerText = 60 - this.date.getSeconds();
  }
  // END COUNT DOWN

  images = [
    'assets/images/banner.jpg',
    'assets/images/banner2.png'
  ];
  // images = [
  //   'assets/images/big/img1.jpg',
  //   'assets/images/big/img3.jpg',
  // ];
  handlePageChange(event: number): void {
    this.page = event;
    this.retrievePendaftarans();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrievePendaftarans();
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/pembayarans', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response.rows)
        let rdata = response.rows
        let sumpembayarans = 0;
        for (let i = 0; i < rdata.length; i++) {
          // const element = rdata[i];
          // console.log(rdata[i].totalbayar)
          sumpembayarans += rdata[i].totalbayar;
          
        }
        // for (i = 0; i < response.rows.length; i++) {
        //   // bulanKyw.push(data[i].bulan);
        //     totalKyw.push(parseInt(data[i].total == undefined ? 0 : data[i].total));
        // };

        // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
        this.totalpayments = this.currencyStr(sumpembayarans)
        // console.log(response.rows[0].totalbayar);
      },
      error => {
        // console.log(error);
      });
  };

  retrieveTotalKategoris(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/genderkategori', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response.rows)
        let rdata = response.rows
        let sum3KMale = 0;
        let sum3KFemale = 0;
        let sum5KMale = 0;
        let sum5KFemale = 0;
        let sum10KMale = 0;
        let sum10KFemale = 0;
        let sum21KMale = 0;
        let sum21KFemale = 0;
        let sum21KMMale = 0;
        let sum21KMFemale = 0;
        let sum21KM1Male = 0;
        let sum21KM1Female = 0;
        let sum5KRMale = 0;
        let sum5KRFemale = 0;
        let sum10KRMale = 0;
        let sum10KRFemale = 0;
        let sum5KRIMale = 0;
        let sum5KRIFemale = 0;
        let sum5KRIJMale = 0;
        let sum5KRIJFemale = 0;
        let sum5KRIJKMale = 0;
        let sum5KRIJKFemale = 0;
        let sum5KRIJLMale = 0;
        let sum5KRIJLFemale = 0;
        let sum5KBRMMale = 0;
        let sum5KBRMFemale = 0;
        let sum10KBRMMale = 0;
        let sum10KBRMFemale = 0;
        // let sum5KR1Male = 0;
        // let sum5KR1Female = 0;
        // let sum10KR1Male = 0;
        // let sum10KR1Female = 0;
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].run_category == '3K' && rdata[i].run_gender == 'Male'){ // Laki
            sum3KMale += rdata[i].total;
          }
          if(rdata[i].run_category == '3K' && rdata[i].run_gender == 'Female'){ // Perempuan
            sum3KFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '5K' && rdata[i].run_gender == 'Male'){ // Laki
            sum5KMale += rdata[i].total;
          }
          if(rdata[i].run_category == '5K' && rdata[i].run_gender == 'Female'){ // Perempuan
            sum5KFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '10K' && rdata[i].run_gender == 'Male'){ // Laki
            sum10KMale += rdata[i].total;
          }
          if(rdata[i].run_category == '10K' && rdata[i].run_gender == 'Female'){ // Perempuan
            sum10KFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '21K' && rdata[i].run_gender == 'Male'){ // Laki
            sum21KMale += rdata[i].total;
          }
          if(rdata[i].run_category == '21K' && rdata[i].run_gender == 'Female'){ // Perempuan
            sum21KFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '21KM' && rdata[i].run_gender == 'Male'){ // Laki
            sum21KMMale += rdata[i].total;
          }
          if(rdata[i].run_category == '21KM' && rdata[i].run_gender == 'Female'){ // Perempuan
            sum21KMFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '21KM1' && rdata[i].run_gender == 'Male'){ // Laki
            sum21KM1Male += rdata[i].total;
          }
          if(rdata[i].run_category == '21KM1' && rdata[i].run_gender == 'Female'){ // Perempuan
            sum21KM1Female += rdata[i].total;
          }
          if(rdata[i].run_category == '5KR' && rdata[i].run_gender == 'Male'){ // Laki BYK
            sum5KRMale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KR' && rdata[i].run_gender == 'Female'){ // Perempuan BYK
            sum5KRFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '10KR' && rdata[i].run_gender == 'Male'){ // Laki BYK
            sum10KRMale += rdata[i].total;
          }
          if(rdata[i].run_category == '10KR' && rdata[i].run_gender == 'Female'){ // Perempuan BYK
            sum10KRFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRI' && rdata[i].run_gender == 'Male'){ // Laki IMA
            sum5KRIMale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRI' && rdata[i].run_gender == 'Female'){ // Perempuan IMA
            sum5KRIFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRIJ' && rdata[i].run_gender == 'Male'){ // Laki ONAGO
            sum5KRIJMale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRIJ' && rdata[i].run_gender == 'Female'){ // Perempuan ONAGO
            sum5KRIJFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRIJK' && rdata[i].run_gender == 'Male'){ // Laki UNL
            sum5KRIJKMale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRIJK' && rdata[i].run_gender == 'Female'){ // Perempuan UNL
            sum5KRIJKFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRIJL' && rdata[i].run_gender == 'Male'){ // Laki WHR
            sum5KRIJLMale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KRIJL' && rdata[i].run_gender == 'Female'){ // Perempuan WHR
            sum5KRIJLFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KBRM' && rdata[i].run_gender == 'Male'){ // Laki WHR
            sum5KBRMMale += rdata[i].total;
          }
          if(rdata[i].run_category == '5KBRM' && rdata[i].run_gender == 'Female'){ // Perempuan WHR
            sum5KBRMFemale += rdata[i].total;
          }
          if(rdata[i].run_category == '10KBRM' && rdata[i].run_gender == 'Male'){ // Laki WHR
            sum10KBRMMale += rdata[i].total;
          }
          if(rdata[i].run_category == '10KBRM' && rdata[i].run_gender == 'Female'){ // Perempuan WHR
            sum10KBRMFemale += rdata[i].total;
          }
          // if(rdata[i].run_category == '5KR' && rdata[i].run_gender == 'Male'){ // Laki
          //   sum5KR1Male += rdata[i].total;
          // }
          // if(rdata[i].run_category == '5KR' && rdata[i].run_gender == 'Female'){ // Perempuan
          //   sum5KR1Female += rdata[i].total;
          // }
          // if(rdata[i].run_category == '10KR' && rdata[i].run_gender == 'Male'){ // Laki
          //   sum10KR1Male += rdata[i].total;
          // }
          // if(rdata[i].run_category == '10KR' && rdata[i].run_gender == 'Female'){ // Perempuan
          //   sum10KR1Female += rdata[i].total;
          // }
        }
      
        this.sum3KMales = sum3KMale;
        this.sum3KFemales = sum3KFemale;
        this.sum5KMales = sum5KMale;
        this.sum5KFemales = sum5KFemale;
        this.sum10KMales = sum10KMale;
        this.sum10KFemales = sum10KFemale;
        this.sum21KMales = sum21KMale;
        this.sum21KFemales = sum21KFemale;
        this.sum21KMMales = sum21KMMale;
        this.sum21KMFemales = sum21KMFemale;
        this.sum21KM1Males = sum21KM1Male;
        this.sum21KM1Females = sum21KM1Female;

        this.totalshawbkgpercen1 = Math.abs(((sum3KMale+sum3KFemale+sum5KMale+sum5KFemale+sum10KMale+sum10KFemale+sum21KMale+sum21KFemale+sum21KMMale+sum21KMMale+sum21KMFemale+sum21KM1Male+sum21KM1Female) / 2500) * 100)
        this.totalshawbkgpercen = parseFloat(this.totalshawbkgpercen1).toFixed(2);
        
        
        this.sum5KRMales = sum5KRMale;
        this.sum5KRFemales = sum5KRFemale;
        this.sum10KRMales = sum10KRMale;
        this.sum10KRFemales = sum10KRFemale;

        this.totalshawbkgpercen0 = Math.abs((((sum5KRMale+sum5KRFemale+sum10KRMale+sum10KRFemale)) / 1467) * 100);
        this.totalshawbkgpercen2 = parseFloat(this.totalshawbkgpercen0).toFixed(2);


        this.sum5KRIMales = sum5KRIMale;
        this.sum5KRIFemales = sum5KRIFemale;

        this.totalshawbkgpercen3s = Math.abs((((sum5KRIMale+sum5KRIFemale)) / 1000) * 100);
        this.totalshawbkgpercen3 = parseFloat(this.totalshawbkgpercen3s).toFixed(2);

        this.sum5KRIJMales = sum5KRIJMale;
        this.sum5KRIJFemales = sum5KRIJFemale;

        this.totalshawbkgpercen4s = Math.abs((((sum5KRIJMale+sum5KRIJFemale)) / 1000) * 100);
        this.totalshawbkgpercen4 = parseFloat(this.totalshawbkgpercen4s).toFixed(2);

        this.sum5KRIJKMales = sum5KRIJKMale;
        this.sum5KRIJKFemales = sum5KRIJKFemale;

        this.totalshawbkgpercen5s = Math.abs((((sum5KRIJKMale+sum5KRIJKFemale)) / 1000) * 100);
        this.totalshawbkgpercen5 = parseFloat(this.totalshawbkgpercen5s).toFixed(2);

        this.sum5KRIJLMales = sum5KRIJLMale;
        this.sum5KRIJLFemales = sum5KRIJLFemale;

        this.totalshawbkgpercen6s = Math.abs((((sum5KRIJLMale+sum5KRIJLFemale)) / 1000) * 100);
        this.totalshawbkgpercen6 = parseFloat(this.totalshawbkgpercen6s).toFixed(2);

        this.sum5KBRMMales = sum5KBRMMale;
        this.sum5KBRMFemales = sum5KBRMFemale;
        this.sum10KBRMMales = sum10KBRMMale;
        this.sum10KBRMFemales = sum10KBRMFemale;

        this.totalshawbkgpercen7s= Math.abs((((sum5KBRMMale+sum5KBRMFemale+sum10KBRMMale+sum10KBRMFemale)) / 1000) * 100);
        this.totalshawbkgpercen7 = parseFloat(this.totalshawbkgpercen7s).toFixed(2);
        // this.sum5KR1Males = sum5KR1Male;
        // this.sum5KR1Females = sum5KR1Female;
        // this.sum10KR1Males = sum10KR1Male;
        // this.sum10KR1Females = sum10KR1Female;
        // this.chartOptions = {
        //   series: [sum5KRMale, sum5KRFemale, sum10KRMale, sum10KRFemale],
        //   chart: {
        //     width: 380,
        //     type: "donut"
        //   },
        //   plotOptions: {
        //     pie: {
        //       donut: {
        //         labels: {
        //           show: true,
        //           total: {
        //             showAlways: true,
        //             show: true
        //           }
        //         }
        //       }
        //     }
        //   },
        //   dataLabels: {
        //     enabled: true
        //   },
        //   labels: ["Male 5K", "Female 5K", "Male 10K", "Female 10K"],
        //   fill: {
        //     type: "gradient"
        //   },
        //   legend: {
        //     formatter: function(val:any, opts:any) {
        //       return val + " - " + opts.w.globals.series[opts.seriesIndex];
        //     }
        //   },
        //   responsive: [
        //     {
        //       breakpoint: 480,
        //       options: {
        //         chart: {
        //           width: 200
        //         },
        //         legend: {
        //           position: "bottom"
        //         }
        //       }
        //     }
        //   ]
        // };
        // console.log(response.rows[0].totalbayar);
      },
      error => {
        // console.log(error);
      });
  };

  retrieveTotalTagihan(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/tagihannbayar', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response,'s')
        let rdata = response.rows;

        let sum3KLunas = 0;
        let sumTotalPeserta3KLunas = 0;
        let sum5KLunas = 0;
        let sumTotalPeserta5KLunas = 0;
        let sum10KLunas = 0;
        let sumTotalPeserta10KLunas = 0;
        let sum21KLunas = 0;
        let sumTotalPeserta21KLunas = 0;
        let sum21KMLunas = 0;
        let sumTotalPeserta21KMLunas = 0;
        let sum21KM1Lunas = 0;
        let sumTotalPeserta21KM1Lunas = 0;

        let sum10KRLunas = 0;
        let sum1TotalPeserta10KRLunas = 0;

        let sum5KRLunas = 0;
        let sumTotalPeserta5KRLunas = 0;

        // BRIMOB
        let sum10KBRMLunas = 0;
        let sum1TotalPeserta10KBRMLunas = 0;
        let sum5KBRMLunas = 0;
        let sumTotalPeserta5KBRMLunas = 0;

        let sum5KRILunas = 0;
        let sumTotalPeserta5KRILunas = 0;

        let sum5KRIJLunas = 0;
        let sumTotalPeserta5KRIJLunas = 0;

        let sum5KRIJKLunas = 0;
        let sumTotalPeserta5KRIJKLunas = 0;

        let sum5KRIJLLunas = 0;
        let sumTotalPeserta5KRIJLLunas = 0;
      
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].status_bayar == '00' && rdata[i].client == '' && rdata[i].run_category == '3K'){ // LUNAS
            sum3KLunas += rdata[i].total;
            sumTotalPeserta3KLunas += rdata[i].totalpeserta;
          }
          if(rdata[i].status_bayar == '00' && rdata[i].client == '' && rdata[i].run_category == '5K'){ // LUNAS
            sum5KLunas += rdata[i].total;
            sumTotalPeserta5KLunas += rdata[i].totalpeserta;
          }
          if(rdata[i].status_bayar == '00' && rdata[i].client == '' && rdata[i].run_category == '10K'){ // LUNAS
            sum10KLunas += rdata[i].total;
            sumTotalPeserta10KLunas += rdata[i].totalpeserta;
          }
          if(rdata[i].status_bayar == '00' && rdata[i].client == '' && rdata[i].run_category == '21K'){ // LUNAS
            sum21KLunas += rdata[i].total;
            sumTotalPeserta21KLunas += rdata[i].totalpeserta;
          }
          if(rdata[i].status_bayar == '00' && rdata[i].client == '' && rdata[i].run_category == '21KM'){ // LUNAS
            sum21KMLunas += rdata[i].total;
            sumTotalPeserta21KMLunas += rdata[i].totalpeserta;
          }
          if(rdata[i].status_bayar == '00' && rdata[i].client == '' && rdata[i].run_category == '21KM1'){ // LUNAS
            sum21KM1Lunas += rdata[i].total;
            sumTotalPeserta21KM1Lunas += rdata[i].totalpeserta;
          }


          if(rdata[i].status_bayar == '00' && rdata[i].client == 'BYK' && rdata[i].run_category == '5KR'){ // LUNAS
            sum5KRLunas += rdata[i].total;
            sumTotalPeserta5KRLunas += rdata[i].totalpeserta;
          }
          if(rdata[i].status_bayar == '00' && rdata[i].client == 'BYK' && rdata[i].run_category == '10KR'){ // Lunas
            sum10KRLunas += rdata[i].total;
            sum1TotalPeserta10KRLunas += rdata[i].totalpeserta;
          }

          if(rdata[i].status_bayar == '00' && rdata[i].client == 'IMA' && rdata[i].run_category == '5KRI'){ // LUNAS
            sum5KRILunas += rdata[i].total;
            sumTotalPeserta5KRILunas += rdata[i].totalpeserta;
          }

          if(rdata[i].status_bayar == '00' && rdata[i].client == 'ONA' && rdata[i].run_category == '5KRIJ'){ // LUNAS
            sum5KRIJLunas += rdata[i].total;
            sumTotalPeserta5KRIJLunas += rdata[i].totalpeserta;
          }

          if(rdata[i].status_bayar == '00' && rdata[i].client == 'UNL' && rdata[i].run_category == '5KRIJK'){ // LUNAS
            sum5KRIJKLunas += rdata[i].total;
            sumTotalPeserta5KRIJKLunas += rdata[i].totalpeserta;
          }

          if(rdata[i].status_bayar == '00' && rdata[i].client == 'WHR' && rdata[i].run_category == '5KRIJL'){ // LUNAS
            sum5KRIJLLunas += rdata[i].total;
            sumTotalPeserta5KRIJLLunas += rdata[i].totalpeserta;
          }

          // BRIM
          if(rdata[i].status_bayar == '00' && rdata[i].client == 'BRM' && rdata[i].run_category == '5KBRM'){ // LUNAS
            sum5KBRMLunas += rdata[i].total;
            sumTotalPeserta5KBRMLunas += rdata[i].totalpeserta;
          }
          if(rdata[i].status_bayar == '00' && rdata[i].client == 'BRM' && rdata[i].run_category == '10KBRM'){ // Lunas
            sum10KBRMLunas += rdata[i].total;
            sum1TotalPeserta10KBRMLunas += rdata[i].totalpeserta;
          }
          
        }
      
        this.Vsum3KLunas = sum3KLunas;
        this.VsumTotalPeserta3KLunas = sumTotalPeserta3KLunas;
        this.Vsum5KLunas = sum5KLunas;
        this.VsumTotalPeserta5KLunas = sumTotalPeserta5KLunas;
        this.Vsum10KLunas = sum10KLunas;
        this.VsumTotalPeserta10KLunas = sumTotalPeserta10KLunas;
        this.Vsum21KLunas = sum21KLunas;
        this.VsumTotalPeserta21KLunas = sumTotalPeserta21KLunas;
        this.Vsum21KMLunas = sum21KMLunas;
        this.VsumTotalPeserta21KMLunas = sumTotalPeserta21KMLunas;
        this.Vsum21KM1Lunas = sum21KM1Lunas;
        this.VsumTotalPeserta21KM1Lunas = sumTotalPeserta21KM1Lunas;


        this.Vsum10KRLunas = sum10KRLunas;
        this.VsumTotalPeserta10KRLunas = sum1TotalPeserta10KRLunas;

        this.Vsum5KRLunas = sum5KRLunas;   
        this.VsumTotalPeserta5KRLunas = sumTotalPeserta5KRLunas;  

        this.Vsum10KBRMLunas = sum10KBRMLunas;
        this.VsumTotalPeserta10KBRMLunas = sum1TotalPeserta10KBRMLunas;
        this.Vsum5KBRMLunas = sum5KBRMLunas;   
        this.VsumTotalPeserta5KBRMLunas = sumTotalPeserta5KBRMLunas;  
        
        this.Vsum5KRILunas = sum5KRILunas;   
        this.VsumTotalPeserta5KRILunas = sumTotalPeserta5KRILunas; 

        this.Vsum5KRIJLunas = sum5KRIJLunas;   
        this.VsumTotalPeserta5KRIJLunas = sumTotalPeserta5KRIJLunas; 

        this.Vsum5KRIJKLunas = sum5KRIJKLunas;   
        this.VsumTotalPeserta5KRIJKLunas = sumTotalPeserta5KRIJKLunas; 

        this.Vsum5KRIJLLunas = sum5KRIJLLunas;   
        this.VsumTotalPeserta5KRIJLLunas = sumTotalPeserta5KRIJLLunas; 
        // console.log(response.rows[0].totalbayar);
      },
      error => {
        // console.log(error);
      });
  };
  retrieveTotalSize(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/totalSize', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        console.log(response,'s')
        let rdata = response.rows
        let sumSaldoS = 0;
        let sumSaldosisaS = 0;
        let sumSaldopS = 0;
        let sumSaldoM = 0;
        let sumSaldopM = 0;
        let sumSaldosisaM = 0;
        let sumSaldoL = 0;
        let sumSaldopL = 0;
        let sumSaldosisaL = 0;
        let sumSaldoXL = 0;
        let sumSaldopXL = 0;
        let sumSaldosisaXL = 0;
        // PSMTI
        let sumSaldoXS1 = 0;
        let sumSaldosisaXS1 = 0;
        let sumSaldopXS1 = 0;

        let sumSaldoS1 = 0;
        let sumSaldosisaS1 = 0;
        let sumSaldopS1 = 0;
        let sumSaldoM1 = 0;
        let sumSaldopM1 = 0;
        let sumSaldosisaM1 = 0;
        let sumSaldoL1 = 0;
        let sumSaldopL1 = 0;
        let sumSaldosisaL1 = 0;
        let sumSaldoXL1 = 0;
        let sumSaldopXL1 = 0;
        let sumSaldosisaXL1 = 0;
        let sumSaldoXXL1 = 0;
        let sumSaldopXXL1 = 0;
        let sumSaldosisaXXL1 = 0;

        // IMA
        let sumSaldoS2 = 0;
        let sumSaldosisaS2 = 0;
        let sumSaldopS2 = 0;
        let sumSaldoM2 = 0;
        let sumSaldopM2 = 0;
        let sumSaldosisaM2 = 0;
        let sumSaldoL2 = 0;
        let sumSaldopL2 = 0;
        let sumSaldosisaL2 = 0;
        let sumSaldoXL2 = 0;
        let sumSaldopXL2 = 0;
        let sumSaldosisaXL2 = 0;
        let sumSaldoXXL2 = 0;
        let sumSaldopXXL2 = 0;
        let sumSaldosisaXXL2 = 0;

        // ONA
        // IMA
        let sumSaldoS4 = 0;
        let sumSaldosisaS4 = 0;
        let sumSaldopS4 = 0;
        let sumSaldoM4 = 0;
        let sumSaldopM4 = 0;
        let sumSaldosisaM4 = 0;
        let sumSaldoL4 = 0;
        let sumSaldopL4 = 0;
        let sumSaldosisaL4 = 0;
        let sumSaldoXL4 = 0;
        let sumSaldopXL4 = 0;
        let sumSaldosisaXL4 = 0;

        // UNL
        let sumSaldoS5 = 0;
        let sumSaldosisaS5 = 0;
        let sumSaldopS5 = 0;
        let sumSaldoM5 = 0;
        let sumSaldopM5 = 0;
        let sumSaldosisaM5 = 0;
        let sumSaldoL5 = 0;
        let sumSaldopL5 = 0;
        let sumSaldosisaL5 = 0;
        let sumSaldoXL5 = 0;
        let sumSaldopXL5 = 0;
        let sumSaldosisaXL5 = 0;
        let sumSaldoXXL5 = 0;
        let sumSaldopXXL5 = 0;
        let sumSaldosisaXXL5 = 0;

        // WAHAHA
        let sumSaldoS6 = 0;
        let sumSaldosisaS6 = 0;
        let sumSaldopS6 = 0;
        let sumSaldoM6 = 0;
        let sumSaldopM6 = 0;
        let sumSaldosisaM6 = 0;
        let sumSaldoL6 = 0;
        let sumSaldopL6 = 0;
        let sumSaldosisaL6 = 0;
        let sumSaldoXL6 = 0;
        let sumSaldopXL6 = 0;
        let sumSaldosisaXL6 = 0;
        let sumSaldoXXL6 = 0;
        let sumSaldopXXL6 = 0;
        let sumSaldosisaXXL6 = 0;
      
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].run_sizekaos == 'S' && rdata[i].client == 'BYK'){
            sumSaldoS += rdata[i].saldo;
            sumSaldosisaS += rdata[i].sisa;
            sumSaldopS += rdata[i].totalpeserta;
          }
          if(rdata[i].run_sizekaos == 'M' && rdata[i].client == 'BYK'){
            sumSaldoM += rdata[i].saldo;
            sumSaldopM += rdata[i].totalpeserta;
            sumSaldosisaM += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'L' && rdata[i].client == 'BYK'){
            sumSaldoL += rdata[i].saldo;
            sumSaldopL += rdata[i].totalpeserta;
            sumSaldosisaL += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XL' && rdata[i].client == 'BYK'){
            sumSaldoXL += rdata[i].saldo;
            sumSaldopXL += rdata[i].totalpeserta;
            sumSaldosisaXL += rdata[i].sisa;
          }
          // PSMTI
          if(rdata[i].run_sizekaos == 'XS' && rdata[i].client == ''){
            sumSaldoXS1 += rdata[i].saldo;
            sumSaldosisaXS1 += rdata[i].sisa;
            sumSaldopXS1 += rdata[i].totalpeserta;
          }
          if(rdata[i].run_sizekaos == 'S' && rdata[i].client == ''){
            sumSaldoS1 += rdata[i].saldo;
            sumSaldosisaS1 += rdata[i].sisa;
            sumSaldopS1 += rdata[i].totalpeserta;
          }
          if(rdata[i].run_sizekaos == 'M' && rdata[i].client == ''){
            sumSaldoM1 += rdata[i].saldo;
            sumSaldopM1 += rdata[i].totalpeserta;
            sumSaldosisaM1 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'L' && rdata[i].client == ''){
            sumSaldoL1 += rdata[i].saldo;
            sumSaldopL1 += rdata[i].totalpeserta;
            sumSaldosisaL1 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XL' && rdata[i].client == ''){
            sumSaldoXL1 += rdata[i].saldo;
            sumSaldopXL1 += rdata[i].totalpeserta;
            sumSaldosisaXL1 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XXL' && rdata[i].client == ''){
            sumSaldoXXL1 += rdata[i].saldo;
            sumSaldopXXL1 += rdata[i].totalpeserta;
            sumSaldosisaXXL1 += rdata[i].sisa;
          }
          // IMA
          if(rdata[i].run_sizekaos == 'S' && rdata[i].client == 'IMA'){
            sumSaldoS2 += rdata[i].saldo;
            sumSaldosisaS2 += rdata[i].sisa;
            sumSaldopS2 += rdata[i].totalpeserta;
          }
          if(rdata[i].run_sizekaos == 'M' && rdata[i].client == 'IMA'){
            sumSaldoM2 += rdata[i].saldo;
            sumSaldopM2 += rdata[i].totalpeserta;
            sumSaldosisaM2 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'L' && rdata[i].client == 'IMA'){
            sumSaldoL2 += rdata[i].saldo;
            sumSaldopL2 += rdata[i].totalpeserta;
            sumSaldosisaL2 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XL' && rdata[i].client == 'IMA'){
            sumSaldoXL2 += rdata[i].saldo;
            sumSaldopXL2 += rdata[i].totalpeserta;
            sumSaldosisaXL2 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XXL' && rdata[i].client == 'IMA'){
            sumSaldoXXL2 += rdata[i].saldo;
            sumSaldopXXL2 += rdata[i].totalpeserta;
            sumSaldosisaXXL2 += rdata[i].sisa;
          }
           // ONA
          if(rdata[i].run_sizekaos == 'S' && rdata[i].client == 'ONA'){
            sumSaldoS4 += rdata[i].saldo;
            sumSaldosisaS4 += rdata[i].sisa;
            sumSaldopS4 += rdata[i].totalpeserta;
          }
          if(rdata[i].run_sizekaos == 'M' && rdata[i].client == 'ONA'){
            sumSaldoM4 += rdata[i].saldo;
            sumSaldopM4 += rdata[i].totalpeserta;
            sumSaldosisaM4 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'L' && rdata[i].client == 'ONA'){
            sumSaldoL4 += rdata[i].saldo;
            sumSaldopL4 += rdata[i].totalpeserta;
            sumSaldosisaL4 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XL' && rdata[i].client == 'ONA'){
            sumSaldoXL4 += rdata[i].saldo;
            sumSaldopXL4 += rdata[i].totalpeserta;
            sumSaldosisaXL4 += rdata[i].sisa;
          }

          // UNL
          if(rdata[i].run_sizekaos == 'S' && rdata[i].client == 'UNL'){
            sumSaldoS5 += rdata[i].saldo;
            sumSaldosisaS5 += rdata[i].sisa;
            sumSaldopS5 += rdata[i].totalpeserta;
          }
          if(rdata[i].run_sizekaos == 'M' && rdata[i].client == 'UNL'){
            sumSaldoM5 += rdata[i].saldo;
            sumSaldopM5 += rdata[i].totalpeserta;
            sumSaldosisaM5 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'L' && rdata[i].client == 'UNL'){
            sumSaldoL5 += rdata[i].saldo;
            sumSaldopL5 += rdata[i].totalpeserta;
            sumSaldosisaL5 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XL' && rdata[i].client == 'UNL'){
            sumSaldoXL5 += rdata[i].saldo;
            sumSaldopXL5 += rdata[i].totalpeserta;
            sumSaldosisaXL5 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XXL' && rdata[i].client == 'UNL'){
            sumSaldoXXL5 += rdata[i].saldo;
            sumSaldopXXL5 += rdata[i].totalpeserta;
            sumSaldosisaXXL5 += rdata[i].sisa;
          }

          // WAHAHA
          if(rdata[i].run_sizekaos == 'S' && rdata[i].client == 'WHR'){
            sumSaldoS6 += rdata[i].saldo;
            sumSaldosisaS6 += rdata[i].sisa;
            sumSaldopS6 += rdata[i].totalpeserta;
          }
          if(rdata[i].run_sizekaos == 'M' && rdata[i].client == 'WHR'){
            sumSaldoM6 += rdata[i].saldo;
            sumSaldopM6 += rdata[i].totalpeserta;
            sumSaldosisaM6 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'L' && rdata[i].client == 'WHR'){
            sumSaldoL6 += rdata[i].saldo;
            sumSaldopL6 += rdata[i].totalpeserta;
            sumSaldosisaL6 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XL' && rdata[i].client == 'WHR'){
            sumSaldoXL6 += rdata[i].saldo;
            sumSaldopXL6 += rdata[i].totalpeserta;
            sumSaldosisaXL6 += rdata[i].sisa;
          }
          if(rdata[i].run_sizekaos == 'XXL' && rdata[i].client == 'WHR'){
            sumSaldoXXL6 += rdata[i].saldo;
            sumSaldopXXL6 += rdata[i].totalpeserta;
            sumSaldosisaXXL6 += rdata[i].sisa;
          }
        } 
        
        this.VsumSaldoS = sumSaldoS;
        this.VsumSaldopS = sumSaldopS;
        this.VsumSaldosisaS = sumSaldosisaS;

        this.VsumSaldoM = sumSaldoM;
        this.VsumSaldopM = sumSaldopM;
        this.VsumSaldosisaM = sumSaldosisaM;

        this.VsumSaldoL = sumSaldoL;
        this.VsumSaldopL = sumSaldopL;
        this.VsumSaldosisaL = sumSaldosisaL;

        this.VsumSaldoXL = sumSaldoXL;
        this.VsumSaldopXL = sumSaldopXL;
        this.VsumSaldosisaXL = sumSaldosisaXL;

        // PSMTI
        this.VsumSaldoXS1 = sumSaldoXS1;
        this.VsumSaldopXS1 = sumSaldopXS1;
        this.VsumSaldosisaXS1 = sumSaldosisaXS1;

        // this.VsumSaldoS1 = sumSaldoS1;
        // this.VsumSaldopS1 = sumSaldopS1;
        // this.VsumSaldosisaS1 = sumSaldosisaS1;

        this.VsumSaldoS1 = sumSaldoS1;
        this.VsumSaldopS1 = sumSaldopS1;
        this.VsumSaldosisaS1 = sumSaldosisaS1;

        this.VsumSaldoM1 = sumSaldoM1;
        this.VsumSaldopM1 = sumSaldopM1;
        this.VsumSaldosisaM1 = sumSaldosisaM1;

        this.VsumSaldoL1 = sumSaldoL1;
        this.VsumSaldopL1 = sumSaldopL1;
        this.VsumSaldosisaL1 = sumSaldosisaL1;

        this.VsumSaldoXL1 = sumSaldoXL1;
        this.VsumSaldopXL1 = sumSaldopXL1;
        this.VsumSaldosisaXL1 = sumSaldosisaXL1;

        this.VsumSaldoXXL1 = sumSaldoXXL1;
        this.VsumSaldopXXL1 = sumSaldopXXL1;
        this.VsumSaldosisaXXL1 = sumSaldosisaXXL1;

        // IMA
        // this.VsumSaldoS2 = sumSaldoS2;
        // this.VsumSaldopS2 = sumSaldopS2;
        // this.VsumSaldosisaS2 = sumSaldosisaS2;

        this.VsumSaldoS2 = sumSaldoS2;
        this.VsumSaldopS2 = sumSaldopS2;
        this.VsumSaldosisaS2 = sumSaldosisaS2;

        this.VsumSaldoM2 = sumSaldoM2;
        this.VsumSaldopM2 = sumSaldopM2;
        this.VsumSaldosisaM2 = sumSaldosisaM2;

        this.VsumSaldoL2 = sumSaldoL2;
        this.VsumSaldopL2 = sumSaldopL2;
        this.VsumSaldosisaL2 = sumSaldosisaL2;

        this.VsumSaldoXL2 = sumSaldoXL2;
        this.VsumSaldopXL2 = sumSaldopXL2;
        this.VsumSaldosisaXL2 = sumSaldosisaXL2;

        this.VsumSaldoXXL2 = sumSaldoXXL2;
        this.VsumSaldopXXL2 = sumSaldopXXL2;
        this.VsumSaldosisaXXL2 = sumSaldosisaXXL2;

        // ONA
         // IMA
        // this.VsumSaldoS2 = sumSaldoS2;
        // this.VsumSaldopS2 = sumSaldopS2;
        // this.VsumSaldosisaS2 = sumSaldosisaS2;

        this.VsumSaldoS4 = sumSaldoS4;
        this.VsumSaldopS4 = sumSaldopS4;
        this.VsumSaldosisaS4 = sumSaldosisaS4;

        this.VsumSaldoM4 = sumSaldoM4;
        this.VsumSaldopM4 = sumSaldopM4;
        this.VsumSaldosisaM4 = sumSaldosisaM4;

        this.VsumSaldoL4 = sumSaldoL4;
        this.VsumSaldopL4 = sumSaldopL4;
        this.VsumSaldosisaL4 = sumSaldosisaL4;

        this.VsumSaldoXL4 = sumSaldoXL4;
        this.VsumSaldopXL4 = sumSaldopXL4;
        this.VsumSaldosisaXL4 = sumSaldosisaXL4;

        this.VsumSaldoS5 = sumSaldoS5;
        this.VsumSaldopS5 = sumSaldopS5;
        this.VsumSaldosisaS5 = sumSaldosisaS5;

        this.VsumSaldoM5 = sumSaldoM5;
        this.VsumSaldopM5 = sumSaldopM5;
        this.VsumSaldosisaM5 = sumSaldosisaM5;

        this.VsumSaldoL5 = sumSaldoL5;
        this.VsumSaldopL5 = sumSaldopL5;
        this.VsumSaldosisaL5 = sumSaldosisaL5;

        this.VsumSaldoXL5 = sumSaldoXL5;
        this.VsumSaldopXL5 = sumSaldopXL5;
        this.VsumSaldosisaXL5 = sumSaldosisaXL5;

        this.VsumSaldoXXL5 = sumSaldoXXL5;
        this.VsumSaldopXXL5 = sumSaldopXXL5;
        this.VsumSaldosisaXXL5 = sumSaldosisaXXL5;

        this.VsumSaldoS6 = sumSaldoS6;
        this.VsumSaldopS6 = sumSaldopS6;
        this.VsumSaldosisaS6 = sumSaldosisaS6;

        this.VsumSaldoM6 = sumSaldoM6;
        this.VsumSaldopM6 = sumSaldopM6;
        this.VsumSaldosisaM6 = sumSaldosisaM6;

        this.VsumSaldoL6 = sumSaldoL6;
        this.VsumSaldopL6 = sumSaldopL6;
        this.VsumSaldosisaL6 = sumSaldosisaL6;

        this.VsumSaldoXL6 = sumSaldoXL6;
        this.VsumSaldopXL6 = sumSaldopXL6;
        this.VsumSaldosisaXL6 = sumSaldosisaXL6;

        this.VsumSaldoXXL6 = sumSaldoXXL6;
        this.VsumSaldopXXL6 = sumSaldopXXL6;
        this.VsumSaldosisaXXL6 = sumSaldosisaXXL6;

      },
      error => {
        // console.log(error);
      });
  };

  retrieveTotalKategori(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/totalKategori', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response,'s')
        let rdata = response.rows;

        let sumSaldoS3K = 0;
        let sumSaldosisaS3K = 0;
        let sumSaldopS3K = 0;

        let sumSaldoS5K = 0;
        let sumSaldosisaS5K = 0;
        let sumSaldopS5K = 0;

        let sumSaldoS10K = 0;
        let sumSaldosisaS10K = 0;
        let sumSaldopS10K = 0;

        let sumSaldoS21K = 0;
        let sumSaldosisaS21K = 0;
        let sumSaldopS21K = 0;

        let sumSaldoS21KM = 0;
        let sumSaldosisaS21KM = 0;
        let sumSaldopS21KM = 0;

        let sumSaldoS21KM1 = 0;
        let sumSaldosisaS21KM1 = 0;
        let sumSaldopS21KM1 = 0;

        let sumSaldoS5KR = 0;
        let sumSaldosisaS5KR = 0;
        let sumSaldopS5KR = 0;

        let sumSaldoM10KR = 0;
        let sumSaldopM10KR = 0;
        let sumSaldosisaM10KR = 0;

        let sumSaldoS5KRI = 0;
        let sumSaldosisaS5KRI = 0;
        let sumSaldopS5KRI = 0;

        let sumSaldoS5KRIJ = 0;
        let sumSaldosisaS5KRIJ = 0;
        let sumSaldopS5KRIJ = 0;

        // BRM
        let sumSaldoS5KBRM = 0;
        let sumSaldosisaS5KBRM = 0;
        let sumSaldopS5KBRM = 0;

        let sumSaldoM10KBRM = 0;
        let sumSaldopM10KBRM = 0;
        let sumSaldosisaM10KBRM = 0;
      
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].run_category == '3K'){
            sumSaldoS3K += rdata[i].saldo;
            sumSaldosisaS3K += rdata[i].sisa;
            sumSaldopS3K += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '5K'){
            sumSaldoS5K += rdata[i].saldo;
            sumSaldosisaS5K += rdata[i].sisa;
            sumSaldopS5K += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '10K'){
            sumSaldoS10K += rdata[i].saldo;
            sumSaldosisaS10K += rdata[i].sisa;
            sumSaldopS10K += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '21K'){
            sumSaldoS21K += rdata[i].saldo;
            sumSaldosisaS21K += rdata[i].sisa;
            sumSaldopS21K += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '21KM'){
            sumSaldoS21KM += rdata[i].saldo;
            sumSaldosisaS21KM += rdata[i].sisa;
            sumSaldopS21KM += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '21KM1'){
            sumSaldoS21KM1 += rdata[i].saldo;
            sumSaldosisaS21KM1 += rdata[i].sisa;
            sumSaldopS21KM1 += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '5KR'){
            sumSaldoS5KR += rdata[i].saldo;
            sumSaldosisaS5KR += rdata[i].sisa;
            sumSaldopS5KR += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '10KR'){
            sumSaldoM10KR += rdata[i].saldo;
            sumSaldopM10KR += rdata[i].totalpeserta;
            sumSaldosisaM10KR += rdata[i].sisa;
          }
          if(rdata[i].run_category == '5KRI'){
            sumSaldoS5KRI += rdata[i].saldo;
            sumSaldosisaS5KRI += rdata[i].sisa;
            sumSaldopS5KRI += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '5KRIJ'){
            sumSaldoS5KRIJ += rdata[i].saldo;
            sumSaldosisaS5KRIJ += rdata[i].sisa;
            sumSaldopS5KRIJ += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '5KBRM'){
            sumSaldoS5KBRM += rdata[i].saldo;
            sumSaldosisaS5KBRM += rdata[i].sisa;
            sumSaldopS5KBRM += rdata[i].totalpeserta;
          }
          if(rdata[i].run_category == '10KBRM'){
            sumSaldoM10KBRM += rdata[i].saldo;
            sumSaldopM10KBRM += rdata[i].totalpeserta;
            sumSaldosisaM10KBRM += rdata[i].sisa;
          }
        } 
        
        this.VsumSaldoS3K = sumSaldoS3K;
        this.VsumSaldopS3K = sumSaldopS3K;
        this.VsumSaldosisaS3K = sumSaldosisaS3K;

        this.VsumSaldoS5K = sumSaldoS5K;
        this.VsumSaldopS5K = sumSaldopS5K;
        this.VsumSaldosisaS5K = sumSaldosisaS5K;

        this.VsumSaldoS10K = sumSaldoS10K;
        this.VsumSaldopS10K = sumSaldopS10K;
        this.VsumSaldosisaS10K = sumSaldosisaS10K;

        this.VsumSaldoS21K = sumSaldoS21K;
        this.VsumSaldopS21K = sumSaldopS21K;
        this.VsumSaldosisaS21K = sumSaldosisaS21K;

        this.VsumSaldoS21KM = sumSaldoS21KM;
        this.VsumSaldopS21KM = sumSaldopS21KM;
        this.VsumSaldosisaS21KM = sumSaldosisaS21KM;

        this.VsumSaldoS21KM1 = sumSaldoS21KM1;
        this.VsumSaldopS21KM1 = sumSaldopS21KM1;
        this.VsumSaldosisaS21KM1 = sumSaldosisaS21KM1;

        this.VsumSaldoS5KR = sumSaldoS5KR;
        this.VsumSaldopS5KR = sumSaldopS5KR;
        this.VsumSaldosisaS5KR = sumSaldosisaS5KR;

        this.VsumSaldoM10KR = sumSaldoM10KR;
        this.VsumSaldopM10KR = sumSaldopM10KR;
        this.VsumSaldosisaM10KR = sumSaldosisaM10KR;

        this.VsumSaldoS5KRI = sumSaldoS5KRI;
        this.VsumSaldopS5KRI = sumSaldopS5KRI;
        this.VsumSaldosisaS5KRI = sumSaldosisaS5KRI;

        this.VsumSaldoS5KRIJ = sumSaldoS5KRIJ;
        this.VsumSaldopS5KRIJ = sumSaldopS5KRIJ;
        this.VsumSaldosisaS5KRIJ = sumSaldosisaS5KRIJ;

        // BRIM
        this.VsumSaldoS5KBRM = sumSaldoS5KBRM;
        this.VsumSaldopS5KBRM = sumSaldopS5KBRM;
        this.VsumSaldosisaS5KBRM = sumSaldosisaS5KBRM;

        this.VsumSaldoM10KBRM = sumSaldoM10KBRM;
        this.VsumSaldopM10KBRM = sumSaldopM10KBRM;
        this.VsumSaldosisaM10KBRM = sumSaldosisaM10KBRM;

      },
      error => {
        // console.log(error);
      });
  };

  initializeItems(): void {
    this.tutorials = this.tutorialsTemp;
  }

  retrievePendaftarans(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.type = 'dash';

    this.tutorialService.getAlls('pendaftaran/viewpendaftaran', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.tutorialsTemp = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        // this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveTagihans(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/tagihans', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        let rdata = response.rows
        let sum3KMale = 0;
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].run_category == '3K' && rdata[i].run_gender == 'Male'){ // Laki
            sum3KMale += rdata[i].total;
            // console.log(sum3KMale,'sum3KMale')
          }
        }
      
        this.sum3KMales = sum3KMale
      },
      error => {
        // console.log(error);
      });
  };

  retrieveInbounds(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getInboundshari', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        for (let i = 0; i < rdata.length; i++) {
          sumtagihan += rdata[i].totalinbound;
        }
        this.totalinbh = sumtagihan
      },
      error => {
        // console.log(error);
      });
  };

  retrieveInboundbulan(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getInboundsbulan', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        for (let i = 0; i < rdata.length; i++) {
          sumtagihan += rdata[i].totalinbound;
        }
        this.totalinbb = sumtagihan
      },
      error => {
        // console.log(error);
      });
  };

  retrieveInboundtahun(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getInboundstahun', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        for (let i = 0; i < rdata.length; i++) {
          sumtagihan += rdata[i].totalinbound;
        }
        this.totalinbt = sumtagihan
      },
      error => {
        // console.log(error);
      });
  };
  retrieveShipmenthari(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getShipmentshari', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        let sumtagihan1 = 0;
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].consignee_id == null){
            sumtagihan += rdata[i].totalshipment;
          }else{
            sumtagihan1 += rdata[i].totalshipment;
          }
        }
        this.totalshpph = sumtagihan
        this.totalshpph1 = sumtagihan1
      },
      error => {
        // console.log(error);
      });
  };

  retrieveShipmentbulan(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getShipmentsbulan', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        let sumtagihan1 = 0;
        for (let i = 0; i < rdata.length; i++) {
          // console.log(rdata[i].consignee_id == null,'rdata[i]rdata[i]')
          if(rdata[i].consignee_id == null){
            sumtagihan += rdata[i].totalshipment;
          }else{
            sumtagihan1 += rdata[i].totalshipment;
          }
        }
        this.totalshppb = sumtagihan
        this.totalshppb1 = sumtagihan1
      },
      error => {
        // console.log(error);
      });
  };

  retrieveShipmenttahun(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getShipmentstahun', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        let sumtagihan1 = 0;
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].consignee_id == null){
            sumtagihan += rdata[i].totalshipment;
            // console.log(sumtagihan,'sumtagihan')
          }else{
            sumtagihan1 += rdata[i].totalshipment;
            // console.log(sumtagihan1,'sumtagihan1')
          }
        }
        this.totalshppt = sumtagihan
        this.totalshppt1 = sumtagihan1
      },
      error => {
        // console.log(error);
      });
  };

  fadd_role: any = {
    id: '',
    run_idnumber: '',
    run_category: '',
    run_email: '',
    run_phone:'',

  };

  showsPesertas(sendMessageModal:any, value: any, action:any) {
    // this.isCreated = false;
    this.modalService.open(sendMessageModal, { size: 'lg' });
    // console.log(value,'valuedit')
    // this.fadd_role.id = tutorial.id;
    this.fadd_role.run_lastname = value.run_lastname;
    // this.fadd_role.id"= "1f2e3c43-318a-4e81-b550-77572f89b76a",
    this.fadd_role.run_order_id = value.run_order_id;
    this.fadd_role.run_category = value.run_category;
    this.fadd_role.run_email = value.run_email;
    this.fadd_role.run_phone = value.run_phone;
    // this.fadd_role.run_password = value.run_password
    this.fadd_role.run_firstname = value.run_firstname
    this.fadd_role.run_lastname = value.run_lastname
    this.fadd_role.run_gender = value.run_gender
    this.fadd_role.run_birth = value.run_birth
    this.fadd_role.run_idnumber = value.run_idnumber
    this.fadd_role.run_nationality = value.run_nationality
    this.fadd_role.run_city = value.run_city
    this.fadd_role.run_occupation = value.run_occupation
    this.fadd_role.run_organization = value.run_organization
    this.fadd_role.run_emergencyname = value.run_emergencyname
    this.fadd_role.run_emergencyphone = value.run_emergencyphone
    this.fadd_role.run_emergencyrel = value.run_emergencyrel
    this.fadd_role.run_blood = value.run_blood
    this.fadd_role.run_medicalcondition = value.run_medicalcondition
    this.fadd_role.run_runninggroup = value.run_runninggroup
    this.fadd_role.agrees = value.run_runninggroup
    this.fadd_role.agree = value.agree
    this.fadd_role.id_events = value.id_events
    this.fadd_role.harga1 = value.harga1
    this.fadd_role.harga2 = value.harga2
    this.fadd_role.harga2_percent = value.harga2_percent
    this.fadd_role.harga3 = value.harga3
    this.fadd_role.harga3_percent = value.harga3
    this.fadd_role.total = value.total
    this.fadd_role.run_sizekaos = value.run_sizekaos
    this.fadd_role.run_namabip = value.run_namabip
    this.fadd_role.run_nobip = value.run_nobip
   
    this.fadd_role.status_bayar = value.status_bayar
    this.fadd_role.reference = value.reference
    this.fadd_role.publisherOrderId = value.publisherOrderId
    this.fadd_role.paymentUrl = value.paymentUrl
    this.fadd_role.statusCode = value.statusCode
    this.fadd_role.statusMessage = value.statusMessage
    this.fadd_role.merchantCode = value.merchantCode
    this.fadd_role.fullname = value.fullname
    this.fadd_role.paymentText = value.paymentText
    this.fadd_role.createdAt = value.createdAt
    this.fadd_role.run_categoryText = value.run_categoryText
    this.fadd_role.action = action;
  }

  currencyStr(value: any) {
      var formatter = new Intl.NumberFormat('en-US');
      return formatter.format(value);
  };

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  setKehadiran(data:any) {
    var conf = confirm('Anda yakin Hadir ?');
    if (conf) {
      this.tutorialService.delete(`pendaftaran/sethadirs/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrievePendaftarans();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  showsPembayarans(sendMessagebayarModal:any, value: any, action:any) {
    // this.isCreated = true;
    this.modalService.open(sendMessagebayarModal, { size: 'lg' });
    // console.log(value,'valuedit')
    // this.fadd_role.id = tutorial.id;
    this.fadd_role.id = value.id;
    this.fadd_role.run_lastname = value.run_lastname;
    // this.fadd_role.id"= "1f2e3c43-318a-4e81-b550-77572f89b76a",
    this.fadd_role.run_order_id = value.run_order_id;
    this.fadd_role.run_category = value.run_category;
    this.fadd_role.run_email = value.run_email;
    this.fadd_role.run_phone = value.run_phone;
    // this.fadd_role.run_password = value.run_password
    this.fadd_role.run_firstname = value.run_firstname
    this.fadd_role.run_lastname = value.run_lastname
    this.fadd_role.run_gender = value.run_gender
    this.fadd_role.run_birth = value.run_birth
    this.fadd_role.run_idnumber = value.run_idnumber
    this.fadd_role.run_nationality = value.run_nationality
    this.fadd_role.run_city = value.run_city
    this.fadd_role.run_occupation = value.run_occupation
    this.fadd_role.run_organization = value.run_organization
    this.fadd_role.run_emergencyname = value.run_emergencyname
    this.fadd_role.run_emergencyphone = value.run_emergencyphone
    this.fadd_role.run_emergencyrel = value.run_emergencyrel
    this.fadd_role.run_blood = value.run_blood
    this.fadd_role.run_medicalcondition = value.run_medicalcondition
    this.fadd_role.run_runninggroup = value.run_runninggroup
    this.fadd_role.agrees = value.run_runninggroup
    this.fadd_role.agree = value.agree
    this.fadd_role.id_events = value.id_events
    this.fadd_role.harga1 = value.harga1
    this.fadd_role.harga2 = value.harga2
    this.fadd_role.harga2_percent = value.harga2_percent
    this.fadd_role.harga3 = value.harga3
    this.fadd_role.harga3_percent = value.harga3
    this.fadd_role.total = value.total
    this.fadd_role.run_sizekaos = value.run_sizekaos
    this.fadd_role.run_namabip = value.run_namabip
    this.fadd_role.run_nobip = value.run_nobip
   
    this.fadd_role.status_bayar = value.status_bayar
    this.fadd_role.reference = value.reference
    this.fadd_role.publisherOrderId = value.publisherOrderId
    this.fadd_role.paymentUrl = value.paymentUrl
    this.fadd_role.statusCode = value.statusCode
    this.fadd_role.statusMessage = value.statusMessage
    this.fadd_role.merchantCode = value.merchantCode
    this.fadd_role.fullname = value.fullname
    this.fadd_role.paymentText = value.paymentText
    this.fadd_role.createdAt = value.createdAt
    this.fadd_role.run_categoryText = value.run_categoryText
    this.fadd_role.ambilkaos = value.ambilkaos
    this.fadd_role.run_kehadiranText = value.run_kehadiranText
    this.fadd_role.action = action;
  }

  saveBayar(): void {
    const data = {
      run_order_id: this.fadd_role.run_order_id,
      run_category: this.fadd_role.run_category,
      total: this.fadd_role.total,
      run_firstname: this.fadd_role.run_firstname,
      run_lastname: this.fadd_role.run_lastname,
      run_email: this.fadd_role.run_email,
      run_phone: this.fadd_role.run_phone,
      harga1: this.fadd_role.harga1,
      harga2: this.fadd_role.harga2,
      harga3: this.fadd_role.harga3,
      run_idnumber: this.fadd_role.run_idnumber,
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('pendaftaran/generatebayar/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response,'s');

            // window.location.href = response.url;

            var anchor = document.createElement('a');
            // anchor.href = 'http://localhost/report/welcome/laporan/'+tutorial.id;
            anchor.href = response.url;
            anchor.target="_blank";
            anchor.click();
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            // this.submitted = true;
            this.modalService.dismissAll();
            this.retrievePendaftarans();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }


}
