import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';


import { MasterService } from '../../_services/master.service';

// export interface Tutorial {
//   id?: any;
//   short_name?: string,
//   full_name?: string,
//   hp?: string,
//   email?: string,
//   password?: string,
//   image?: string,
//   action?: string
// }

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  // cleansingImg = 'http://103.214.112.148:7777/uploads/images/cleansings/';
  profilImg = 'https://api.siger.co.id/uploads/images/profil/'; // https://api.siger.co.id/
  // profilImg = 'http://localhost:39998/uploads/images/profil/';

  constructor(private modalService: NgbModal,
    private router: Router,
		private tokenStorage: TokenStorageService,
    private tutorialService: MasterService,
    public toastService: ToastService
    ) {}

    users:any = {};
    rolesString:any = '';

    id: any;
    short_name:any;
    full_name:any;
    email:any;
    hp:any;
    images:any;
    password:any;

    isCreated:boolean = true;

    selectedFile : any;

  ngOnInit(): void {
    this.tokenStorage.getUsers().then(respon => {
      this.users = respon;
      this.short_name = this.users.name;
      this.full_name = this.users.full_name;
      this.email = this.users.email;
      this.hp = this.users.hp;
      this.images = this.users.images;
        this.users.role.forEach((e:any) => {
        this.rolesString = this.rolesString+e.name+', ';
      // console.log(this.rolesString)
      });
    })
  }

   // START
   onFileSelected(event:any) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

  submitted!:boolean;
  saveTutorial(): void {
      const formData: FormData = new FormData();
      formData.append('short_name', this.short_name);
      formData.append('full_name', this.full_name);
      formData.append('hp', this.hp);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('fileimage', this.selectedFile);

      this.tutorialService.postData('users/updateprofil', formData)
        .subscribe(
          async response => {
            // console.log(response);
            // this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            // this.modalService.dismissAll();
            // this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

}
