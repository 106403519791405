<div class="deznav">
  <div class="deznav-scroll" [perfectScrollbar]>
    <ul class="metismenu" id="menu" metis-menu>
      <li *ngIf="isAdmin || isSuperadmin || isPeserta"
        class="{{(currentHref == '/admin/index') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/index']" class="ai-icon {{(currentHref == '/admin/index') ? 'mm-active' : ''}}"
          aria-expanded="false">
          <i class="flaticon-381-home"></i>
          <span class="nav-text">Dashboard</span>
        </a>
      </li>

      <li *ngIf="isAdmin || isSuperadmin || isPeserta"
        class="{{(currentHref == '/admin/app-tr-peserta') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-tr-peserta']"
          class="ai-icon {{(currentHref == '/admin/app-tr-peserta') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-notepad"></i>
          <span class="nav-text">Pendaftaran</span>
        </a>
      </li>

      <!-- <li *ngIf="isAdmin || isSuperadmin"
      class="{{(currentHref == '/admin/app-tr-tagihans') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-tr-tagihans']"
          class="ai-icon {{(currentHref == '/admin/app-tr-tagihans') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-id-card"></i>
          <span class="nav-text">Tagihan</span>
        </a>
      </li>

      <li *ngIf="isAdmin || isSuperadmin"
      class="{{(currentHref == '/admin/app-tr-pembayarans') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-tr-pembayarans']"
          class="ai-icon {{(currentHref == '/admin/app-tr-pembayarans') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-price-tag"></i>
          <span class="nav-text">Pembayaran</span>
        </a>
      </li> -->

      <!-- <li *ngIf="isSuperadmin"
      class="{{(currentHref == '/admin/app-mst-partners') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-mst-partners']"
          class="ai-icon {{(currentHref == '/admin/app-mst-partners') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-share"></i>
          <span class="nav-text">Partner</span>
        </a>
      </li> -->

      <!-- <li *ngIf="isSuperadmin"
      class="{{(currentHref == '/admin/app-mst-user') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-mst-user']"
          class="ai-icon {{(currentHref == '/admin/app-mst-user') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-user"></i>
          <span class="nav-text">User</span>
        </a>
      </li> -->

      <!-- <li *ngIf="isAdmin || isSuperadmin"
      class="{{(currentHref == '/admin/app-tr-lappeserta') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-tr-lappeserta']"
          class="ai-icon {{(currentHref == '/admin/app-tr-lappeserta') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-pad"></i>
          <span class="nav-text">laporan Pendaftaran</span>
        </a>
      </li> -->

      <!-- <li *ngIf="isAdmin || isSuperadmin || isAgen"
        class="{{(currentHref == '/admin/app-mst-country') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-mst-country']"
          class="ai-icon {{(currentHref == '/admin/app-mst-country') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-pad"></i>
          <span class="nav-text">Country</span>
        </a>
      </li> -->

      <!-- <li *ngIf="isAdmin || isSuperadmin || isAgen"
        class="{{(currentHref == '/admin/app-mst-city') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-mst-city']"
          class="ai-icon {{(currentHref == '/admin/app-mst-city') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-photo"></i>
          <span class="nav-text">City</span>
        </a>
      </li> -->

      <!-- <li [ngClass]="{'mm-active': mstArray.indexOf(currentHref) > -1}">
        <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
          <i class="flaticon-381-networking"></i>
          <span class="nav-text">Master</span>
        </a>
        <ul aria-expanded="false" [ngClass]="{'mm-show': mstArray.indexOf(currentHref) > -1}">

          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-mst-role']"
              class="{{(currentHref == '/admin/app-mst-role') ? 'mm-active' : ''}}">Role</a>
          </li>
          <li *ngIf="isSuperadmin || isAdmin || isAgen">
            <a [routerLink]="['/admin/app-mst-user']"
              class="{{(currentHref == '/admin/app-mst-user') ? 'mm-active' : ''}}">User</a>
            </li>
          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-mst-typeitems']"
              class="{{(currentHref == '/admin/app-mst-typeitems') ? 'mm-active' : ''}}">Type Items</a>
            </li>
          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-mst-subtypeitem']"
              class="{{(currentHref == '/admin/app-mst-subtypeitem') ? 'mm-active' : ''}}">Sub Type Items</a>
            </li>
          <li *ngIf="isAdmin || isSuperadmin">
            <a [routerLink]="['/admin/app-mst-shippers']"
              class="{{(currentHref == '/admin/app-mst-shippers') ? 'mm-active' : ''}}">Shippers</a>
            </li>
          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-mst-vendors']"
              class="{{(currentHref == '/admin/app-mst-vendors') ? 'mm-active' : ''}}">Vendors</a>
            </li>
          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-mst-vendorsresi']"
              class="{{(currentHref == '/admin/app-mst-vendorsresi') ? 'mm-active' : ''}}">Vendors Resi</a>
            </li>
          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-mst-contact-us']"
              class="{{(currentHref == '/admin/app-mst-contact-us') ? 'mm-active' : ''}}">Contact Us</a>
            </li>
          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-mst-bank']"
              class="{{(currentHref == '/admin/app-mst-bank') ? 'mm-active' : ''}}">Bank</a>
            </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance">
            <a [routerLink]="['/admin/app-mst-rekening-bank']"
              class="{{(currentHref == '/admin/app-mst-rekening-bank') ? 'mm-active' : ''}}">Rekening Bank</a>
            </li>
            <li *ngIf="isAdmin || isSuperadmin">
              <a [routerLink]="['/admin/app-mst-kurir']"
                class="{{(currentHref == '/admin/app-mst-kurir') ? 'mm-active' : ''}}">Kurir</a>
            </li>
            <li *ngIf="isAdmin">
              <a [routerLink]="['/admin/app-mst-sliders']"
                class="{{(currentHref == '/admin/app-mst-sliders') ? 'mm-active' : ''}}">Slider</a>
            </li>
            <li *ngIf="isAdmin">
              <a [routerLink]="['/admin/app-mst-kategoris']"
                class="{{(currentHref == '/admin/app-mst-kategoris') ? 'mm-active' : ''}}">Kategori Produk</a>
            </li>
            <li *ngIf="isAdmin">
              <a [routerLink]="['/admin/app-mst-products']"
                class="{{(currentHref == '/admin/app-mst-products') ? 'mm-active' : ''}}">Produk</a>
            </li>
        </ul>
      </li>

      <li *ngIf="isAdmin || isAgen"
        class="{{(currentHref == '/admin/app-mst-consignee') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-mst-consignee']"
          class="ai-icon {{(currentHref == '/admin/app-mst-consignee') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-user-8"></i>
          <span class="nav-text">Consignee</span>
        </a>
      </li>
      

      <li *ngIf="isSuperadmin" class="{{(currentHref == '/admin/app-mst-tracking') ? 'mm-active' : ''}}">
        <a [routerLink]="['/admin/app-mst-tracking']"
          class="ai-icon {{(currentHref == '/admin/app-mst-tracking') ? 'mm-active' : ''}}" aria-expanded="false">
          <i class="flaticon-381-map-2"></i>
          <span class="nav-text">Tracking</span>
        </a>
      </li>
     

      <li [ngClass]="{'mm-active': trsArray.indexOf(currentHref) > 0}">
        <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
          <i class="flaticon-381-album"></i>
          <span class="nav-text">Transaction</span>
        </a>
        <ul aria-expanded="false" [ngClass]="{'mm-show': trsArray.indexOf(currentHref) > -1}">
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isInbound">
            <a [routerLink]="['/admin/app-tr-inbound']"
              class="{{(currentHref == '/admin/app-tr-inbound') ? 'mm-active' : ''}}">Inbound</a>
            </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance || isCleansings">
            <a [routerLink]="['/admin/app-tr-cleansings']"
              class="{{(currentHref == '/admin/app-tr-cleansings') ? 'mm-active' : ''}}">Cleansing Process</a>
          </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance || isCleansings">
            <a [routerLink]="['/admin/app-tr-cleansingscomplete']"
              class="{{(currentHref == '/admin/app-tr-cleansingscomplete') ? 'mm-active' : ''}}">Cleansing Complete</a>
          </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance">
            <a [routerLink]="['/admin/app-tr-tagihan']"
              class="{{(currentHref == '/admin/app-tr-tagihan') ? 'mm-active' : ''}}">Bill Unpaid</a>
          </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance">
            <a [routerLink]="['/admin/app-tr-tagihanpaid']"
              class="{{(currentHref == '/admin/app-tr-tagihanpaid') ? 'mm-active' : ''}}">Bill Paid</a>
          </li>
          <li *ngIf="isAdmin || isSuperadmin">
            <a [routerLink]="['/admin/app-tr-manifest']"
              class="{{(currentHref == '/admin/app-tr-manifest') ? 'mm-active' : ''}}">Outbound</a>
            </li>
        </ul>
      </li>

      <li [ngClass]="{'mm-active': rpsArray.indexOf(currentHref) > -1}">
        <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
          <i class="flaticon-381-book"></i>
          <span class="nav-text">Reports</span>
        </a>
        <ul aria-expanded="false" [ngClass]="{'mm-show': rpsArray.indexOf(currentHref) > -1}">
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isInbound">
            <a [routerLink]="['/admin/app-rp-inbound']"
              class="{{(currentHref == '/admin/app-rp-inbound') ? 'mm-active' : ''}}">Inbound</a>
            </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance || isCleansings">
            <a [routerLink]="['/admin/app-rp-cleansings']"
              class="{{(currentHref == '/admin/app-rp-cleansings') ? 'mm-active' : ''}}">Cleansing</a>
            </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen">
            <a [routerLink]="['/admin/app-rp-manifest']"
              class="{{(currentHref == '/admin/app-rp-manifest') ? 'mm-active' : ''}}">Manifest</a>
          </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance">
            <a [routerLink]="['/admin/app-rp-tagihan']"
              class="{{(currentHref == '/admin/app-rp-tagihan') ? 'mm-active' : ''}}">Bill</a>
          </li>
          <li *ngIf="isSuperadmin">
            <a [routerLink]="['/admin/app-rp-tagihanshipper']"
              class="{{(currentHref == '/admin/app-rp-tagihanshipper') ? 'mm-active' : ''}}">Bill Shipper</a>
          </li>
          <li *ngIf="isAdmin || isSuperadmin || isAgen || isFinance">
            <a [routerLink]="['/admin/app-rp-pembayaran']"
              class="{{(currentHref == '/admin/app-rp-pembayaran') ? 'mm-active' : ''}}">Payment</a>
            </li>
        </ul>
      </li> -->


    </ul>

  </div>
</div>
