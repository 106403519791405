<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <!-- <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Transaksi</a></li>
                  <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Pendaftaran</a></li>
              </ol>
          </div>
        </div>
    </div> -->

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input
          type="text"
          class="form-control"
          placeholder="Search by nik or phone number"
          [(ngModel)]="title"
          />

        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <!-- <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a *ngIf="isSuperadmin" href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a> -->

    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Data Tagihan</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">NIK/PASSPORT</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Nama Bip</th>
                    <th scope="col">Email</th>
                    <th scope="col">No Hp/Telp</th>
                    <th scope="col" *ngIf="isSuperadmin">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x + 1 }}
                    </th>
                    <td>
                      {{ tutorial.run_category }}
                    </td>
                    <td>
                      {{ tutorial.run_idnumber }}
                    </td>
                    <td>
                      {{ tutorial.run_firstname }} {{ tutorial.run_lastname }}
                    </td>
                    <td>
                      {{ tutorial.run_namabip }}
                    </td>
                    <td>
                      {{ tutorial.run_email }}
                    </td>
                    <td>
                      {{ tutorial.run_phone }}
                    </td>
                    <td *ngIf="isSuperadmin">
                      <!-- <button type="button" class="btn btn-rounded btn-primary"
                      (click)="actionPembayaran(sendMessageModal, tutorial, 'update')"><span
                        class="btn-icon-left text-primary"><i class="fa fa-pencil color-primary"></i>
                      </span>Edit</button>&nbsp;

                      <button (click)="deleteUser(tutorial)" type="button" class="btn btn-rounded btn-danger">
                        <span class="btn-icon-left text-danger"
                          ><i class="fa fa-trash-o color-danger"></i> </span
                        >Remove
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination class="pagination-responsive"
                [collectionSize]="count"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              >
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select
                  class="mr-sm-2 custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="handlePageSizeChange($event)"
                >
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Country</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="comment-form">
      <!-- <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >Shortname <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="shortname"
              required
              [(ngModel)]="fadd_role.shortname"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="shortname"
            />
            </div>
        </div>
         <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >Name <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="name"
              required
              [(ngModel)]="fadd_role.name"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="name"
            />
            </div>
        </div>
         <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >Phonecode <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="phonecode"
              required
              [(ngModel)]="fadd_role.phonecode"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="phonecode"
            />
            </div>
        </div>
        <div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
          <div class="form-group mb-0">
            <button
              (click)="saveTutorial()"
              [disabled]="name.invalid"
              class="submit btn btn-primary"
            >
              {{ isCreated ? "Submit" : "Update" }}
            </button>
            </div>
        </div>
      </div> -->
    </form>
  </div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
