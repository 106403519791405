<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Transaction</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/admin/app-tr-manifest']">Outbound</a></li>
                  <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Outbound Bag</a></li>
              </ol>
            </div>
          </div>

      </div>

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <!-- <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create Bag Manual</a> -->
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="opens(contents)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>

    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Outbound Bag</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Bag Number</th>
                      <th scope="col">Label</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <!-- <td>{{ tutorial.manifest_id }}</td> -->
                        <td>{{ tutorial.bag_no }}</td>
                        <td>{{ tutorial.label }}</td>
                        <!-- <td>{{ tutorial.flight_number }}</td> -->
                        <!-- <td>{{ tutorial.vendor_id }}</td> -->
                        <!-- <td>{{ tutorial.note }}</td> -->
                        <!-- <td>{{ tutorial.status }}</td> -->
                        <td>
                          <!-- <a [routerLink]="['/admin/app-tr-manifest']" class="btn btn-rounded btn-outline-primary mt-sm-4 mt-2"><i class="fa fa-box scale5 mr-3"></i></a> -->
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag']" class="btn btn-primary btn-xxs mr-2 mb-2" placement="left" ngbTooltip="Manifest Bag Detail">
                            <i class="fa fa-archive color-info"></i></a> -->
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag']" class="btn btn-outline btn-xs btn-dark"><i class="fa fa-archive color-info"></i></a>&nbsp; -->
                          <!-- <a [routerLink]="['items', customer.id, 'items', item.id]">{{item.name}}</a> -->
                          <a href="#" [routerLink]="['/admin/app-tr-manifest-bag-detail/', tutorial.id, 'manifest-bag', idmanifest ]" class="btn btn-primary btn-xxs mr-2 mb-2" placement="left" ngbTooltip="Manifest Bag Detail">
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag-detail/', idbag+'/', tutorial.id ]" class="btn btn-primary btn-xxs mr-2 mb-2" placement="left" ngbTooltip="Manifest Bag Detail"> -->
                            <i class="fa fa-archive color-info"></i></a>
                          <button type="button" class="btn btn-rounded btn-info btn-xxs mr-2 mb-2" (click)="editUser(sendMessageModal, tutorial, 'update')" placement="left" ngbTooltip="Edit">
                            <i class="fa fa-pencil color-info"></i>
                            </button>
                            <button type="button" class="btn btn-danger btn-xxs mr-2 mb-2" (click)="deleteUser(tutorial)" placement="left" ngbTooltip="Delete">
                              <i class="fa fa-trash color-info"></i>
                              </button>
                          <!-- <button type="button" class="btn btn-rounded btn-danger"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button> -->
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

              <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize" (pageChange)="handlePageChange($event)">
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize" (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>

              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- MODAL CREATE -->
<ng-template #contents let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Add Bag</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-alert [dismissible]="false" type="danger">
      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="mr-2"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
      Available Label : <strong>0 labels</strong>
    </ngb-alert>
    <!-- <p>Hello, World!</p> -->
    <input
              type="hidden"
              class="form-control"
              id="manifest_id"
              required
              [(ngModel)]="fadd_role.manifest_id"
              #name="ngModel"
              name="manifest_id"
            />
    <div class="col-lg-12">
      <div class="form-group">
        <label class="text-black font-w600">Add Number of Bags <span class="required">*</span></label>
        <input
          type="number"
          class="form-control"
          id="bag_no"
          required
          [(ngModel)]="fadd_role.bag_no"
          #name="ngModel"
          name="bag_no"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveBag()">Save</button>
  </div>
</ng-template>
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Menu</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<!-- <div class="col-lg-12"> -->
					<!-- <div class="form-group"> -->
						<!-- <label class="text-black font-w600">Manifest ID <span class="required">*</span></label> -->
            <input
              type="hidden"
              class="form-control"
              id="manifest_id"
              required
              [(ngModel)]="fadd_role.manifest_id"
              #name="ngModel"
              name="manifest_id"
            />
          <!-- </div> -->
				<!-- </div> -->
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Bag Number <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="bag_no"
              required
              [(ngModel)]="fadd_role.bag_no"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="bag_no"
            />
          </div>
				</div>
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Label <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="label"
              required
              [(ngModel)]="fadd_role.label"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="mawb"
            />
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
