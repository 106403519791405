import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { SharedService } from 'src/app/shared.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	toggleChat: boolean = true;
	toggleSingle: boolean = true;

  profilImg = 'https://api.siger.co.id/uploads/images/profil/';
  // profilImg = 'http://localhost:39998/uploads/images/profil/';

	// constructor() { }

	// ngOnInit(): void {
	// }

  constructor(
		public sharedService: SharedService,
		public toastService: ToastService,
		private router: Router,
		private tokenStorage: TokenStorageService,
	  ) {

	  }

  users:any = {};
	rolesString:any = '';

  async ngOnInit(): Promise<void> {
	  // this.getToken();
	  this.tokenStorage.getUsers().then(respon => {
		this.users = respon;
		// console.log(this.users)
      if(this.users.auth === undefined) {
        this.toastService.show('U must login first!', { classname: 'bg-danger text-white', delay: 3000 });
        setTimeout(async () => {
        this.router.navigate(['/page-login']);
        }, 1000)
      } else {
        this.toastService.show('Helo '+this.users.name+'!', { classname: 'bg-success text-white', delay: 3000 });
        this.users.role.forEach((e:any) => {
        this.rolesString = this.rolesString+e.name+', ';
        // console.log(this.rolesString)
        });
      }
	  })
  }



	togglechatbar() {
		this.toggleChat = !this.toggleChat;
	}
	singleChatWindow() {
		this.toggleSingle = !this.toggleSingle;
	}

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

}
