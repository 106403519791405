import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

// import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

@Component({
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Profile deletion</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="OnSubmit('no')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="OnSubmit('no')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="OnSubmit('Ok')">Ok</button>
  </div>
  `
})
export class NgbdModalConfirm {
  @Input() public data: any;
  constructor(public modal: NgbActiveModal) {
    // console.log(this.data)
  }

  OnSubmit(status:any) {
    this.modal.close(status);
  }
}

export interface Tutorial {
  id?: any;
  // shortname?: string,
  // name?: string,
  // phonecode?: string,
  run_idnumber?: string,
  run_category?: string,
  run_email?: string,
  run_firstname?:string,
  run_lastname?:string,
  run_namabip?:string,
  run_phone?: string,
  action?: string
}

const MODALS: {[name: string]: Type<any>} = {
  focusFirst: NgbdModalConfirm
};

@Component({
  selector: 'app-tr-tagihans',
  templateUrl: './tr-tagihans.component.html',
  styleUrls: ['./tr-tagihans.component.css']
})
export class TrTagihansComponent implements OnInit {

  tutorials: Tutorial[] = [];
  tutorialsTemp: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;

  id: any;
  shortname: any;
  name: any;
  phonecode: any;
  // angForm: FormGroup | any;
  // value!: any;
  // vcombo: any = [];

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    // private fb: FormBuilder
    ) {
      // this.createForm();
    }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.getUserData();
    // this.retrieveCombodata();
  }

  usersData:any = {};
	rolesString:any = '';
  isAdmin:boolean = false;
  isSuperadmin:boolean = false;
  isCustomer:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(res)
		  this.usersData.role.forEach((e:any) => {
			this.rolesString = this.rolesString+e.name+', ';
		  });
			// console.log(this.rolesString)
      if(this.rolesString.includes('Admin')) {
        this.isAdmin = true;
      }
      if(this.rolesString.includes('Superadmin')) {
        this.isSuperadmin = true;
      }
			// console.log(this.isAdmin)
    })
  }


  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.fadd_role.id = '';
    this.fadd_role.run_idnumber = '';
    this.fadd_role.run_category = '';
    this.fadd_role.run_email = '';
    this.fadd_role.run_phone = '';
		this.modalService.open(content);
	}

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('pendaftaran/viewpendaftaran', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.tutorialsTemp = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  // retrieveCombodata(): void {
  //   const params = this.getRequestParams(this.title, this.page, this.pageSize);

  //   this.tutorialService.getAlls('rekenings/combodata', params)
  //     .subscribe(
  //       response => {
  //         const { data } = response;
  //         this.vcombo = data;
  //       },
  //       error => {
  //         console.log(error);
  //       });
  // }

  initializeItems(): void {
    this.tutorials = this.tutorialsTemp;
  }

  searchTerm: string = '';
  searchChanged(evt:any) {
    this.initializeItems();

    const searchTerm = evt;

    if (!searchTerm) {
      return;
    }

    this.tutorials = this.tutorials.filter(item => {
      // console.log(item, 'items')
      if (item.run_idnumber && item.run_phone && searchTerm) {
        if (item.run_idnumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          return true;
        } else if(item.run_phone.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1){
          return true;
        }
        return false;
      } else {
        return false;
      }
    });

  }


  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    id: '',
    run_idnumber: '',
    run_category: '',
    run_email: '',
    run_phone:'',

  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      id: this.fadd_role.id,
      run_idnumber: this.fadd_role.run_idnumber,
      run_category: this.fadd_role.run_category,
      run_email: this.fadd_role.run_email,
      run_phone: this.fadd_role.run_phone,
    };

    if(this.isCreated) {
      this.tutorialService.postData('country/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      // this.id = this.id
      // data.idtagihan = this.id
      // console.log(this.fadd_role.id, data, 'this.fadd_role.id, data')
      // this.tutorialService.postData('country/createbayar', data)
      this.tutorialService.postData('country/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response, 'response update');
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error, 'errors');
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  actionPembayaran(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    // console.log(tutorial, 'tes')
    this.id = tutorial.id;
    this.shortname = tutorial.shortname;
    this.name = tutorial.name;
    this.phonecode = tutorial.phonecode;
    this.fadd_role = tutorial;
    this.fadd_role.action = action;
  }

  // deleteData:any;
  deleteUser(data:any) {
    // this.deleteData = data;
    let modal = this.modalService.open(MODALS['focusFirst']);
    modal.result.then((res:any) => {
      // console.log(res)
      if(res == 'Ok') {

        this.tutorialService.delete(`country/delete/${data.id}`)
        .subscribe(
          async response => {
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

        // this.tutorialService.postData('country/delete/'+this.deleteData.id, {}).subscribe(res => {
        // this.tutorialService.delete(`country/delete/${this.deleteData.id}`).subscribe(res => {
        //   this.toastService.show('Successfully delete data.', { classname: 'bg-success text-white', delay: 10000 });
        //   this.retrieveTutorials();
        // })
      }
    });
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
