import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-send-etiket',
  templateUrl: './send-etiket.component.html',
  styleUrls: ['./send-etiket.component.css']
})
export class SendetiketComponent implements OnInit {

  form: any = {
    email: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  error: any;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
  }

  onSubmit(): void {
    // console.log('tes')
    const { email } = this.form;
    // console.log(email, password, 'email, password');

    this.authService.sendetikets(email).subscribe(
      data => {
        this.errorMessage = data.message;
        this.error = false;
      },
      err => {
        // console.log(err.error, 'err.error')
        this.errorMessage = err.error.message;
        this.error = true;
      }
    );
  }

  reloadPage(): void {
		this.router.navigate(['admin/index']);
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

}
