import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, Type } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  finalize,
} from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ICleansingResponse } from 'src/app/cleansing.response';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Image deletion</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="OnSubmit('no')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="OnSubmit('no')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="OnSubmit('Ok')">Ok</button>
  </div>
  `
})
export class NgbdModalConfirm {
  @Input() public data: any;
  constructor(public modal: NgbActiveModal) {
    // console.log(this.data)
  }

  OnSubmit(status:any) {
    this.modal.close(status);
  }
}

const MODALS: {[name: string]: Type<any>} = {
  focusFirst: NgbdModalConfirm
};

@Component({
  selector: 'app-cleansing-form',
  templateUrl: './cleansing-form.component.html',
  styleUrls: ['./cleansing-form.component.css'],
})
export class CleansingFormComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  // cleansingImg = 'http://103.214.112.148:7777/uploads/images/cleansings/';
  cleansingImg = 'https://api.siger.co.id/uploads/images/cleansings/';
  // cleansingImg = 'http://localhost:39998/uploads/images/cleansings/';
  // cleansingImg = 'http://localhost:39999/uploads/images/cleansings/';
  isLoading$ = false;
  // shippers
  shippers: any[] = [];
  selectedShipper: any | undefined;
  // vendor
  vendors: any[] = [];
  cities: any[] = [];
  selectedVendors: any | undefined;
  selectedPrice:any;
  selectedCountryConsignee:any;
  selectedCityConsignee:any;
  // consignee
  consignee: any[] = [];
  selectedConsignee: any | undefined;
  //
  selectedPaymentType: any | undefined;

  paymentType = [
    { name: 'WEEKLY', id: 1},
    { name: 'COD', id: 2},
  ];

  currencies = [
    { name: 'IDR', id: 1 },
    { name: 'USD', id: 2 },
  ];

  selectedCurrency: any | undefined;

  //
  typeItems: any[] = [];
  selectedTypeItem: any | undefined;

  //
  subTypeItems: any[] = [];
  selectedSubTypeItem: any | undefined;

  countries: any[] = [];
  selectedShipperPhoneNumberCountry: any = {};
  selectedPhoneNumber: any = {};

  // paymentinfo items
  packages: any[] = [];

  // typeahead
  text: any = {};

  results: any[] = [];
  // images
  files: File[] = [];
  filesOld:any = [];

  // editdata
  id: string | undefined;

  // code_resi_inbound: any;
  // awb_vendor: any;

  async filterValue(value: any) {
    this.apiService
      .get(`consignees/combodata?title=${value.query}`)
      .subscribe((data: any) => {
        this.results = data.data;
      });
  }

  onSelected(value: any) {}

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
  }

  action:any = '';
  dataCleansing:any;
  detailCleansing:any = {};
  async ngOnInit(): Promise<void> {
    await this.initForm();
    await this.getUserData();
    this.route.queryParams.subscribe(async (params: any) => {
      if(params.info) {
        let item = JSON.parse(params.info);
        this.dataCleansing = item;
        this.action = JSON.parse(params.action);
        this.apiService
        .get(`cleansings/detail/${this.dataCleansing.id}`)
        .subscribe((data: ICleansingResponse) => {
          this.detailCleansing = data;
          // console.log(this.detailCleansing,'this.detailCleansing')
          if(this.detailCleansing.shipperdetails.length > 0) {
            this.selectedShipperPhoneNumberCountry.phonecode = this.detailCleansing.shipperdetails[0].phonecode;
          }
          if(this.detailCleansing.consigneesdetails.length > 0) {
            this.selectedPhoneNumber.phonecode = this.detailCleansing.consigneesdetails[0].phonecode;
            this.selectedCountryConsignee = this.detailCleansing.consigneesdetails[0].id_country;
            // this.selectedCityConsignee = this.detailCleansing.consigneesdetails[0].id_city;
            this.form.patchValue({
              country: this.detailCleansing.consigneesdetails[0].id_country,
              // city: this.detailCleansing.consigneesdetails[0].id_city,
            });
          }

          if(this.detailCleansing.imagedetails.length > 0){
            this.filesOld = this.detailCleansing.imagedetails;
            this.filesOld.forEach((e:any) => {
              e.images = this.cleansingImg+e.images;
            })
          }

          if(data.packgedetails) {
            this.packages = data.packgedetails;
            this.packages.forEach(async (e:any, idx:any) => {
              if(!e.qty) {
                e.qty = e.quantity
              }
              if(!e.desc) {
                e.desc = e.description
              }
              if(e.currency != 1 || e.currency != 2) {
                e.currency = this.detailCleansing.data.currency;
                let selectedCurrency = this.currencies.find(c => c.id=== e.currency);
                e.selectedCurrency = selectedCurrency;
              }
            })
          }
        });
        this.populateDataForm(this.dataCleansing);
        // this.router.navigate(['/admin/app-tr-cleansings']);
      } else {
        await this.fetchDropdownValues();
      }

      if (params.id) {
        this.id = params.id;
        this.getDetail();
      }
    });
  }

  usersData:any = {};
  rolesString = '';
  isSuperadmin:boolean = false;
  isAgen:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(this.usersData)
		  this.usersData.role.forEach((e:any) => {
			this.rolesString = this.rolesString+e.name+', ';
		  });
			// console.log(this.rolesString)
      if(this.rolesString.includes('Superadmin')) {
        this.isSuperadmin = true;
      }
      if(this.rolesString.includes('Agen')) {
        this.isAgen = true;
      }
      if(this.usersData.shipper.length > 0) {
        this.form.patchValue({
          shipper_id: this.usersData.shipper[0].id
        });
        await this.fetchDropdownValues();
      }
    })
  }

  get f() { return this.form.controls; }
  async populateDataForm(data:any) {
    // console.log(data, 'data inserts')
    this.form.patchValue({
      // shippinginfo
      connote: data.connote,
      ship_date: data.ship_date,
      shipper_id: data.shipper_id,
      vendor_id: data.vendor_id,
      shipper_reff: data.shipper_reff,
      // code_resi_inbound: data.code_resi_inbound,
      // awb_vendor: data.awb_vendor,
      // shipper_name:  data.,
      shipper_reff_phone_number: data.shipper_reff_phone_number,
      airwaybill: data.airwaybill,

      // consigneeinfo
      consignee_id: data.consignee_id,
      // reff_name: data.,
      phone_number: data.shipmentnumber,
      // shipmentnumber: data.shipmentnumber,
      // name: data.,
      // id_country: data.,
      // country: data.,
      // id_city: data.,
      // city: data.,
      // post_code: data.,
      // address: data.,
      // arc_number: data.,

      //! packageinfo
      total_pcs: data.total_pcs,
      total_colly: data.total_colly,
      customs_value: data.customs_value,
      actual_weight: data.actual_weight,
      weight: data.weight, //chargedweight
      description: data.description,
      note: data.note,

      //! paymentinfo
      payment_term: data.payment_term,
      currency: data.currency,
      type_id: data.type_id,
      subtype_id: data.subtype_id,
      cost: data.cost,
      payment: data.payment,
    });
    await this.fetchDropdownValues();
    if(this.action == 'view') {
      this.form.disable();
    }
  }

  getDetail() {
    this.apiService
      .get(`cleansings/detail/${this.id}`)
      .subscribe((data: ICleansingResponse) => {
        // console.log(data, 'datas')
        this.form.patchValue({
          // shippinginfo
          connote: data.data?.connote,
          ship_date: data.data?.ship_date,
          shipper_id: data.data?.shipper_id,
          vendor_id: data.data?.vendor_id,
          shipper_reff: data.data?.shipper_reff,
          // code_resi_inbound: data.data?.code_resi_inbound,
          // awb_vendor: data.data?.awb_vendor,
          // shipper_name:  data.data?.,
          shipper_reff_phone_number: data.data?.shipper_reff_phone_number,
          airwaybill: data.data?.airwaybill,

          // consigneeinfo
          consignee_id: data.data?.consignee_id,
          // reff_name: data.data?.,
          phone_number: data.data?.shipmentnumber,
          // name: data.data?.,
          // id_country: data.data?.,
          // country: data.data?.,
          // id_city: data.data?.,
          // city: data.data?.,
          // post_code: data.data?.,
          // address: data.data?.,
          // arc_number: data.data?.,

          //! packageinfo
          total_pcs: data.data?.total_pcs,
          total_colly: data.data?.total_colly,
          customs_value: data.data?.customs_value,
          actual_weight: data.data?.actual_weight,
          weight: data.data?.weight, //chargedweight
          description: data.data?.description,
          note: data.data?.note,

          //! paymentinfo
          payment_term: data.data?.payment_term,
          currency: data.data?.currency,
          type_id: data.data?.type_id,
          subtype_id: data.data?.subtype_id,
          cost: data.data?.cost,
          payment: data.data?.payment,
        });
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      // shippinginfo
      connote: [
        {
          value: this.getRandomIntInclusive(100000000000000, 199999999999999),
          disabled: true,
        },
        [Validators.required],
      ], //text
      ship_date: [new Date(), [Validators.required]],
      shipper_id: ['', [Validators.required]],
      // vendor_id: ['', [Validators.required]],
      vendor_id: [''],
      shipper_reff: [''], //text
      // code_resi_inbound: [''],
      // awb_vendor: [''],
      shipper_name: [''], //text
      shipper_code_resi: [''], //text
      shiperprice: [''], //text
      shipper_reff_phone_number: ['', [Validators.required]],
      airwaybill: [
        {value:this.getRandomIntInclusive(10000000, 19999999),disabled: true,},
        [Validators.required],
      ], //text

      // consigneeinfo
      // consignee_id: ['', [Validators.required]],
      consignee_id: [''],
      reff_name: ['', [Validators.required]],
      // phonecodecunumen: [''],
      phonecodecunumen: [''],
      phone_number: ['', [Validators.required]],
      name: ['', [Validators.required]],
      id_country: ['', [Validators.required]],
      country: ['', [Validators.required]],
      id_city: ['', [Validators.required]],
      city: [{ value: '', disabled: true }, [Validators.required]],
      post_code: ['', [Validators.required]],
      address: ['', [Validators.required]],
      arc_number: [''],

      //! packageinfo
      total_pcs: ['', [Validators.required]],
      total_colly: ['', [Validators.required]],
      customs_value: ['', [Validators.required]],
      actual_weight: ['', [Validators.required]],
      weight: ['', [Validators.required]], //chargedweight
      description: [''],
      note: [''],

      //! paymentinfo
      payment_term: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      type_id: ['', [Validators.required]],
      subtype_id: ['', [Validators.required]],
      cost: ['', [Validators.required]],
      payment: [
        {
          value: '',
          disabled: true,
        },
        [Validators.required],
      ],

      //! packages
      package_type_id_: [''],
      package_subtype_id_: [''],
      package_quantity_: [''],
      package_currency_: [''],
      package_price_: [''],
      package_description_: [''],

      // !!!hardcoded TEmP
      code: ['C001'],
      // awb_vendor: ['333'],

      height: [40],
      width: [50],
      lengths: [60],
      status_vendor: [0],
      status_apisg: [''],
      list_response: [''],
      shipmentnumber: [''],
    });
    this.form.get('city')?.disable();
  }

  dropdownPackageChange(event: any, tipe: string, index: number) {
    this.packages.map(async (item: any, i: number) => {
      if (i === index) {
        if (tipe === 'type_id') {
          item.itemType = event.value.id;
          item.selectedTypeItem = event.value;
          const subTypesResult: any = await this.fetchSubType(event.value.id);
          item.subTypeSources = subTypesResult.data || [];
        }

        if (tipe === 'subtype_id') {
          item.subItemType = event.value.id;
          item.selectedSubTypeItem = event.value;
        }

        if (tipe === 'currency') {
          item.currency = event.value.id;
          item.selectedCurrency = event.value;
        }
      }
      return item;
    });
  }

  dropdownPackageClear(tipe: string, index: number) {}

  async fetchSubType(type_id: number) {
    return this.apiService
      .get(`typeitemsubs/combodata?type_id=${type_id}`)
      .toPromise();
  }

  addPackageInfo() {
    this.packages.push({
      itemType: '',
      subItemType: '',
      qty: '',
      currency: '',
      price: '',
      desc: '',
      selectedTypeItem: {},
      selectedSubTypeItem: {},
      selectedCurrency: {},
      subTypeSources: [],
    });
  }

  removePackageInfo(id: number) {
    this.packages.splice(id, 1);
  }

  onAutoCompleteSelected(event: any, tipe: string) {
    const val = {
      value: event,
    };
    this.onDropdownChange(val, tipe);
  }

  async onDropdownChange(event: any, tipe: string) {
    if (tipe === 'shipper_id') {
      this.selectedShipper = event.value;
      if (this.selectedShipper) {
        let shipperprice = this.selectedShipper.shipperprice;
        if(this.selectedConsignee) {
          this.selectedShipper.selectedShipper = shipperprice.find((e:any) => e.dest_country == this.selectedConsignee.id_country);
        }

        if(this.selectedShipper.selectedShipper) {
          let purc = this.selectedShipper.selectedShipper.purc_price == null ? 0:this.selectedShipper.selectedShipper.purc_price;
          let sell = this.selectedShipper.selectedShipper.sell_price == null ? 0:this.selectedShipper.selectedShipper.sell_price;

          // this.selectedShipper.optionPrice = [{'price': purc},{'price': sell}];
          this.selectedShipper.optionPrice = [{'price': sell}];
        }

        this.form.patchValue({
          shipper_id: this.selectedShipper.id,
          shipper_code_resi: this.selectedShipper.code_resi,
          shipper_name: this.selectedShipper.name,
          shipper_reff_phone_number: this.selectedShipper.phone_number,
          // priceshippers: this.selectedShipper.optionPrice,
        });
        if(this.action != 'view' || this.action != 'update'){
          if(this.selectedShipper.phonecode) {
            let phoneCode = this.countries.find(e => e.phonecode == this.selectedShipper.phonecode);
            // console.log(phoneCode)
            this.selectedShipperPhoneNumberCountry = phoneCode;
          }
        }
        // console.log(this.selectedShipper)
      }
      if(this.usersData.shipper) {
        this.form.get('shipper_code_resi')?.disable();
        // this.form.get('shipper_reff')?.disable();
        this.form.get('shipper_reff_phone_number')?.disable();
      }
    }

    if (tipe === 'vendor_id') {
      this.selectedVendors = event.value;
      if (this.selectedVendors)
        this.form.patchValue({
          vendor_id: this.selectedVendors.id,
        });
    }

    if (tipe === 'consignee_id') {
      this.selectedConsignee = event.value;
      if (this.selectedConsignee) {
        // console.log(this.selectedConsignee.id, 'this.selectedConsignee.id')
        this.form.patchValue({
          consignee_id: this.selectedConsignee.id,

          reff_name: this.selectedConsignee.reff_name,
          phone_number: this.selectedConsignee.phone_number,
          name: this.selectedConsignee.name,
          id_country: this.selectedConsignee.id_country,
          country: this.selectedConsignee.country,
          id_city: this.selectedConsignee.id_city,
          city: this.selectedConsignee.city,
          post_code: this.selectedConsignee.post_code,
          address: this.selectedConsignee.address,
          arc_number: this.selectedConsignee.arc_number,
        });

        await this.apiService.get(`country/combodata?limit=300`).subscribe((response: any) => {
          this.countries = response.data;
          let data = {
            'value' : this.countries.find(e => e.id === this.selectedConsignee.id_country)
          }
          if(data.value) {
            this.onDropdownChange(data, 'consignee_country');
          }
          if(this.action != 'view' || this.action != 'update'){
            let phoneCode = this.countries.find(e => e.id == this.selectedConsignee.id_country);
            // console.log(phoneCode)
            this.selectedPhoneNumber = phoneCode;
          }
        });
        // if(this.selectedConsignee) {
          if(this.selectedShipper) {
            let shipperprice = this.selectedShipper.shipperprice;
            if(this.selectedConsignee) {
              this.selectedShipper.selectedShipper = shipperprice.find((e:any) => e.dest_country == this.selectedConsignee.id_country);
            }

            if(this.selectedShipper.selectedShipper) {
              let purc = this.selectedShipper.selectedShipper.purc_price == null ? 0:this.selectedShipper.selectedShipper.purc_price;
              let sell = this.selectedShipper.selectedShipper.sell_price == null ? 0:this.selectedShipper.selectedShipper.sell_price;

              // this.selectedShipper.optionPrice = [{'price': purc},{'price': sell}];
              this.selectedShipper.optionPrice = [{'price': sell}];
            }
          }
        // }
      }
    }

    if (tipe === 'payment_term') {
      this.selectedPaymentType = event.value;
      if (this.selectedPaymentType)
        this.form.patchValue({
          payment_term: this.selectedPaymentType.id,
        });
    }

    if (tipe === 'currency') {
      this.selectedCurrency = event.value;
      if (this.selectedCurrency)
        this.form.patchValue({
          currency: this.selectedCurrency.id,
        });
    }

    if (tipe === 'type_id') {
      this.selectedTypeItem = event.value;
      if (this.selectedTypeItem) {
        this.form.patchValue({
          type_id: this.selectedTypeItem.id,
        });
        this.fetchSubItem(this.selectedTypeItem.id);
      }
    }

    if (tipe === 'subtype_id') {
      this.selectedSubTypeItem = event.value;
      if (this.selectedSubTypeItem)
        this.form.patchValue({
          subtype_id: this.selectedSubTypeItem.id,
        });
    }

    if(tipe === 'shipper_phone') {
      this.selectedShipperPhoneNumberCountry = event.value;
    }

    if(tipe === 'phone_number') {
      this.selectedPhoneNumber = event.value;
      this.form.patchValue({
        phonecodecunumen: this.selectedPhoneNumber.phonecode,
      });
      // phonecodecunumen
      // console.log(this.selectedPhoneNumber, 'this.selectedPhoneNumber1')
    }

    if(tipe === 'select_price') {
      this.selectedPrice = event.value;
      // console.log('selectedPrice',this.selectedPrice);
      //get price estimation
      let dataPrice = 0;
      const cost = this.form.get('cost')?.value;
      const actual_weight = this.form.get('actual_weight')?.value;
      if(actual_weight && cost) {
        dataPrice = this.selectedPrice.price * actual_weight * cost;
        // console.log(dataPrice)

        // this.form.patchValue({ payment: dataPrice });
        this.form.patchValue({ payment: dataPrice, shiperprice: this.selectedPrice.price });
      }
    }

    if(tipe === 'consignee_country') {
      // console.log(event.value)
      this.selectedCountryConsignee = event.value;
      
      // console.log('consignee_country',this.selectedCountryConsignee);
      if (this.selectedCountryConsignee)
        this.form.patchValue({
          id_country: this.selectedCountryConsignee.id,
          country: this.selectedCountryConsignee.name,
        });
        // this.form.get('city')?.enable();
        // console.log(this.)
        this.getCities();
    }

    if(tipe === 'consignee_city') {
      // console.log(event.value,'event.value')
      this.selectedCityConsignee = event.value;
      // console.log('consignee_city',this.selectedCityConsignee);
      if (this.selectedCityConsignee)
        this.form.patchValue({
          id_city: this.selectedCityConsignee.id,
          city: this.selectedCityConsignee.name,
        });
    }
  }

  async getCities() {
    // this.selectedCityConsignee = '3493'
    // console.log(this.selectedCountryConsignee,'a')
    // console.log(this.selectedCityConsignee,'this.selectedCityConsignee.id')
    // v1/api/states/combodata?page=1&limit=3&country_id=102
    await this.apiService.get('states/combodata?page=1&limit=300000000&country_id='+this.selectedCountryConsignee.id).subscribe((response: any) => {
      this.cities = response.data;

      // this.selectedCityConsignee = 3493
      // console.log(this.cities,'jj')
      // let subType = this.cities.find((e:any) => e.country_id == this.selectedCountryConsignee.id);
        // console.log(subType, 'subType')
        // this.selectedSubTypeItem = subType;
        let subType = this.cities.find((e:any) => e.id == this.selectedConsignee.id_city);

        if(subType != undefined){
          this.selectedCityConsignee = subType
        }

    });
  }

  getCost() {
    // console.log(this.selectedCountryConsignee, 'a')
    if(this.selectedCountryConsignee) {
      let shipperprice = this.selectedShipper.shipperprice;
      // console.log(shipperprice)

      // this.selectedShipper.selectedShipper = shipperprice.find((e:any) => e.dest_country == this.selectedCountryConsignee.id_country);
      this.selectedShipper.selectedShipper = shipperprice.find((e:any) => e.dest_country == this.selectedCountryConsignee.id);

      if(this.selectedShipper.selectedShipper) {
        let purc = this.selectedShipper.selectedShipper.purc_price == null ? 0:this.selectedShipper.selectedShipper.purc_price;
        let sell = this.selectedShipper.selectedShipper.sell_price == null ? 0:this.selectedShipper.selectedShipper.sell_price;

        // this.selectedShipper.optionPrice = [{'price': purc},{'price': sell}];
        this.selectedShipper.optionPrice = [{'price': sell}];
      } else {
        // this.selectedShipper.optionPrice = [{'price': 0},{'price': 0}];
        this.selectedShipper.optionPrice = [{'price': 0}];
      }
    }
  }

  onDropdownClear(tipe: string) {
    if (tipe === 'shipper_id') {
      this.selectedShipper = undefined;
      this.form.patchValue({
        shipper_id: '',
        shipper_code_resi: '',
        shipper_name: '',
        shipper_reff_phone_number: '',
      });
    }
    if (tipe === 'vendor_id') {
      this.selectedVendors = undefined;
      this.form.patchValue({
        vendor_id: '',
      });
    }

    if (tipe === 'consignee_id') {
      this.selectedConsignee = undefined;
      this.form.patchValue({
        consignee_id: '',
        reff_name: '',
        phone_number: '',
        name: '',
        id_country: '',
        country: '',
        id_city: '',
        city: '',
        post_code: '',
        address: '',
        arc_number: '',
      });
    }

    if (tipe === 'payment_term') {
      this.selectedPaymentType = undefined;
      this.form.patchValue({
        payment_term: '',
      });
    }
    if (tipe === 'currency') {
      this.selectedCurrency = undefined;
      this.form.patchValue({
        currency: '',
      });
    }
    if (tipe === 'type_id') {
      this.selectedTypeItem = undefined;
      this.form.patchValue({
        type_id: '',
      });
    }
    if (tipe === 'subtype_id') {
      this.selectedSubTypeItem = undefined;
      this.form.patchValue({
        subtype_id: '',
      });
    }

    if(tipe === 'shipper_phone') {
      this.selectedShipperPhoneNumberCountry = {};
    }

    if(tipe === 'phone_number') {
      this.selectedPhoneNumber = {};
    }

    if(tipe === 'consignee_country') {
      this.selectedCountryConsignee = null;
    }
  }

  fetchSubItem(type_id: number) {
    this.apiService
      .get(`typeitemsubs/combodata?type_id=${type_id}`)
      .subscribe((response: any) => {
        this.subTypeItems = response.data;
        if(this.action == 'view' || this.action == 'update'){
          let data = {
            'value' : this.subTypeItems.find(e => e.id === this.form.get('subtype_id')?.value)
          }
          if(data.value)
          this.onDropdownChange(data, 'subtype_id');
        }
      });
  }

  async fetchDropdownValues() {
    // this.selectedPaymentType = event.value;
    // this.selectedShipperPhoneNumberCountry = event.value;
    // this.selectedCurrency = event.value;

    await this.apiService.get(`shippers/combodata`).subscribe((response: any) => {
      this.shippers = response.data;
      if(this.action == 'view' || this.action == 'update'){
        let data = {
          'value' : this.shippers.find(e => e.id === this.form.get('shipper_id')?.value)
        }
        if(data.value)
        this.onDropdownChange(data, 'shipper_id');
      } else {
        if(this.usersData.shipper) {
          let data = {
            'value' : this.shippers.find(e => e.id === this.form.get('shipper_id')?.value)
          }
          if(data.value)
          this.onDropdownChange(data, 'shipper_id');
        }
      }
    });

    await this.apiService.get(`consignees/combodata`).subscribe((response: any) => {
      this.consignee = response.data;
      // console.log(this.consignee,'this.consignee')
      // console.log(this.form.get('consignee_id')?.value,'this.consignee')
      if(this.action == 'view' || this.action == 'update'){
        let data = {
          'value' : this.consignee.find(e => e.id === this.form.get('consignee_id')?.value)
        }
        // console.log(data, 'dat1a');
        if(data.value)
        this.onDropdownChange(data, 'consignee_id');
        // console.log(data.value.id_city, 'data');
        // this.selectedCityConsignee = data.value.id_city;
        console.log(data, 'data');
      }
    });

    await this.apiService.get(`typeitems/combodata`).subscribe((response: any) => {
      this.typeItems = response.data;
      if(this.action == 'view' || this.action == 'update'){
        let data = {
          'value' : this.typeItems.find(e => e.id === this.form.get('type_id')?.value)
        }
        if(data.value)
        this.onDropdownChange(data, 'type_id');
      }
    });

    await this.apiService.get(`vendors/combodata`).subscribe((response: any) => {
      this.vendors = response.data;
      if(this.action == 'view' || this.action == 'update'){
        let data = {
          'value' : this.vendors.find(e => e.id === this.form.get('vendor_id')?.value)
        }
        if(data.value)
        this.onDropdownChange(data, 'vendor_id');
      }
    });

    await this.apiService.get(`country/combodata?limit=300`).subscribe((response: any) => {
      this.countries = response.data;
      if(this.action == 'view' || this.action == 'update'){
        let data = {
          'value' : this.countries.find(e => e.phonecode === this.form.get('shipper_phone')?.value)
        }
        if(data.value) {
          this.onDropdownChange(data, 'shipper_phone');
        }

        let data2 = {
          'value' : this.countries.find(e => e.id === this.form.get('country')?.value)
        }
        if(data2.value) {
          this.onDropdownChange(data2, 'consignee_country');
        }
      }
    });
    if(this.action == 'view' || this.action == 'update'){
      let data = {
        'value' : this.paymentType.find(e => e.id === this.form.get('payment_term')?.value)
      }
      if(data.value)
      this.onDropdownChange(data, 'payment_term');

      let data2 = {
        'value' : this.currencies.find(e => e.id === this.form.get('currency')?.value)
      }
      if(data2.value)
      this.onDropdownChange(data2, 'currency');
    }

    if(this.dataCleansing) {
      this.apiService
      .get(`cleansings/detail/${this.dataCleansing.id}`)
      .subscribe((data: ICleansingResponse) => {
        // console.log(data)
        if(data.packgedetails) {
          this.packages = data.packgedetails;
          this.packages.forEach(async (e:any, idx:any) => {
            // console.log(e, 'edit')

            if(!e.qty) {
              e.qty = e.quantity
            }
            if(!e.desc) {
              e.desc = e.description
            }
            if(e.type_id) {
              let type = this.typeItems.find((a:any) => a.id === e.type_id)
              if(type) {
                e.itemType = type.id;
                e.selectedTypeItem = type;
                const subTypesResult: any = await this.fetchSubType(type.id);
                e.subTypeSources = subTypesResult.data || [];
              }
            }

            if(e.subtype_id) {
              const subTypesResult: any = await this.fetchSubType(e.type_id);
              let subTypeSources = subTypesResult.data || [];

              let subItemType = subTypeSources.find((a:any) => a.id === e.subtype_id);
              if(subItemType) {
                e.subItemType = subItemType.id;
                e.selectedSubTypeItem = subItemType;
              }
            }

            if(e.currency) {
              if(e.currency != 1 || e.currency != 2) {
                e.currency = this.detailCleansing.data.currency;
                // console.log(this.detailCleansing.data.currency, 'this.detailCleansing.data.currency')
                let selectedCurrency = this.currencies.find(c => c.id=== e.currency);
                e.selectedCurrency = selectedCurrency;
              }
            }

          })
        }
      });
    }
  }

  getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  save(action:any) {
    console.log(this.form,'this.form.valid0')
    // console.log(this.form.controls.actual_weight.errors?.required,'this.form.valid1')
    // console.log(this.form.controls.address.errors?.required,'this.form.valid2')
    // console.log(this.form.controls.address,'this.form.valid3')
    // console.log(this.form.value.address,'this.form.valid4')
    // console.log(this.selectedConsignee, 'action')
    // console.log(this.selectedConsignee == undefined, 'action')
    // console.log(this.form.patchValue, 'this.form.patchValue')
    // consignee_id: this.selectedConsignee.id
    this.isLoading$ = true;

    if(this.form.controls.address.errors?.required == true){
      this.toastService.show('Address Tidak Boleh Kosong', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    
    if(this.form.controls.country.errors?.required == true){
      this.toastService.show('Country Tidak Boleh Kosong', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    if(this.form.controls.id_city.errors?.required == true){
      this.toastService.show('ID City Tidak di Temukan', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    if(this.form.controls.id_country.errors?.required == true){
      this.toastService.show('ID Country Tidak di Temukan', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }

    if(this.form.controls.name.errors?.required == true){
      this.toastService.show('Consignee Name Tidak di Temukan', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    if(this.form.controls.phone_number.errors?.required == true){
      this.toastService.show('Consignee Phone number Tidak di Temukan', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    if(this.form.controls.phonecodecunumen.errors?.required == true){
      this.toastService.show('Consignee Code Phone number Tidak di Temukan', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    if(this.form.controls.post_code.errors?.required == true){
      this.toastService.show('Consignee Code Pos Tidak di Temukan', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    if(this.form.controls.reff_name.errors?.required == true){
      this.toastService.show('Consignee Reff_name Tidak di Temukan', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    // if(this.form.controls.vendor_id.errors?.required == true){
    //   this.toastService.show('Vendor Tidak Boleh Kosong', { classname: 'bg-danger text-white', delay: 15000 });
    //   this.isLoading$ = false;
    //   return;
    // }

    if (!this.form.valid) {
      // console.log(
      //   '🚀 ~ file: cleansing-form.component.ts ~ line 407 ~ CleansingFormComponent ~ this.form.valid',
      //   this.form.valid
      // );
      this.toastService.show('Insert all field value!', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }

    // if(this.files.length < 2) {
    if(this.files.length < 1) {
      this.toastService.show('Image must be 1 files or more!', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }

    if(this.packages.length == 0) {
      this.toastService.show('Items must be 1 data or more!', { classname: 'bg-danger text-white', delay: 15000 });
      this.isLoading$ = false;
      return;
    }
    const typeArr: any[] = [];
    const subTypeArr: any[] = [];
    const qtyArr: any[] = [];
    const currencyArr: any[] = [];
    const priceArr: any[] = [];
    const descArr: any[] = [];
    this.packages.forEach((item: any) => {
      // console.log(item, 'detail packge')
      typeArr.push(`"${item.itemType}"`);
      subTypeArr.push(`"${item.subItemType}"`);

      qtyArr.push(`"${item.qty}"`);
      currencyArr.push(`"${item.currency}"`);
      priceArr.push(`"${item.price}"`);
      descArr.push(`"${item.desc}"`);
    });
    this.form.patchValue({
      package_type_id_: `[${typeArr}]`,
      package_subtype_id_: `[${subTypeArr}]`,
      package_quantity_: `[${qtyArr}]`,
      package_currency_: `[${currencyArr}]`,
      package_price_: `[${priceArr}]`,
      package_description_: `[${descArr}]`,
    });

    // console.log(
    //   '🚀 ~ file: cleansing-form.component.ts ~ line 359 ~ CleansingFormComponent ~ save',
    //   this.form.getRawValue()
    // );

    const formdata = new FormData();

    const val1 = this.form.get('shipper_reff_phone_number')?.value;

    if(this.selectedConsignee == undefined){
      // console.log('kosong')
      this.form.patchValue({
        consignee_id: 'undefined',
      });
      // if(this.selectedConsignee.id == undefined){
      //   this.form.patchValue({
      //     consignee_id: this.selectedConsignee.id,
      //   });
      // }
    }else if(this.selectedConsignee.id == undefined){
        this.form.patchValue({
          consignee_id: this.selectedConsignee.id,
        });
    }


    this.form.patchValue({
      code: 'C001',
      // awb_vendor: 333,
      // consignee_id: this.selectedConsignee.id
      // consignee_id: this.selectedConsignee.id,
      height: 40,
      width: 50,
      lengths: 60,
      status_vendor: 0,
      status_apisg: '',
      list_response: '',
      shipmentnumber: '',
      shipper_reff_phone_number: `${this.selectedShipperPhoneNumberCountry?.phonecode}${val1}`,
      phonecode: `${this.selectedShipperPhoneNumberCountry?.phonecode}`,
    });

    for (var key in this.form.getRawValue()) {
      formdata.append(key, this.form.getRawValue()[key]);
    }

    for(var i=0; i<this.files.length; i++) {
      formdata.append('fileimage', this.files[i]);
    }

    // console.log(this.selectedConsignee.id, 'action')
    // if(this.selectedConsignee.id == undefined){
    //   formdata.append('consignee_id', this.selectedConsignee.id)
    // }

    if(this.action == 'update') {
      // console.log(formdata, 'formdata update')
      this.apiService
      .post(`cleansings/update/`+this.dataCleansing.id, formdata)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
      .subscribe((data: any) => {
        // console.log(data, 'data update')

        this.toastService.show('Successfully update data.', { classname: 'bg-success text-white', delay: 10000 });
        if(action == 'print'){
          this.cetak(data.data);
        } else {
          this.router.navigate(['/admin/app-tr-cleansings']);
        }
      }, err => {
        // console.log(err.error.status_response, 'err update')
        if(err.error.status_response == "Inbound"){
          this.toastService.show('Resi Inbound Not Found', { classname: 'bg-danger text-white', delay: 15000 });
        }else{
          this.toastService.show('Failed to update data!', { classname: 'bg-danger text-white', delay: 15000 });
        }
      });
    } else {
      // console.log(formdata, 'formdata create')
      this.apiService
      .post(`cleansings/create`, formdata)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
      .subscribe((data: any) => {
        // console.log(data)

        this.toastService.show('Successfully save data.', { classname: 'bg-success text-white', delay: 10000 });
        if(action == 'print'){
          this.cetak(data.data);
        } else {
          this.router.navigate(['/admin/app-tr-cleansings']);
        }
      }, err => {
        // console.log(err.error);
        if(err.error.status_response == "Inbound"){
          this.toastService.show('Resi Inbound Not Found', { classname: 'bg-danger text-white', delay: 15000 });
        }else{
          this.toastService.show('Failed to save data!', { classname: 'bg-danger text-white', delay: 15000 });
        }
      });
    }
  }

  async cetak(data:any) {
    // await this.apiService.get('cleansings/cetak/'+data.id).subscribe(res => {
    // await this.apiService.getcetak('welcome/laporan/'+data.id).subscribe(res => {
    //   // console.log(res)
    // })
    // window.location.href = 'http://localhost/report/welcome/laporan/'+data.id, '_blank';
    // window.location.href = 'http://localhost/report/welcome/laporan/', '_blank';
    // window.open('http://www.example.com?ReportID=1', '_blank');
    var anchor = document.createElement('a');
    // anchor.href = 'http://localhost/report/welcome/laporan/'+data.id;
    anchor.href = 'https://report.solustik.co.id/welcome/laporan/'+data.id;
    anchor.target="_blank";
    anchor.click();
    this.router.navigate(['/admin/app-tr-cleansings']);
  }

  removeImages(image:any, idx:any) {
    let modal = this.modalService.open(MODALS['focusFirst']);
    modal.result.then((res:any) => {
      // console.log(image.id, idx, 'image.id')
      if(res == 'Ok') {
        this.apiService.postData('cleansingimages/deleteimage/'+image.id, {}).subscribe(res => {
          // console.log(res, 'res')
          this.filesOld.splice(idx, 1);
          this.toastService.show('Successfully delete image.', { classname: 'bg-success text-white', delay: 6000 });
        }, err => {
          this.toastService.show(JSON.stringify(err.error.errors), { classname: 'bg-danger text-white', delay: 6000 });
        })
      }
    });
  }

  getPayment() {
    // const total_pcs = this.form.get('total_pcs')?.value;
    // const total_colly = this.form.get('total_colly')?.value;
    // const cost = this.form.get('cost')?.value;
    // const payment = total_pcs * total_colly * cost;
    // console.log(
    //   '🚀 ~ file: cleansing-form.component.ts ~ line 409 ~ CleansingFormComponent ~ payment',
    //   payment
    // );
    //get price estimation
    let dataPrice = 0;
    // const cost = this.form.get('cost')?.value;-
    const cost = 1;//+
    const actual_weight = this.form.get('actual_weight')?.value;
    if(actual_weight && cost) {
      // console.log(this.selectedPrice, 'pertama')
      // console.log(this.selectedPrice.price == 0 ? 1:this.selectedPrice.price, 'price')
      // console.log(cost, 'cost')
      // console.log(weight, 'wight')
      let prce = this.selectedPrice.price == 0 ? 1:this.selectedPrice.price
      // console.log(prce * cost * weight, 'kali')
      // dataPrice = this.selectedPrice.price * weight * cost;
      dataPrice = prce * actual_weight * cost;
      // console.log(dataPrice)

      // this.form.patchValue({ payment: dataPrice });
      this.form.patchValue({ payment: dataPrice, shiperprice: this.selectedPrice.price });
    }
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  selectShipperPhoneNumberCountry(item: any) {
    this.selectedShipperPhoneNumberCountry = item;
    // console.log(
    //   this.selectedShipperPhoneNumberCountry
    // );
    /* const val = this.form.get('shipper_reff_phone_number')?.value;
    this.form.patchValue({
      shipper_reff_phone_number: `${item.phonecode}${val}`,
    }); */
  }
}
