<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Transaction</a></li>
            <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Inbound</a></li>
          </ol>
        </div>

      </div>


    </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input type="text" class="form-control" placeholder="Search" [(ngModel)]="title" />
        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a *ngIf="isAdmin || isAgen || isInbound" href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
      <a *ngIf="isAdmin || isSuperadmin || isInbound" href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendAgenModal)"><i class="fa fa-plus scale5 mr-2"></i>Create Agen/Mitra</a>
      <a *ngIf="isAdmin || isAgen || isInbound" href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendAgenModalKurir)"><i class="fa fa-plus scale5 mr-2"></i>Create Kurir By Scan</a>
      <!-- <a *ngIf="isSuperadmin" href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendAgenModalKurir1)"><i class="fa fa-plus scale5 mr-2"></i>Create Konfirmasi Terima</a> -->
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Inbound</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Code</th>
                    <th scope="col">Paket</th>
                    <th scope="col">Resi</th>
                    <th scope="col">Kurir</th>
                    <!-- <th scope="col">Status Kurir</th> -->
                    <th scope="col">Tanggal Kirim</th>
                    <th scope="col">Tanggal Terima</th>
                    <th scope="col">Pengirim</th>
                    <th scope="col">CreateBy</th>
                    <!-- <th scope="col">Status Vendor</th> -->
                    <!-- <th scope="col">Type</th> -->
                    <!-- <th scope="col">Template</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x+1 }}
                    </th>
                    <td >
                      {{ tutorial.code }}
                    </td>
                    <td *ngIf="tutorial.status_kurir == 'Y'">
                      Kurir
                    </td>
                    <td *ngIf="tutorial.status_pickup == 'Y'">
                      Pickup
                    </td>
                    <td *ngIf="tutorial.status_direct == 'Y'">
                      Direct
                    </td>
                    <td *ngIf="tutorial.no_resi != 'null'">
                      {{ tutorial.no_resi }}
                    </td>
                    <td *ngIf="tutorial.no_resi == 'null'">
                      -
                    </td>
                    <td >
                      {{ tutorial.namakurirs }}
                    </td>
                    <!-- <td>{{ tutorial.status_kurir }}</td> -->
                    <td *ngIf="tutorial.status_kurir == 'Y'">{{ tutorial.tgl_kirim }}</td>
                    <td *ngIf="tutorial.status_kurir == 'N'">-</td>
                    <td *ngIf="tutorial.status_paket == 'Y'">{{ tutorial.tgl_terima }}</td>
                    <td *ngIf="tutorial.status_paket == 'N'">-</td>
                    <!-- <td>{{ tutorial.tgl_terima }}</td> -->

                    <!-- <td *ngIf="tutorial.status_kurir == 'Y'">
                      {{ tutorial.nameshippers }}
                    </td>
                    <td *ngIf="tutorial.status_pickup == 'Y'">
                      {{ tutorial.name_pengirim }}
                    </td> -->
                    <td >
                      {{ tutorial.name_pengirim }}
                    </td>
                    <td >
                      {{ tutorial.full_name }}
                    </td>
                    <!-- <td *ngIf="tutorial.id_shippers == ''">{{ tutorial.name_pengirim }}</td>
                    <td *ngIf="tutorial.id_shippers != ''">{{ tutorial.nameshippers }}</td> -->

                    <!-- <td *ngIf="tutorial.status_inbound == '0'">Menunggu</td>
                    <td *ngIf="tutorial.status_inbound == '1'">Proses</td>
                    <td *ngIf="tutorial.status_inbound == '2'">Setuju</td>
                    <td *ngIf="tutorial.status_inbound == '3'">Batal</td> -->
                    <!-- <td>{{ tutorial.type_id }}</td> -->
                    <!-- <td *ngIf="isAdmin && tutorial.status_idpartner == 'Y'"> -->
                        <!-- <button *ngIf="tutorial.status_inbound == '0'" type="button" class="btn btn-rounded btn-warning" (click)="prosesInbound(tutorial)"><span
                          class="btn-icon-left text-warning"><i class="fa fa-circle-o-notch color-warning"></i>
                          </span>Proses</button>&nbsp;
                        <button *ngIf="tutorial.status_inbound == '1'" type="button" class="btn btn-rounded btn-success" (click)="setujuInbound(tutorial)"><span
                          class="btn-icon-left text-success"><i class="fa fa-check color-success"></i>
                          </span>Setuju</button>&nbsp;
                        <button *ngIf="tutorial.status_inbound == '0' || tutorial.status_inbound == '1'" type="button" class="btn btn-rounded btn-danger" (click)="tolakInbound(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-close color-danger"></i>
                          </span>Tolak</button>&nbsp; -->
                    <!-- </td> -->
                   <td *ngIf="isAdmin || isAgen">
                    <button type="button" class="btn btn-rounded btn-primary" (click)="editUser(sendMessageModal, tutorial, 'view')"><span
                      class="btn-icon-left text-primary"><i class="fa fa-eye color-primary"></i>
                      </span>Detail</button>&nbsp;
                      <button type="button" class="btn btn-rounded btn-info"
                        (click)="editUser(sendMessageModal, tutorial, 'update')"><span
                          class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                        </span>Edit</button>&nbsp;
                        <button type="button" class="btn btn-rounded btn-danger" (click)="deleteInbound(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button>&nbsp;
                    </td>
                    <td *ngIf="isSuperadmin">
                      <button type="button" class="btn btn-rounded btn-primary" (click)="editUser(sendMessageModal, tutorial, 'view')"><span
                        class="btn-icon-left text-primary"><i class="fa fa-eye color-primary"></i>
                        </span>Detail</button>&nbsp;
                        <!-- <button *ngIf="tutorial.status_inbound == '0'" type="button" class="btn btn-rounded btn-warning" (click)="prosesInbound(tutorial)"><span
                          class="btn-icon-left text-warning"><i class="fa fa-circle-o-notch color-warning"></i>
                          </span>Proses</button>&nbsp;
                        <button *ngIf="tutorial.status_inbound == '1'" type="button" class="btn btn-rounded btn-success" (click)="setujuInbound(tutorial)"><span
                          class="btn-icon-left text-success"><i class="fa fa-check color-success"></i>
                          </span>Setuju</button>&nbsp;
                        <button *ngIf="tutorial.status_inbound == '0' || tutorial.status_inbound == '1'" type="button" class="btn btn-rounded btn-danger" (click)="tolakInbound(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-close color-danger"></i>
                          </span>Tolak</button>&nbsp; -->
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination class="pagination-responsive"
                [collectionSize]="count"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              >
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select
                  class="mr-sm-2 custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="handlePageSizeChange($event)"
                >
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- AGEN -->
<!-- Modal ADD -->
<ng-template #sendAgenModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Inbound Agen/Mitra</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Scan Connote <span class="required">*</span></label>
            <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
              [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
              (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
          </div>
        </div>

        <!-- <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">ID <span class="required">*</span></label> -->
            <input
              type="hidden"
              class="form-control"
              id="id"
              required
              [(ngModel)]="id"
              #name="ngModel"
              name="id"
            />
          <!-- </div>
				</div> -->
        <div class="col-md-8">
          <label class="text-black font-w600">No Airwaybill/Connote <span class="required">*</span></label>
          <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
            <input
              type="text"
              class="form-control"
              id="no_resi"
              required
              [(ngModel)]="no_resi"
              #name="ngModel"
              name="no_resi"
            />
            <div class="input-group-append">
              <button class="btn" type="button" class="submit btn btn-primary" (click)="cariResi()">
                <i class="flaticon-381-search-2"></i> Cari
              </button>
            </div>
          </div>
          <!-- <button (click)="cariResi()" class="submit btn btn-primary">Cari</button> -->
        </div>

				<!-- <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No Resi <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="no_resi"
              required
              [(ngModel)]="no_resi"
              #name="ngModel"
              name="no_resi"
            />
          </div>
				</div> -->

        <!-- <div class="col-lg-6">
					<div class="form-group">
            <label class="text-black font-w600">&nbsp;</label>
            <button (click)="cariResi()" class="submit btn btn-primary">Cari</button>
					</div>
				</div> -->


				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Pengirim </label>
            <input
              type="text"
              class="form-control"
              id="nameshippers"
              required
              [(ngModel)]="nameshippers"
              #name="ngModel"
              [disabled]="true"
              name="nameshippers"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Paket diKirimkan </label>
            <input
              type="text"
              class="form-control"
              id="tgl_kirim"
              required
              [(ngModel)]="tgl_kirim"
              #name="ngModel"
              [disabled]="true"
              name="tgl_kirim"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Kurir</label>
            <input
              type="text"
              class="form-control"
              id="namakurirs"
              required
              [(ngModel)]="namakurirs"
              #name="ngModel"
              [disabled]="true"
              name="namakurirs"
            />
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Status Paket</label>
            <input
              type="text"
              class="form-control"
              id="status_paket"
              required
              [(ngModel)]="status_paket"
              #name="ngModel"
              [disabled]="true"
              name="status_paket"
            />
          </div>
				</div>

        <div class="col-lg-6" *ngIf="status_paket == 'Terima'">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Terima </label>
            <input
              type="text"
              class="form-control"
              id="tgl_terima"
              required
              [(ngModel)]="tgl_terima"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="tgl_terima"
            />
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Type Items</label>
            <input
              type="text"
              class="form-control"
              id="namaitems"
              required
              [(ngModel)]="namaitems"
              #name="ngModel"
              [disabled]="true"
              name="namaitems"
            />
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Sub Type Items</label>
            <input
              type="text"
              class="form-control"
              id="namasubitems"
              required
              [(ngModel)]="namasubitems"
              #name="ngModel"
              [disabled]="true"
              name="namasubitems"
            />
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Berat Paket (KG)</label>
            <input
              type="text"
              class="form-control"
              id="berat_paket"
              required
              [(ngModel)]="berat_paket"
              #name="ngModel"
              [disabled]="true"
              name="berat_paket"
            />
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Keterangan</label>
            <textarea class="form-control" id="description" rows="5" [(ngModel)]="description" formControlName="description"
                  placeholder="Keterangan"></textarea>
          </div>
				</div>


				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <!-- <button (click)="saveAgenmitras()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
            <ng-container *ngIf="(isLoading$) === false">
              <span class="indicator-label">
                <!-- <button (click)="saveInbs()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
                <button (click)="saveAgenmitras()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
              </span>
            </ng-container>
            <ng-container *ngIf="isLoading$">
              <span class="indicator-progress" [style.display]="'block'">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<!-- START KURIR SCAN -->
<!-- Modal ADD -->
<ng-template #sendAgenModalKurir let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Inbound Kurir</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Scan Connote <span class="required">*</span></label>
            <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult1($event)"
              [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
              (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
          </div>
        </div>


				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No Resi <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="no_resi"
              required
              [(ngModel)]="no_resi"
              #name="ngModel"
              name="no_resi"
            />
          </div>
				</div>

				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Pengirim </label>
            <input
              type="text"
              class="form-control"
              id="name_pengirim"
              required
              [(ngModel)]="name_pengirim"
              #name="ngModel"
              name="name_pengirim"
            />
          </div>
				</div>
				<!-- <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Paket diKirimkan </label>
            <input type="date" class="form-control" id="tgl_kirim" formControlName="tgl_kirim"  [(ngModel)]="tgl_kirim"
                placeholder="Enter a tanggal kirim..">
          </div>
				</div> -->
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Kurir</label>
            <select class="form-control default-select" *ngIf="vcomboskurir.length > 0" id="id_kurirs" [(ngModel)]="id_kurirs" name="id_kurirs">
              <option value="">Please select</option>
              <option *ngFor="let n of vcomboskurir" value="{{n.id}}">{{n.name}}</option>
            </select>
          </div>
				</div>

        <!-- <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Status Paket</label>
            <input
              type="text"
              class="form-control"
              id="status_paket"
              required
              [(ngModel)]="status_paket"
              #name="ngModel"
              [disabled]="true"
              name="status_paket"
            />
          </div>
				</div> -->

        <!-- <div class="col-lg-6" *ngIf="status_paket == 'Terima'">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Terima </label>
            <input
              type="text"
              class="form-control"
              id="tgl_terima"
              required
              [(ngModel)]="tgl_terima"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="tgl_terima"
            />
          </div>
				</div> -->

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Type Items</label>
            <p-dropdown
                  [options]="typeItems"
                  optionLabel="name"
                  [filter]="true"
                  [disabled]="action == 'view'"
                  filterBy="name"
                  [showClear]="true"
                  styleClass="w-100"
                  [(ngModel)]="selectedTypeItem"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="Select a Type"
                  (onChange)="onDropdownChange($event, 'type_id')"
                  (onClear)="onDropdownClear('type_id')"
                >
                  <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedTypeItem">
                      <div>{{ selectedTypeItem.name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="">
                      <div>{{ item.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Sub Type Items</label>
            <p-dropdown
                  [options]="subtypeItems"
                  optionLabel="name"
                  [filter]="true"
                  filterBy="name"
                  [disabled]="action == 'view'"
                  [showClear]="true"
                  styleClass="w-100"
                  [(ngModel)]="selectedSubTypeItem"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="Select a Type"
                  (onChange)="onDropdownChange($event, 'subtype_id')"
                  (onClear)="onDropdownClear('subtype_id')"
                >
                  <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedSubTypeItem">
                      <div>{{ selectedSubTypeItem.name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="">
                      <div>{{ item.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Berat Paket (KG)</label>
            <input
              type="text"
              class="form-control"
              id="berat_paket"
              required
              [(ngModel)]="berat_paket"
              #name="ngModel"
              name="berat_paket"
            />
          </div>
				</div>

        

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Keterangan</label>
            <textarea class="form-control" id="description" rows="5" [(ngModel)]="description" name="description"
                  placeholder="Keterangan"></textarea>
          </div>
				</div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Upload Foto Paket</label>
              <input type="file" class="form-control" name="fileimage" id="fileimage" (change)="onFileSelected($event)">
              <input type="file" class="form-control" name="fileimage1" id="fileimage1" (change)="onFileSelected1($event)">
              <input type="file" class="form-control" name="fileimage2" id="fileimage2" (change)="onFileSelected2($event)">
              <input type="file" class="form-control" name="fileimage3" id="fileimage3" (change)="onFileSelected3($event)">
          </div>
				</div>


				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <!-- <button (click)="saveBySCAN()" [disabled]="name.invalid" class="submit btn btn-primary">
              {{isCreated ? 'Submit':'Update'}}
            </button> -->
             <!-- <button (click)="saveBySCAN()" [disabled]="name.invalid" class="submit btn btn-primary"> -->
            <ng-container *ngIf="(isLoading$) === false">
							<span class="indicator-label">
                <button (click)="saveBySCAN()" [disabled]="name.invalid" class="submit btn btn-primary">
                  {{isCreated ? 'Submit':'Update'}}
                </button>
              </span>
						</ng-container>
						<ng-container *ngIf="isLoading$">
							<span class="indicator-progress" [style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
            <!-- </button>  -->
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<!-- END KURIR -->

<!-- START KONFIRMASI TERIMA SCAN -->
<!-- Modal ADD -->
<ng-template #sendAgenModalKurir1 let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Konfirmasi Terima Paket</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Scan Resi <span class="required">*</span></label>
            <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult1($event)"
              [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
              (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
          </div>
        </div>


				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No Resi <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="no_resi"
              required
              [(ngModel)]="no_resi"
              #name="ngModel"
              name="no_resi"
            />
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
             <ng-container *ngIf="(isLoading$) === false">
							<span class="indicator-label">
                <button (click)="saveBySCANKONFIRS()" [disabled]="name.invalid" class="submit btn btn-primary">
                  {{isCreated ? 'Submit':'Update'}}
                </button>
              </span>
						</ng-container>
						<ng-container *ngIf="isLoading$">
							<span class="indicator-progress" [style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
            <!-- </button>  -->
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<!-- END KURIR -->

<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Inbound</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- START FORM -->
    <div class="form-validation">
      <form class="form-valide" action="#" method="post" [formGroup]="angForm" novalidate>
        <div class="row">
          <div class="col-xl-12">

            <div class="form-group row">
              <label class="col-lg-12 col-form-label" for="name">Pilih Datang darinya paket
                <!-- <span class="text-danger">*</span> -->
              </label>
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-md-4 col-6">
                      <div class="custom-control custom-checkbox mb-3">
                          <input type="checkbox" class="custom-control-input" id="status_kurir" formControlName="status_kurir" [(ngModel)]="status_kurir">
                          <label class="custom-control-label" for="status_kurir">Kurir</label>
                          <div
                            *ngIf="angForm.controls['status_kurir'].invalid && (angForm.controls['status_kurir'].dirty || angForm.controls['status_kurir'].touched)"
                            class="text-danger fs-13 mt-1">
                            <div *ngIf="angForm.controls['status_kurir'].errors.required">
                              status_kurir is required.
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4 col-6">
                      <div class="custom-control custom-checkbox mb-3">
                          <input type="checkbox" class="custom-control-input" id="status_pickup" formControlName="status_pickup" [(ngModel)]="status_pickup">
                          <label class="custom-control-label" for="status_pickup">Pickup</label>
                          <div
                            *ngIf="angForm.controls['status_pickup'].invalid && (angForm.controls['status_pickup'].dirty || angForm.controls['status_pickup'].touched)"
                            class="text-danger fs-13 mt-1">
                            <div *ngIf="angForm.controls['status_pickup'].errors.required">
                              status_pickup is required.
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4 col-6">
                      <div class="custom-control custom-checkbox mb-3">
                          <input type="checkbox" class="custom-control-input" id="status_direct" formControlName="status_direct" [(ngModel)]="status_direct">
                          <label class="custom-control-label" for="status_direct">Direct</label>
                          <div
                            *ngIf="angForm.controls['status_direct'].invalid && (angForm.controls['status_direct'].dirty || angForm.controls['status_direct'].touched)"
                            class="text-danger fs-13 mt-1">
                            <div *ngIf="angForm.controls['status_direct'].errors.required">
                              status_direct is required.
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_kurir">
              <label class="form-label col-lg-4" for="no_resi">Resi Paket</label>
              <div class="input-group col-lg-8">
                <input type="text" class="form-control" id="no_resi" [(ngModel)]="no_resi" formControlName="no_resi"
                placeholder="Resi Paket..">
              </div>
              <div
                *ngIf="angForm.controls['no_resi'].invalid && (angForm.controls['no_resi'].dirty || angForm.controls['no_resi'].touched)"
                class="text-danger fs-13 mt-1">
                <div *ngIf="angForm.controls['no_resi'].errors.required">
                  Resi Paket is required.
                </div>
              </div>
            </div>

            

            <div class="form-group row" *ngIf="status_kurir">
              <label class="form-label col-lg-4" for="id_shippers">Pengirim</label>
              <div class="input-group col-lg-8">
                 <select class="form-control default-select" *ngIf="vcombos.length > 0" id="id_shippers" [(ngModel)]="id_shippers" name="id_shippers" formControlName="id_shippers">
                  <option value="">Please select</option>
                  <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
                </select>
              </div>
              <div
                *ngIf="angForm.controls['id_shippers'].invalid && (angForm.controls['id_shippers'].dirty || angForm.controls['id_shippers'].touched)"
                class="text-danger fs-13 mt-1">
                <div *ngIf="angForm.controls['id_shippers'].errors.required">
                  Shipper is required.
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_pickup || status_direct || status_kurir">
              <label class="form-label col-lg-4" for="no_resi">Pengirim</label>
              <div class="input-group col-lg-8">
                <input type="text" class="form-control" id="name_pengirim" [(ngModel)]="name_pengirim" formControlName="name_pengirim"
                placeholder="Pengirim..">
              </div>
              <div
                *ngIf="angForm.controls['name_pengirim'].invalid && (angForm.controls['name_pengirim'].dirty || angForm.controls['name_pengirim'].touched)"
                class="text-danger fs-13 mt-1">
                <div *ngIf="angForm.controls['name_pengirim'].errors.required">
                  Pengirim is required.
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_kurir">
              <label class="form-label col-lg-4" for="tgl_kirim">Tanggal Paket di Kirimkan<span class="text-danger">*</span></label>
              <div class="input-group col-lg-8">
                <input type="date" class="form-control" id="tgl_kirim" formControlName="tgl_kirim"  [(ngModel)]="tgl_kirim"
                placeholder="Enter a tanggal kirim..">
              </div>
              <div
                *ngIf="angForm.controls['tgl_kirim'].invalid && (angForm.controls['tgl_kirim'].dirty || angForm.controls['tgl_kirim'].touched)"
                class="text-danger fs-13 mt-1">
                <div *ngIf="angForm.controls['tgl_kirim'].errors.required">
                  Tanggal Kirim is required.
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_kurir">
              <label class="form-label col-lg-4" for="id_kurirs">Kurir</label>
              <div class="input-group col-lg-8">
                 <select class="form-control default-select" *ngIf="vcomboskurir.length > 0" id="id_kurirs" [(ngModel)]="id_kurirs" name="id_kurirs" formControlName="id_kurirs">
                  <option value="">Please select</option>
                  <option *ngFor="let n of vcomboskurir" value="{{n.id}}">{{n.name}}</option>
                </select>
              </div>
              <div
                *ngIf="angForm.controls['id_kurirs'].invalid && (angForm.controls['id_kurirs'].dirty || angForm.controls['id_kurirs'].touched)"
                class="text-danger fs-13 mt-1">
                <div *ngIf="angForm.controls['id_kurirs'].errors.required">
                  Kurir is required.
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_kurir">
              <label class="col-lg-4 col-form-label" for="status_paket">Status Paket
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <select class="form-control default-select" id="status_paket" formControlName="status_paket" [(ngModel)]="status_paket">
                  <option value="">Please select</option>
                  <option value="N">Belum diterima</option>
                  <option value="Y">Diterima</option>
                </select>
                <div
                  *ngIf="angForm.controls['status_paket'].invalid && (angForm.controls['status_paket'].dirty || angForm.controls['status_paket'].touched)"
                  class="text-danger fs-13 mt-1">

                  <div *ngIf="angForm.controls['status_paket'].errors.required">
                    Select Status Paket.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_kurir || status_pickup || status_direct || status_agen">
              <label class="form-label col-lg-4" for="tgl_terima">Tanggal Terima</label>
              <div class="input-group col-lg-8">
                <input type="date" class="form-control" id="tgl_terima" [(ngModel)]="tgl_terima" formControlName="tgl_terima"
                placeholder="Enter a tanggal Terima..">
              </div>
              <div
                *ngIf="angForm.controls['tgl_terima'].invalid && (angForm.controls['tgl_terima'].dirty || angForm.controls['tgl_terima'].touched)"
                class="text-danger fs-13 mt-1">
                <div *ngIf="angForm.controls['tgl_terima'].errors.required">
                  Tanggal Terima is required.
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_kurir || status_pickup || status_direct">
              <label class="col-lg-4 col-form-label" for="type_id">Type Items
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <p-dropdown
                  [options]="typeItems"
                  optionLabel="name"
                  [filter]="true"
                  [disabled]="action == 'view'"
                  filterBy="name"
                  [showClear]="true"
                  styleClass="w-100"
                  [(ngModel)]="selectedTypeItem"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="Select a Type"
                  (onChange)="onDropdownChange($event, 'type_id')"
                  (onClear)="onDropdownClear('type_id')"
                >
                  <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedTypeItem">
                      <div>{{ selectedTypeItem.name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="">
                      <div>{{ item.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <div
                  *ngIf="angForm.controls['type_id'].invalid && (angForm.controls['type_id'].dirty || angForm.controls['type_id'].touched)"
                  class="text-danger fs-13 mt-1">

                  <div *ngIf="angForm.controls['type_id'].errors.required">
                    Select Type.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="status_kurir || status_pickup || status_direct">
              <label class="col-lg-4 col-form-label" for="subtype_id">SubType Item
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <p-dropdown
                  [options]="subtypeItems"
                  optionLabel="name"
                  [filter]="true"
                  filterBy="name"
                  [disabled]="action == 'view'"
                  [showClear]="true"
                  styleClass="w-100"
                  [(ngModel)]="selectedSubTypeItem"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="Select a Type"
                  (onChange)="onDropdownChange($event, 'subtype_id')"
                  (onClear)="onDropdownClear('subtype_id')"
                >
                  <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedSubTypeItem">
                      <div>{{ selectedSubTypeItem.name }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="">
                      <div>{{ item.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <div
                  *ngIf="angForm.controls['subtype_id'].invalid && (angForm.controls['subtype_id'].dirty || angForm.controls['subtype_id'].touched)"
                  class="text-danger fs-13 mt-1">

                  <div *ngIf="angForm.controls['subtype_id'].errors.required">
                    Select Sub Type.
                  </div>
                </div>
              </div>
            </div>




              <div class="form-group row" *ngIf="status_kurir || status_pickup || status_direct">
                <label class="form-label col-lg-4" for="berat_paket">Berat Paket</label>
                <div class="input-group col-lg-8">
                  <input type="text" class="form-control" id="berat_paket" [(ngModel)]="berat_paket" formControlName="berat_paket"
                  placeholder="Enter a Berat Paket..">
                </div>
                <div
                  *ngIf="angForm.controls['berat_paket'].invalid && (angForm.controls['berat_paket'].dirty || angForm.controls['berat_paket'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['berat_paket'].errors.required">
                    Berat Paket is required.
                  </div>
                </div>
              </div>

            <div class="form-group row" *ngIf="status_kurir || status_pickup || status_direct">
              <label class="col-lg-4 col-form-label">Upload Foto Paket
              </label>
              <div class="col-lg-8" *ngIf="fadd_role.action != 'view'">
                  <input type="file" class="form-control" name="fileimage" id="fileimage" (change)="onFileSelected($event)">
                  <input type="file" class="form-control" name="fileimage1" id="fileimage1" (change)="onFileSelected1($event)">
                  <input type="file" class="form-control" name="fileimage2" id="fileimage2" (change)="onFileSelected2($event)">
                  <input type="file" class="form-control" name="fileimage3" id="fileimage3" (change)="onFileSelected3($event)">
              </div>
              <!-- <div class="col-lg-8" *ngIf="fadd_role.action == 'view'"> -->
              <div class="col-lg-8" *ngIf="!isCreated">
                <img src="{{imagess}}" alt="" width="100">
                <img src="{{imagess1}}" alt="" width="100">
                <img src="{{imagess2}}" alt="" width="100">
                <img src="{{imagess3}}" alt="" width="100">
              </div>
            </div>

            <!-- <div class="form-group row" >
              <label class="col-lg-4 col-form-label">Upload Foto Paket
              </label>

            </div> -->

            <div class="form-group row" *ngIf="status_kurir || status_pickup || status_direct">
              <label class="col-lg-12 col-form-label" for="description">Keterangan <span class="text-danger">*</span>
              </label>
              <div class="col-lg-12">
                <textarea class="form-control" id="description" rows="5" [(ngModel)]="description" formControlName="description"
                  placeholder="Keterangan"></textarea>
                <div
                  *ngIf="angForm.controls['description'].invalid && (angForm.controls['description'].dirty || angForm.controls['description'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['description'].errors.required">
                    Keterangan is required.
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group row">
              <div class="col-lg-8 ml-auto" *ngIf="fadd_role.action != 'view'">
                  <!-- <button (click)="saveInbs()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
                  <ng-container *ngIf="(isLoading$) === false">
                    <span class="indicator-label">
                      <button (click)="saveInbs()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="isLoading$">
                    <span class="indicator-progress" [style.display]="'block'">
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- END FORM -->
  </div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
