import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SelectItem, PrimeNGConfig } from "primeng/api";

import * as XLSX from 'xlsx';



export interface Tutorial {
  id?: any;
  code_resi?: string,
  code?: string,
  name?: string,
  phonecode?: string,
  phone_number?: string,
  address?: string,
  template_option?: string,
  fileimage?: string,
  event?: string,
  state?: any;
  // published?: boolean;
  action?: string
}


@Component({
  selector: 'app-mst-shippers',
  templateUrl: './mst-shippers.component.html',
  styleUrls: ['./mst-shippers.component.css']
})
export class MstShippersComponent implements OnInit {
  //   imageSrc: string = '';
  //   myForm = new FormGroup({
  //    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  //    file: new FormControl('', [Validators.required]),
  //    fileSource: new FormControl('', [Validators.required])
  //  });

  image: File | undefined;
  resData: any;
  selectedFile: any;
  //  selectedFile = null;

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated: boolean = true;

  code_resi: any;
  code: any;
  name: any;
  phone_number: any;
  address: any;
  template_option: any;
  // fileimage: any;
  id: any;

  type_id: any;

  purc_price: any;
  sell_price: any;
  weight: any;

  countries: any[] = [];
  selectedShipperPhoneNumberCountry: any = {};

  action: any = '';
  phonecode: any;

  typeItems: any[] = [];
  selectedTypeItem: any | undefined;

  cities: any[] = [];

  selectedCities: any[] | undefined;

  ngOnInit(): void {
    this.retrieveTutorials();
    this.fetchDropdownValues();

    this.retrieveCombodatatypeItems();



    this.primengConfig.ripple = true;
  }

  // event: any;
  angForm: FormGroup | any;
  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig
  ) {
    this.createForm();

    this.cities = [
      { name: 'New York', code: 'NY', inactive: false },
      { name: 'Rome', code: 'RM', inactive: true },
      { name: 'London', code: 'LDN', inactive: false },
      { name: 'Istanbul', code: 'IST', inactive: true },
      { name: 'Paris', code: 'PRS', inactive: false }
    ];
  }


  createForm() {
    this.angForm = this.fb.group({
      code_resi: ['', [Validators.required]],
      code: ['', [Validators.required]],
      name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      address: ['', [Validators.required]],
      template_option: ['', [Validators.required]]
    });

    // console.log('masuk')

  }

  open(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.id = null;
    this.code_resi = null;
    this.code = null;
    this.name = null;
    this.phonecode = null;
    this.phone_number = null;
    this.address = null;
    this.template_option = null;
    this.selectedShipperPhoneNumberCountry = {};
    this.modalService.open(content);
  }

  openimport(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    // this.shipper_id = '';
    // this.ship_date = '';
    // this.vendor_id = '';
    this.modalService.open(content, { size: 'lg' });
    // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  opensethargashipper(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    // this.shipper_id = '';
    // this.status = '';

    // this.ship_date = '';
    // this.vendor_id = '';
    this.modalService.open(content);
    // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  // START
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

  onSubmit() {
    // console.log('masuk sini')
    const payload = new FormData();
    payload.append('code_resi', this.code_resi);
    payload.append('code', this.code);
    payload.append('fileimage', this.selectedFile);
    // console.log(this.code, this.name, 'this.code, this.name')

    this.tutorialService.postData('shippers/create', payload)
      .subscribe(res => {
        // console.log(res);
        alert('Uploaded Successfully.');
      })
    // this.http
    //   .post(`https://srinu.org/Api.php?apicall=upload_sub_cat`, payload, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //   .subscribe((data: any) => {
    //     this.resData = data;
    //     console.log(this.resData);
    //   });
  }

  // get f(){
  //   return this.myForm.controls;
  // }

  // onFileChange(event:any) {
  //   const reader = new FileReader();

  //   if(event.target.files && event.target.files.length) {
  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {

  //       this.imageSrc = reader.result as string;

  //       this.myForm.patchValue({
  //         fileSource: reader.result
  //       });

  //     };

  //   }
  //   console.log(reader)
  // }

  // submit(){
  //   console.log(this.myForm.value);
  //   const formData: FormData = new FormData();
  //   formData.append('fileimage', this.myForm.value.file);
  //   formData.append('name', this.myForm.value.name);
  //   console.log(formData)
  //   // this.http.post('http://localhost:8001/upload.php', this.myForm.value)
  //   this.tutorialService.postData('shippers/create', formData)
  //     .subscribe(res => {
  //       console.log(res);
  //       alert('Uploaded Successfully.');
  //     })
  // }
  // END

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('shippers/view', params)
      .subscribe(
        response => {
          const { data, totalItems } = response;
          this.tutorials = data;
          this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  fetchDropdownValues() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // console.log(params, 'params')
    this.tutorialService.getcombo(`country/combodata`, params).subscribe((response: any) => {
      this.countries = response.data;
      // console.log(this.countries, 'ss')
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    code_resi: '',
    code: '',
    name: '',
    phonecode: '',
    phone_number: '',
    address: '',
    template_option: '',
    fileimage: '',
    // published: false
  };

  submitted!: boolean;
  saveTutorial(): void {
    const data = {
      code_resi: this.code_resi,
      code: this.code,
      name: this.name,
      phonecode: this.selectedShipperPhoneNumberCountry,
      phone_number: this.phone_number,
      address: this.address,
      template_option: this.template_option,
      // fileimage: this.fadd_role.fileimage,
    };

    if (this.isCreated) {
      // this.code = this.code;
      // this.name= this.name;
      // this.phone_number= this.phone_number;
      // this.address= this.address;
      // this.template_option= this.template_option;
      // this.fileimage= this.fadd_role.fileimage;
      //   const payload = new FormData();
      // payload.append('code', this.code);
      // payload.append('fileimage', this.selectedFile);
      // const phonecode = data.phonecode.phonecode
      // console.log(this.selectedShipperPhoneNumberCountry.phonecode, 'datacreate phonecode name')

      const formData: FormData = new FormData();
      formData.append('code_resi', this.code_resi);
      formData.append('code', this.code);
      formData.append('name', this.name);
      formData.append('phonecode', this.selectedShipperPhoneNumberCountry.phonecode);
      formData.append('phone_number', this.phone_number);
      formData.append('address', this.address);
      formData.append('template_option', this.template_option);
      formData.append('fileimage', this.selectedFile);
      // formData.append('fileimage', this.fileimage);
      // console.log(formData, 'data')
      // this.tutorialService.postDataI('shippers/create', formData)
      this.tutorialService.postData('shippers/create', formData)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      // console.log(data, 'data');
      const formData: FormData = new FormData();
      formData.append('code_resi', data.code_resi);
      formData.append('code', data.code);
      formData.append('name', data.name);
      formData.append('phonecode', this.selectedShipperPhoneNumberCountry.phonecode);
      // formData.append('phonecode', data.phonecode.phonecode);
      formData.append('phone_number', data.phone_number);
      formData.append('address', data.address);
      formData.append('template_option', data.template_option);
      formData.append('fileimage', this.selectedFile);


      this.tutorialService.postData('shippers/update/' + this.id, formData)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal: any, tutorial: any, action: any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.id = tutorial.id;
    this.code_resi = tutorial.code_resi;
    this.code = tutorial.code;
    this.name = tutorial.name;
    this.phonecode = tutorial.phonecode;
    this.phone_number = tutorial.phone_number;
    this.address = tutorial.address;
    this.template_option = tutorial.template_option;
    // this.fileimage = tutorial.fileimage;
    // console.log(this.countries)
    let selectedShipper = this.countries.find((e: any) => e.phonecode == this.phonecode);
    // console.log(selectedShipper)
    this.selectedShipperPhoneNumberCountry = selectedShipper;
    this.fadd_role.action = action;
  }

  deleteShippers(data: any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`shippers/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg: any, status: any) {
    // console.log(msg)
    if (status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  onDropdownChange(event: any, tipe: string) {
    // console.log(event.value)
    // if(tipe === 'shipper_phone') {
    this.selectedShipperPhoneNumberCountry = event.value;
    // console.log('selectedShipperPhoneNumberCountry',this.selectedShipperPhoneNumberCountry);
    // }
    // if(tipe === 'type_id') {
    // this.selectedTypeItem = event.value;
    // }
  }

  onDropdownClear(tipe: string) {
    // if(tipe === 'shipper_phone') {
    this.selectedShipperPhoneNumberCountry = {};
    // }
  }

  checkUncheckAll(event: any) {
    var checkboxes = document.getElementsByTagName('input');
    if (event.target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        // console.log(i, '1')
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = true;
        }
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        // console.log(i, '2')
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
    }
  }

  checkAll(ev: any) {
    this.tutorials.forEach(x => x.state = ev.target.checked)
  }

  isAllChecked() {
    return this.tutorials.every(_ => _.state);
  }

  check(tutorial: any) {
    tutorial.state = !tutorial.state;
  }

  setsaveHarga() {
    // console.log(this.selectedCities, 'selectedCities')
    // console.log(this.selectedTypeItem, 'selectedTypeItem')
    this.tutorials.forEach(x => {
      if (x.state) {
        // const params = x;
        // console.log(x.id,'x')
        let params = {
          shippers_id: x.id,
          negara: this.selectedTypeItem,
          purc_price: this.purc_price,
          sell_price: this.sell_price,
          weight: this.weight,
        }
        // console.log(params, this.selectedTypeItem, 'type_id')
        // params.id = this.type_id;
        // params.sell_price = this.sell_price;
        // // params.manifest_date = this.manifest_date;
        // // console.log(x, this.status, 'x, this.status,');
        // // alert(x.tutorial);
        this.tutorialService.postData('shippers/setharganegara', params)
          .subscribe(
            async response => {
              // console.log(response);
              this.fadd_role = {};
              this.presentToast(response.message, 'success');
              this.submitted = true;
              this.modalService.dismissAll();
              this.retrieveTutorials();
            },
            async error => {
              // console.log(error);
              this.presentToast(error.error.errors, 'error');
            });
      }
    });
  }

  retrieveCombodatatypeItems(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('country/combodata', params)
      .subscribe(
        response => {
          const { data } = response;
          this.typeItems = data;
          // console.log(this.typeItems, 'tset')
          // this.fetchSubItem(this.typeItems.id);
          // console.log(this.vcombo, 'this.vcombo');
          // this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  file: any;
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  Downloadtemps() {
    var anchor = document.createElement('a');
    anchor.href = 'https://api.siger.co.id/uploads/template/template_import_harga.xlsx';
    // anchor.href = 'http://localhost:7777/uploads/template/template_import_cleansings.xlsx';
    anchor.target = "_blank";
    anchor.click();
    this.presentToast('Download Template Success', 'success');
    // this.submitted = true;
    this.modalService.dismissAll();
    this.retrieveTutorials();
  }

  exceljsondata: any;
  arrayBuffer: any;
  Upload() {
    this.tutorials.forEach(x => {
      if (x.state) {

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.exceljsondata = XLSX.utils.sheet_to_json(worksheet, { raw: true, defval: "" });

          let senddata = this.exceljsondata;
          let shippers_id = x.id;
          // console.log(senddata);
          this.tutorialService.postData('shippers/importexcelharga', {
            negara: senddata,
            shippers_id: shippers_id
          })
            .subscribe(
              async response => {
                // console.log(response);
                this.fadd_role = {};
                this.presentToast(response.message, 'success');
                this.submitted = true;
                this.modalService.dismissAll();
                this.retrieveTutorials();
              },
              async error => {
                // console.log(error);
                this.presentToast(error.error.errors, 'error');
              });
        }
        fileReader.readAsArrayBuffer(this.file);
      }
    })
  }

}
