import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import * as XLSX from 'xlsx';
import { Result, BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

// import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

@Component({
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Profile deletion</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="OnSubmit('no')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="OnSubmit('no')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="OnSubmit('Ok')">Ok</button>
  </div>
  `
})
export class NgbdModalConfirm {
  @Input() public data: any;
  constructor(public modal: NgbActiveModal) {
    // console.log(this.data)
  }

  OnSubmit(status:any) {
    this.modal.close(status);
  }
}

export interface Tutorial {
  run_categoryText?: any;
  createdAt?: any;
  paymentText?: any;
  fullname?: any;
  merchantCode?: any;
  statusMessage?: any;
  statusCode?: any;
  paymentUrl?: any;
  publisherOrderId?: any;
  reference?: any;
  status_bayar?: any;
  run_sizekaos?: any;
  total?: any;
  harga3_percent?: any;
  harga3?: any;
  harga2_percent?: any;
  harga2?: any;
  harga1?: any;
  id_events?: any;
  agree?: any;
  agrees?: any;
  run_runninggroup?: any;
  run_medicalcondition?: any;
  run_blood?: any;
  run_emergencyrel?: any;
  run_emergencyphone?: any;
  run_emergencyname?: any;
  run_organization?: any;
  run_occupation?: any;
  run_city?: any;
  run_nationality?: any;
  run_birth?: any;
  run_gender?: any;
  run_order_id?: any;
  id?: any;
  // shortname?: string,
  // name?: string,
  // phonecode?: string,
  run_idnumber?: string,
  run_category?: string,
  run_email?: string,
  run_firstname?:string,
  run_lastname?:string,
  run_namabip?:string,
  run_nobip?:string,
  run_nmpengambil?:string,
  run_phone?: string,
  run_nohppengambil?:string,
  ambilkaos?:string,
  run_kehadiran?:string,
  run_kehadiranText?:string,
  action?: string
}

const MODALS: {[name: string]: Type<any>} = {
  focusFirst: NgbdModalConfirm
};

@Component({
  selector: 'app-tr-peserta',
  templateUrl: './tr-peserta.component.html',
  styleUrls: ['./tr-peserta.component.css']
})
export class TrPesertaComponent implements OnInit {

  isLoading$ = false;

  tutorials: Tutorial[] = [];
  tutorialsTemp: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;

  id: any;
  shortname: any;
  name: any;
  phonecode: any;
  run_nobip:any;
  run_order_id:any;
  run_category:any;
  run_firstname:any;
  run_lastname:any;
  run_sizekaos:any;
  run_email: any;
  run_phone: any;
  run_namabip: any;
  // angForm: FormGroup | any;
  // value!: any;
  // vcombo: any = [];
  file: any;
  exceljsondata: any;
  arrayBuffer: any;

   // START SCAN
   //scan
   availableDevices!: MediaDeviceInfo[];
   currentDevice!: MediaDeviceInfo;

   formatsEnabled: BarcodeFormat[] = [
     BarcodeFormat.CODE_128,
     BarcodeFormat.DATA_MATRIX,
     BarcodeFormat.EAN_13,
     BarcodeFormat.QR_CODE,
   ];

   hasDevices!: boolean;
   hasPermission!: boolean;

   qrResultString!: string;

   torchEnabled = false;
   torchAvailable$ = new BehaviorSubject<boolean>(false);
   tryHarder = false;
  // END SCAN

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    // private fb: FormBuilder
    ) {
      // this.createForm();
    }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.getUserData();
    // this.retrieveCombodata();
  }

  usersData:any = {};
	rolesString:any = '';
  isAdmin:boolean = false;
  isSuperadmin:boolean = false;
  // isCustomer:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(res)
		  this.usersData.role.forEach((e:any) => {
			  this.rolesString = this.rolesString+e.name+', ';
		  });
			// console.log(this.rolesString)
      if(this.rolesString.includes('Admin')) {
        this.isAdmin = true;
      }
      if(this.rolesString.includes('Superadmin')) {
        this.isSuperadmin = true;
      }
			// console.log(this.isAdmin)
    })
  }


  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.fadd_role.id = '';
    this.fadd_role.run_idnumber = '';
    this.fadd_role.run_category = '';
    this.fadd_role.run_email = '';
    this.fadd_role.run_phone = '';
		this.modalService.open(content);
	}

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.type = 'pendaftaran';

    this.tutorialService.getAlls('pendaftaran/viewpendaftaran', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.tutorialsTemp = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  initializeItems(): void {
    this.tutorials = this.tutorialsTemp;
  }

  searchTerm: string = '';
  searchChanged(evt:any) {
    this.initializeItems();

    const searchTerm = evt;

    if (!searchTerm) {
      return;
    }

    this.tutorials = this.tutorials.filter(item => {
      // console.log(item, 'items')
      if (item.run_idnumber && item.run_phone && searchTerm) {
        if (item.run_idnumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          return true;
        } else if(item.run_phone.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1){
          return true;
        }
        return false;
      } else {
        return false;
      }
    });

  }


  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    id: '',
    run_idnumber: '',
    run_category: '',
    run_email: '',
    run_phone:'',

  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      id: this.fadd_role.id,
      run_idnumber: this.fadd_role.run_idnumber,
      run_category: this.fadd_role.run_category,
      run_email: this.fadd_role.run_email,
      run_phone: this.fadd_role.run_phone,
    };

    if(this.isCreated) {
      this.tutorialService.postData('country/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      // this.id = this.id
      // data.idtagihan = this.id
      // console.log(this.fadd_role.id, data, 'this.fadd_role.id, data')
      // this.tutorialService.postData('country/createbayar', data)
      this.tutorialService.postData('country/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response, 'response update');
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error, 'errors');
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  actionPembayaran(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    // console.log(tutorial, 'tes')
    this.id = tutorial.id;
    this.shortname = tutorial.shortname;
    this.name = tutorial.name;
    this.phonecode = tutorial.phonecode;
    this.fadd_role = tutorial;
    this.fadd_role.action = action;
  }

  showsPesertas(sendMessageModal:any, value: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal, { size: 'lg' });
    console.log(value,'valuedit')
    // this.fadd_role.id = tutorial.id;
    this.fadd_role.run_lastname = value.run_lastname;
    // this.fadd_role.id"= "1f2e3c43-318a-4e81-b550-77572f89b76a",
    this.fadd_role.run_order_id = value.run_order_id;
    this.fadd_role.run_category = value.run_category;
    this.fadd_role.run_email = value.run_email;
    this.fadd_role.run_phone = value.run_phone;
    // this.fadd_role.run_password = value.run_password
    this.fadd_role.run_firstname = value.run_firstname
    this.fadd_role.run_lastname = value.run_lastname
    this.fadd_role.run_gender = value.run_gender
    this.fadd_role.run_birth = value.run_birth
    this.fadd_role.run_idnumber = value.run_idnumber
    this.fadd_role.run_nationality = value.run_nationality
    this.fadd_role.run_city = value.run_city
    this.fadd_role.run_occupation = value.run_occupation
    this.fadd_role.run_organization = value.run_organization
    this.fadd_role.run_emergencyname = value.run_emergencyname
    this.fadd_role.run_emergencyphone = value.run_emergencyphone
    this.fadd_role.run_emergencyrel = value.run_emergencyrel
    this.fadd_role.run_blood = value.run_blood
    this.fadd_role.run_medicalcondition = value.run_medicalcondition
    this.fadd_role.run_runninggroup = value.run_runninggroup
    this.fadd_role.agrees = value.run_runninggroup
    this.fadd_role.agree = value.agree
    this.fadd_role.id_events = value.id_events
    this.fadd_role.harga1 = value.harga1
    this.fadd_role.harga2 = value.harga2
    this.fadd_role.harga2_percent = value.harga2_percent
    this.fadd_role.harga3 = value.harga3
    this.fadd_role.harga3_percent = value.harga3
    this.fadd_role.total = value.total
    this.fadd_role.run_sizekaos = value.run_sizekaos
    this.fadd_role.run_namabip = value.run_namabip
    this.fadd_role.run_nobip = value.run_nobip
   
    this.fadd_role.status_bayar = value.status_bayar
    this.fadd_role.reference = value.reference
    this.fadd_role.publisherOrderId = value.publisherOrderId
    this.fadd_role.paymentUrl = value.paymentUrl
    this.fadd_role.statusCode = value.statusCode
    this.fadd_role.statusMessage = value.statusMessage
    this.fadd_role.merchantCode = value.merchantCode
    this.fadd_role.fullname = value.fullname
    this.fadd_role.paymentText = value.paymentText
    this.fadd_role.createdAt = value.createdAt
    this.fadd_role.run_categoryText = value.run_categoryText
    this.fadd_role.ambilkaos = value.ambilkaos
    this.fadd_role.run_kehadiranText = value.run_kehadiranText
    this.fadd_role.action = action;
  }

  showsPembayarans(sendMessagebayarModal:any, value: any, action:any) {
    this.isCreated = true;
    this.modalService.open(sendMessagebayarModal, { size: 'lg' });
    // console.log(value,'valuedit')
    // this.fadd_role.id = tutorial.id;
    this.fadd_role.id = value.id;
    this.fadd_role.run_lastname = value.run_lastname;
    // this.fadd_role.id"= "1f2e3c43-318a-4e81-b550-77572f89b76a",
    this.fadd_role.run_order_id = value.run_order_id;
    this.fadd_role.run_category = value.run_category;
    this.fadd_role.run_email = value.run_email;
    this.fadd_role.run_phone = value.run_phone;
    // this.fadd_role.run_password = value.run_password
    this.fadd_role.run_firstname = value.run_firstname
    this.fadd_role.run_lastname = value.run_lastname
    this.fadd_role.run_gender = value.run_gender
    this.fadd_role.run_birth = value.run_birth
    this.fadd_role.run_idnumber = value.run_idnumber
    this.fadd_role.run_nationality = value.run_nationality
    this.fadd_role.run_city = value.run_city
    this.fadd_role.run_occupation = value.run_occupation
    this.fadd_role.run_organization = value.run_organization
    this.fadd_role.run_emergencyname = value.run_emergencyname
    this.fadd_role.run_emergencyphone = value.run_emergencyphone
    this.fadd_role.run_emergencyrel = value.run_emergencyrel
    this.fadd_role.run_blood = value.run_blood
    this.fadd_role.run_medicalcondition = value.run_medicalcondition
    this.fadd_role.run_runninggroup = value.run_runninggroup
    this.fadd_role.agrees = value.run_runninggroup
    this.fadd_role.agree = value.agree
    this.fadd_role.id_events = value.id_events
    this.fadd_role.harga1 = value.harga1
    this.fadd_role.harga2 = value.harga2
    this.fadd_role.harga2_percent = value.harga2_percent
    this.fadd_role.harga3 = value.harga3
    this.fadd_role.harga3_percent = value.harga3
    this.fadd_role.total = value.total
    this.fadd_role.run_sizekaos = value.run_sizekaos
    this.fadd_role.run_namabip = value.run_namabip
    this.fadd_role.run_nobip = value.run_nobip
   
    this.fadd_role.status_bayar = value.status_bayar
    this.fadd_role.reference = value.reference
    this.fadd_role.publisherOrderId = value.publisherOrderId
    this.fadd_role.paymentUrl = value.paymentUrl
    this.fadd_role.statusCode = value.statusCode
    this.fadd_role.statusMessage = value.statusMessage
    this.fadd_role.merchantCode = value.merchantCode
    this.fadd_role.fullname = value.fullname
    this.fadd_role.paymentText = value.paymentText
    this.fadd_role.createdAt = value.createdAt
    this.fadd_role.run_categoryText = value.run_categoryText
    this.fadd_role.ambilkaos = value.ambilkaos
    this.fadd_role.run_kehadiranText = value.run_kehadiranText
    this.fadd_role.action = action;
  }

  setNomorbip(sendPesertaBIPModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendPesertaBIPModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.run_nobip = tutorial.run_nobip;
    this.fadd_role.run_sizekaos = tutorial.run_sizekaos;
    this.fadd_role.run_namabip = tutorial.run_namabip;
    this.fadd_role.run_idnumber = tutorial.run_idnumber;
    this.fadd_role.run_category = tutorial.run_category;
    this.fadd_role.run_email = tutorial.run_email;
    this.fadd_role.run_phone = tutorial.run_phone;
    this.fadd_role.run_gender = tutorial.run_gender;
    // this.fadd_role.manifest_date = tutorial.manifest_date;
    // this.fadd_role.invoice_no = tutorial.invoice_no;
    // this.fadd_role.mawb = tutorial.mawb;
    // this.fadd_role.flight_number = tutorial.flight_number;
    // this.fadd_role.flight_id = tutorial.flight_id;
    // this.fadd_role.vendor_id = tutorial.vendor_id;
    // this.fadd_role.note = tutorial.note;
    // this.fadd_role.status = tutorial.status;
    this.fadd_role.action = action;
  }

  saveNobip(): void {
    const data = {
      run_nobip: this.fadd_role.run_nobip,
      run_sizekaos: this.fadd_role.run_sizekaos,
      run_namabip: this.fadd_role.run_namabip,
      run_idnumber: this.fadd_role.run_idnumber,
      run_category: this.fadd_role.run_category,
      run_email: this.fadd_role.run_email,
      run_phone: this.fadd_role.run_phone,
      run_gender: this.fadd_role.run_gender,
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('pendaftaran/setupdatenobip/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  setKaos(sendPesertaBIPModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendPesertaBIPModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.run_nmpengambil = tutorial.run_nmpengambil;
    this.fadd_role.run_nohppengambil = tutorial.run_nohppengambil;
    // this.fadd_role.manifest_date = tutorial.manifest_date;
    // this.fadd_role.invoice_no = tutorial.invoice_no;
    // this.fadd_role.mawb = tutorial.mawb;
    // this.fadd_role.flight_number = tutorial.flight_number;
    // this.fadd_role.flight_id = tutorial.flight_id;
    // this.fadd_role.vendor_id = tutorial.vendor_id;
    // this.fadd_role.note = tutorial.note;
    // this.fadd_role.status = tutorial.status;
    this.fadd_role.action = action;
  }

  saveKaos(): void {
    const data = {
      run_nmpengambil: this.fadd_role.run_nmpengambil,
      run_nohppengambil: this.fadd_role.run_nohppengambil
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('pendaftaran/setupdatekaos/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  // setKehadiran(sendPesertaBIPModal:any, tutorial: any, action:any) {
  //   this.isCreated = false;
  //   this.modalService.open(sendPesertaBIPModal);
  //   this.fadd_role.id = tutorial.id;
  //   this.fadd_role.run_nobip = tutorial.run_nobip;
  //   // this.fadd_role.manifest_date = tutorial.manifest_date;
  //   // this.fadd_role.invoice_no = tutorial.invoice_no;
  //   // this.fadd_role.mawb = tutorial.mawb;
  //   // this.fadd_role.flight_number = tutorial.flight_number;
  //   // this.fadd_role.flight_id = tutorial.flight_id;
  //   // this.fadd_role.vendor_id = tutorial.vendor_id;
  //   // this.fadd_role.note = tutorial.note;
  //   // this.fadd_role.status = tutorial.status;
  //   this.fadd_role.action = action;
  // }

  saveKehadiran(): void {
    const data = {
      run_nobip: this.fadd_role.run_nobip,
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('pendaftaran/setupdatenobip/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }
  setBayar(sendPesertaBIPModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendPesertaBIPModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.run_nobip = tutorial.run_nobip;
    // this.fadd_role.manifest_date = tutorial.manifest_date;
    // this.fadd_role.invoice_no = tutorial.invoice_no;
    // this.fadd_role.mawb = tutorial.mawb;
    // this.fadd_role.flight_number = tutorial.flight_number;
    // this.fadd_role.flight_id = tutorial.flight_id;
    // this.fadd_role.vendor_id = tutorial.vendor_id;
    // this.fadd_role.note = tutorial.note;
    // this.fadd_role.status = tutorial.status;
    this.fadd_role.action = action;
  }

  saveBayar(): void {
    const data = {
      run_order_id: this.fadd_role.run_order_id,
      run_category: this.fadd_role.run_category,
      total: this.fadd_role.total,
      run_firstname: this.fadd_role.run_firstname,
      run_lastname: this.fadd_role.run_lastname,
      run_email: this.fadd_role.run_email,
      run_phone: this.fadd_role.run_phone,
      harga1: this.fadd_role.harga1,
      harga2: this.fadd_role.harga2,
      harga3: this.fadd_role.harga3,
      run_idnumber: this.fadd_role.run_idnumber,
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('pendaftaran/generatebayar/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            console.log(response,'s');

            // window.location.href = response.url;

            var anchor = document.createElement('a');
            // anchor.href = 'http://localhost/report/welcome/laporan/'+tutorial.id;
            anchor.href = response.url;
            anchor.target="_blank";
            anchor.click();
            // this.router.navigate(['/admin/app-tr-cleansings']);

          //   {
          //     "errors": false,
          //     "message": "berhasil",
          //     "resp": {
          //         "merchantCode": "DS18135",
          //         "reference": "DS1813524EPDECZG2ZJF4JVU",
          //         "paymentUrl": "https://app-sandbox.duitku.com/redirect_checkout?reference=DS1813524EPDECZG2ZJF4JVU",
          //         "amount": "260000.00",
          //         "statusCode": "00",
          //         "statusMessage": "SUCCESS"
          //     },
          //     "token": "DS1813524EPDECZG2ZJF4JVU",
          //     "merchantCode": "DS18135",
          //     "url": "https://app-sandbox.duitku.com/redirect_checkout?reference=DS1813524EPDECZG2ZJF4JVU"
          // }
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  setKehadiran(data:any) {
    var conf = confirm('Anda yakin Hadir ?');
    if (conf) {
      this.tutorialService.delete(`pendaftaran/sethadirs/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  setSendTiket(data:any) {
    var conf = confirm('Anda Yakin Kirim E-Tiket ?');
    if (conf) {
      this.tutorialService.delete(`pendaftaran/setsendtikets/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  setBIBs(data:any) {
    var conf = confirm('Anda Yakin Set No BIB ?');
    if (conf) {
      this.tutorialService.delete(`pendaftaran/setsendbib/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  // deleteData:any;
  deleteUser(data:any) {
    // this.deleteData = data;
    let modal = this.modalService.open(MODALS['focusFirst']);
    modal.result.then((res:any) => {
      // console.log(res)
      if(res == 'Ok') {

        this.tutorialService.delete(`country/delete/${data.id}`)
        .subscribe(
          async response => {
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

        // this.tutorialService.postData('country/delete/'+this.deleteData.id, {}).subscribe(res => {
        // this.tutorialService.delete(`country/delete/${this.deleteData.id}`).subscribe(res => {
        //   this.toastService.show('Successfully delete data.', { classname: 'bg-success text-white', delay: 10000 });
        //   this.retrieveTutorials();
        // })
      }
    });
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  async downloadExcel() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // console.log()
    // params.shipper_id = this.shipper_id;
    // // params.tglinput = this.tglinput;
    // params.tglinput_mulai = this.tglinput_mulai;
    // params.tglinput_selesai = this.tglinput_selesai;
    // params.code = this.code;
    // console.log(params, 'params')

    this.tutorialService.postData('pendaftaran/downloadexcel', params)
      .subscribe(
        async response => {

        var anchor = document.createElement('a');
        anchor.href = 'https://api.siger.co.id/uploads/files/pendaftaran/'+response.message;
        // anchor.href = 'http://localhost:39998/uploads/files/pendaftaran/'+response.message;
        anchor.target="_blank";
        anchor.click();

        this.presentToast('Expport Excel Pendaftaran Success', 'success');
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });
  }

  openPrint(tutorial:any, action:any) {
    // console.log(tutorial,'s')
    var anchor = document.createElement('a');
    // anchor.href = 'http://localhost/report/tikets/index/'+tutorial.id;
    anchor.href = 'https://report.siger.co.id/tikets/index/'+tutorial.id;
    anchor.target="_blank";
    anchor.click();
    this.router.navigate(['/admin/app-tr-peserta']);
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
     this.file = event.target.files[0];
    }
  }

  openimport(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    // this.shipper_id = '';
    // this.ship_date = '';
    // this.vendor_id = '';
		this.modalService.open(content, { size: 'lg' });
	}

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {type:"binary"});
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        this.exceljsondata = XLSX.utils.sheet_to_json(worksheet,{raw:true, defval:""});

        let senddata = this.exceljsondata;
        // let ship_dates = this.ship_date;
        // let shipper_ids = this.shipper_id;
        // let vendor_ids = this.vendor_id;
        // console.log(senddata);
        this.tutorialService.postData('pendaftaran/importexcelS', {
          data: senddata,
          // ship_dates: ship_dates,
          // shipper_ids: shipper_ids,
          // vendor_ids: vendor_ids,
        })
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
        //  this.providerservice.importexcel(this.exceljsondata).subscribe(data=>{
        //  })
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  // START SCAN
  openFormBIB(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.id = '';
    this.run_nobip = '';
    this.run_order_id = '';
    this.run_category = '';
    this.run_email = '';
    this.run_phone = '';
    this.run_namabip = '';
		this.modalService.open(content);
	}

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult1(resultString: string) {
    this.qrResultString = resultString;
    this.cariNOBIBS(this.qrResultString)
    this.run_nobip = resultString;
  }

  cariNOBIBS(value:any): void {
    // console.log(value, 'no_resi');

    // this.no_resi = this.no_resi;
    this.tutorialService
      .get(`pendaftaran/getbibs/${value}`)
      .subscribe((data: any) => {
        this.id = data.data.id;
        this.run_order_id = data.data.run_order_id;
        this.run_category = data.data.run_category;
        this.run_firstname = data.data.run_firstname;
        this.run_lastname = data.data.run_lastname;
        this.run_sizekaos = data.data.run_sizekaos;
        this.run_email = data.data.run_email;
        this.run_phone = data.data.run_phone;
        this.run_namabip = data.data.run_namabip;
      },(error: any) => {
        // console.log(error, 'error1');
        this.id = '';
        this.run_order_id = '';
        this.run_category = '';
        this.run_firstname = '';
        this.run_lastname = '';
        this.run_sizekaos = '';
        this.run_email = '';
        this.run_phone = '';
        this.run_namabip = '';
        this.presentToast(error.error.errors, 'error');
      },
        () => {
        console.log("Complete");
      });

  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  // END SCAN

  saveBySCAN(): void {
    const data = {
      // pengirim: this.pengirim,
      id: this.id,
      run_nobip: this.run_nobip,
      run_order_id: this.run_order_id,
      run_category: this.run_category,
      run_firstname: this.run_firstname,
      run_lastname: this.run_lastname,
      run_sizekaos: this.run_sizekaos,
      run_email: this.run_email,
      run_phone: this.run_phone,
      run_namabip: this.run_namabip,
    };

    // if(this.isCreated) {
    this.isLoading$ = true;
  
    this.tutorialService.postData('pendaftaran/createscan', data)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
      .subscribe(
        async response => {
          // console.log(response);
          this.fadd_role = {};
          this.id = '';
          this.run_order_id = '';
          this.run_category = '';
          this.run_firstname = '';
          this.run_lastname = '';
          this.run_sizekaos = '';
          this.run_email = '';
          this.run_phone = '';
          this.run_namabip = '';
          this.presentToast(response.message, 'success');
          this.submitted = true;
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });
  }


}
