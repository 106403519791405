<div class="authincation h-100">
    <div class="container vh-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-lg-6 col-md-8">
                <div class="authincation-content">
                    <div class="row no-gutters">
                        <div class="col-xl-12">
                            <div class="auth-form">
                                <!-- <div class="text-center mb-3">
                                    <a [routerLink]="['/admin/index']">
                                      <img src="assets/images/logo-full.png" alt="" height="100">
                                    </a>
                                </div> -->
                                <h4 class="text-center mb-4">Login</h4>
                                <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                    <div class="form-group">
                                      <label for="id">Email</label>
                                      <input type="email" class="form-control" name="email" [(ngModel)]="form.email" required #email="ngModel" />
                                      <div class="alert alert-danger" role="alert" *ngIf="email.errors && f.submitted">
                                        email is required!
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label for="password">Password</label>
                                      <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required
                                        #password="ngModel" />
                                      <div class="alert alert-danger" role="alert" *ngIf="password.errors && f.submitted">
                                        <div *ngIf="password.errors.required">Password is required</div>
                                      </div>
                                    </div>
                                    <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                        <!-- <div class="form-group">
                                          <div class="custom-control custom-checkbox ml-1">
                                                <input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
                                                <label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
                                            </div>
                                        </div> -->
                                        <div class="form-group">
                                            <a [routerLink]="['/page-forgot-password']">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                      <button class="btn btn-primary btn-block" [disabled]="!form.password || !form.email">
                                        Login
                                      </button>
                                    </div>
                                    <div class="form-group">
                                      <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
                                        Login failed: {{ errorMessage }}
                                      </div>
                                    </div>
                                  </form>

                                  <div class="alert alert-success" *ngIf="isLoggedIn">
                                    Logged in as {{ roles }}.
                                    <a href class="nav-link" (click)="logout()">LogOut</a>
                                  </div>
                                <div class="new-account mt-3">
                                    <p>Don't have an account? <a class="text-primary" href="https://siger.co.id" target="_blank">Sign up</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
