<div class="authincation h-100">
        <div class="container vh-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-lg-6 col-md-8">
                    <div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <div class="auth-form">
									<!-- <div class="text-center mb-3">
										<a [routerLink]="['/admin/index']"><img src="assets/images/logo-full.png" alt=""></a>
									</div> -->
                                    <h4 class="text-center mb-4">Forgot Password</h4>
                                    <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="form-group">
                                            <!-- <label><strong>Email</strong></label> -->
                                            <!-- <input type="email" class="form-control" value="hello@example.com"> -->
                                            <label for="id">Email</label>
                                            <input type="email" class="form-control" name="email" [(ngModel)]="form.email" required #email="ngModel" />
                                            <div class="alert alert-danger" role="alert" *ngIf="email.errors && f.submitted">
                                                email is required!
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn btn-primary btn-block" [disabled]="!form.email">SUBMIT</button>
                                        </div>
                                        <div class="form-group mt-2" *ngIf="error === true">
                                            <div class="alert alert-danger">
                                              Reset failed: {{ errorMessage }}
                                            </div>
                                        </div>
                                        <div class="form-group mt-2" *ngIf="error === false">
                                            <div class="alert alert-success">
                                              {{ errorMessage }}
                                            </div>
                                        </div>
                                    </form>
                                    <div class="alert alert-success" *ngIf="isLoggedIn">
                                        Logged in as {{ roles }}.
                                        <a href class="nav-link" (click)="logout()">LogOut</a>
                                    </div>
                                    <div class="new-account mt-3">
                                        <p>Back ? <a class="text-primary" [routerLink]="['/admin/index']">Sign in</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>